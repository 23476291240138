import { render, staticRenderFns } from "./component-static-carousel-v3.vue?vue&type=template&id=267ce604"
import script from "./component-static-carousel-v3.vue?vue&type=script&lang=js"
export * from "./component-static-carousel-v3.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/home/vsts/work/1/s/components/product-card/ProductCard.vue').default,Swiper: require('/home/vsts/work/1/s/components/swiper/index.vue').default})
