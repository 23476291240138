
import { mapGetters } from 'vuex'
import FooterMobileListItem from '~/components/footer/footer-mobile-list-item'
export default {
  components: { FooterMobileListItem },
  data: () => ({
    activeItem: null,
  }),
  computed: {
    ...mapGetters({
      vxFooter: 'common/getFooter',
    }),
    list1() {
      return this.vxFooter?.list1_list
    },
    list2() {
      return this.vxFooter?.list2_list
    },
    list3() {
      return this.vxFooter?.list3_list
    },
  },
  methods: {
    toggleAccordion(itemName) {
      if (this.activeItem === itemName) {
        this.activeItem = null
      } else {
        this.activeItem = itemName
      }
    },
  },
}
