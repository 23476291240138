export const state = () => ({
  selectedProduct: null,
  selectedSize: null,
  leaveReviewIntent: false,
})

export const getters = {
  getSelectedSize(state) {
    return state.selectedSize ? state.selectedSize : null
  },
  getSelectedProductId(state) {
    return state.selectedProduct ? state.selectedProduct.internal_type : null
  },
  getSelectedVariantId(state) {
    return state.selectedProduct
      ? state.selectedProduct.internal_color_code
      : null
  },
  getLeaveReviewIntent(state) {
    return state.leaveReviewIntent
  },
}

export const mutations = {
  setSelectedProduct(state, product) {
    state.selectedProduct = product
  },
  setSelectedSize(state, size) {
    state.selectedSize = size
  },
  setLeaveReviewIntent(state, intent) {
    state.leaveReviewIntent = intent
  },
}

export const actions = {
  selectProduct({ commit }, product) {
    commit('setSelectedProduct', product)
  },
  selectSize({ commit }, size) {
    commit('setSelectedSize', size)
  },
  resetProduct({ commit }) {
    commit('setSelectedProduct', null)
    commit('setSelectedSize', null)
  },
  setLeaveReviewIntent({ commit }, intent) {
    commit('setLeaveReviewIntent', intent)
  },
}
