
import Swiper from '~/components/swiper'
import CarouselBannerSlide from '~/components/carousel/carousel-banner-slide.vue'
import LolePicture from '~/components/picture/lole-picture.vue'
export default {
  components: { Swiper, CarouselBannerSlide, LolePicture },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  methods: {
    urlTarget(slide) {
      return slide.open_new_tab ? '_blank' : '_self'
    },
    getDuration(duration) {
      return duration * 1000
    },
  },
}
