export const getFormatedPrice = (amount) => {
  try {
    if (amount) return parseFloat(amount).toFixed(2)
    else return null
  } catch (e) {}
}

export const getFlapsProduct = (tags) => {
  try {
    const flaps = [
      { tag: '20%off', text: '-20%', id: '-20' },
      { tag: '30%off', text: '-30%', id: '-30' },
      { tag: '40%off', text: '-40%', id: '-40' },
      { tag: '50%off', text: '-50%', id: '-50' },
      { tag: '60%off', text: '-60%', id: '-60' },
      { tag: '70%off', text: '-70%', id: '-70' },
      { tag: 'en_facet_season:ss21', text: 'new', id: 'new' },
      { tag: 'en_facet_eco:true', text: 'eco', id: 'eco' },
      { tag: 'vegan', text: 'vegan', id: 'veg' },
    ]
    const displayFlaps = []
    flaps.forEach((flap) => {
      if (tags.includes(flap.tag)) {
        displayFlaps.push(flap.id)
      }
    })
    return displayFlaps
  } catch (e) {}
}

export const getCategoryProduct = (categories) => {
  try {
    let cat = ''
    if (categories) {
      categories.forEach((categorie, index) => {
        if (index === 0) cat = `${categorie}`
        else cat = `${cat} > ${categorie}`
      })
    }
    return cat
  } catch (e) {}
}

export const getPositionFrom = (position) => {
  try {
    if (position.page) {
      if (position.page.includes('collection')) return 'Collection listing'
      if (position.page.includes('search')) return 'Internal Search listing'
      if (position.page.includes('product')) return 'Product page listing'
      if (position.page.includes('homepage')) return 'Homepage listing'
      return position.page
    }
    if (position.crossSell && position.url) {
      if (position.url.includes('product'))
        return 'Cross-sell product page listing'
      if (position.url.includes('search'))
        return 'Cross-sell internal search listing'
      if (position.url.includes('collection'))
        return 'Cross-sell collection listing'
      if (position.url.includes('homepage'))
        return 'Cross-sell homepage listing'
      return position.url
    }
    return 'undefined'
  } catch (e) {}
}

export const getVariantIdDecoded = (id) => {
  try {
    return id.replace('gid://shopify/ProductVariant/', '')
  } catch (e) {}
}
