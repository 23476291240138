class Shopify {
  static headers = null
  static locale = null
  static currency = null

  static initConfig() {
    Shopify.env = {
      token: process.env.SHOPIFY_ACCESS_TOKEN,
      domain: process.env.SHOPIFY_DOMAIN,
      version: process.env.SHOPIFY_API_VERSION,
    }
  }

  static getHeader() {
    return {
      'X-Shopify-Storefront-Access-Token': Shopify.env.token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }
  }

  static getUrl() {
    return `https://${Shopify.env.domain}/api/${Shopify.env.version}/graphql`
  }

  static switchLocale(locale) {
    if (locale === Shopify.locale) return
    Shopify.locale = locale
  }
}

export default Shopify
