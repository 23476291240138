import { parseProductFromShopify } from '~/helpers/shopify/product'

export const state = () => ({
  list_products: [],
})

export const getters = {
  getProducts(state) {
    return state.list_products
  },
  getRecentlyViewedProducts(state) {
    const ret = []
    state.list_products.forEach((p) => {
      ret.push(p.product)
    })
    return ret
  },
}

export const mutations = {
  setProducts(state, products) {
    state.list_products = products
  },
}

export const actions = {
  async init({ dispatch }) {
    try {
      const recentlyViewed = this.app.$cookies.get(`lole-recently-viewed`)
      if (recentlyViewed) {
        await dispatch('fetchRecentlyViewedProducts', recentlyViewed)
      }
    } catch (e) {
      this.app.$cookies.remove(`lole-recently-viewed`)
      this.app.$sentryException(e, {
        component: 'recently viewed products',
        source: 'store > recentlyViewedProducts > initRecentlyViewed()',
        level: 'warning',
        extra: 'failed to init: ' + e,
      })
    }
  },
  async fetchRecentlyViewedProducts({ commit, rootGetters }, ids) {
    try {
      const currency = rootGetters['currency/getCurrency']
      const listProducts = []
      const { data } = await this.app.$shopify.product.retrieveProducts(
        ids,
        currency
      )
      if (data.errors) throw new Error(data.errors)
      if (data.data.nodes && data.data.nodes.length > 0) {
        data.data.nodes.forEach((y, index) => {
          let parsedProduct = null
          parsedProduct = parseProductFromShopify(
            y,
            this.app.i18n.locale,
            ids[index].split('-')[1]
          ).result
          listProducts.push({
            key: `${parsedProduct.id}-${parsedProduct.internal_color_code}`,
            product: parsedProduct,
          })
        })
        commit('setProducts', listProducts)
      }
    } catch (e) {
      this.app.$cookies.remove('lole-recently-viewed')
      this.app.$sentryException(e, {
        component: 'recently viewed products',
        source:
          'store > recentlyViewedProducts > fetchRecentlyViewedProducts()',
        level: 'warning',
        extra: 'failed to retrieve recently viewed products from shopify: ' + e,
      })
    }
  },
  addRecentlyViewedProduct({ commit, dispatch, getters }, product) {
    try {
      const listProductsSaved = [...getters.getProducts]
      const idProductToAdd = product.id
      const colorProductToAdd = product.internal_color_code
      // IF PRODUCT IS ALREADY IN THE LIST
      const productAlreadyExist = listProductsSaved.find((z) => {
        return product.id === z.key.split('-')[0]
      })
      if (productAlreadyExist) {
        const newListProducts = [...listProductsSaved]
        // DELETE PRODUCT OF THE LIST
        newListProducts.splice(
          listProductsSaved.indexOf(productAlreadyExist),
          1
        )
        // ADD NEW PRODUCT AT THE LIST PRODUCTS
        newListProducts.unshift({
          key: `${idProductToAdd}-${colorProductToAdd}`,
          product,
        })
        commit('setProducts', newListProducts)
        dispatch('updateCookie', newListProducts)
      } else {
        // IF PRODUCT IS NOT ALREADY IN THE LIST
        const newListProducts = [...listProductsSaved]
        // ADD NEW PRODUCT AT THE LIST IDS
        if (newListProducts.length === 10) {
          newListProducts.pop()
          newListProducts.unshift({
            key: `${idProductToAdd}-${colorProductToAdd}`,
            product,
          })
          commit('setProducts', newListProducts)
        } else {
          newListProducts.unshift({
            key: `${idProductToAdd}-${colorProductToAdd}`,
            product,
          })
          commit('setProducts', newListProducts)
        }
        dispatch('updateCookie', newListProducts)
      }
    } catch (e) {
      this.app.$sentryException(e, {
        component: 'recently viewed products',
        source: 'store > recentlyViewedProducts > addRecentlyViewedProduct()',
        level: 'warning',
        extra: 'failed to add recently viewed product : ' + e,
      })
    }
  },
  updateCookie({ commit }, products) {
    try {
      this.app.$cookies.remove(`lole-recently-viewed`)
      this.app.$cookies.set(
        `lole-recently-viewed`,
        products.map((x) => {
          return x.key
        }),
        {
          expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 31),
          path: '/',
        }
      )
    } catch (e) {
      this.app.$sentryException(e, {
        component: 'recently viewed products',
        source: 'store > recentlyViewedProducts > updateCookie()',
        level: 'warning',
        extra: 'failed to set Cookie: ' + e,
      })
    }
  },
}
