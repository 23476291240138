
import { mapGetters } from 'vuex'
import Swiper from '~/components/swiper'
import ProductCard from '~/components/product-card/ProductCard'
export default {
  components: {
    Swiper,
    ProductCard,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      products: [],
    }
  },
  computed: {
    ...mapGetters({
      vxCurrency: 'currency/getCurrency',
    }),
    internalTypesColors() {
      const test = this?.blok?.products?.reduce(
        (acc, { internal_type, color }) => {
          acc.push({
            internal_type: internal_type.toUpperCase(),
            colors: color,
          })
          return acc
        },
        []
      )
      return test
    },
  },
  watch: {
    async vxCurrency() {
      await this.getStaticComponentProducts()
    },
  },
  async beforeMount() {
    await this.getStaticComponentProducts()
  },
  methods: {
    async getStaticComponentProducts() {
      if (this.internalTypesColors?.length > 0) {
        const productsFetched = await this.fetchProductsFromShopify(
          this.internalTypesColors,
          this.vxCurrency
        )
        if (productsFetched?.length > 0)
          this.products = productsFetched?.reduce((acc, product) => {
            const { additional_text } = this.blok?.products.find(
              (p) => p.internal_type === product.internal_type
            )
            acc.push({
              data: product,
              additionalText: additional_text?.content,
            })
            return acc
          }, [])
      }
    },
  },
}
