import { checkout } from '~/plugins/shopify/objects/checkout'
import { orders } from '~/plugins/shopify/objects/orders'
// https://shopify.dev/docs/api/storefront/2024-04/objects/customer
export const customer = `{
  id
  firstName
  lastName
  email
  phone
  createdAt
  lastIncompleteCheckout ${checkout}
  orders(first: 10, reverse: true) ${orders}
  addresses(first: 20) {
    edges{
      node{
        id
        firstName
        lastName
        address1
        city
        provinceCode
        zip
        country
        phone
      }
    }
  }
  giftCardSaved: metafield(namespace: "lole_shop", key: "saved_gift_cards") {
    value
    id
  }
}`
