
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import {
  isKeypressEnter,
  isKeypressSpace,
  isKeypressLeftArrow,
  isKeypressRightArrow,
} from '~/helpers/accessibility'

import ProductCard from '~/components/product-card/ProductCard'
import Backdrop from '~/components/common/backdrop'

export default {
  components: {
    ProductCard,
    Backdrop,
  },
  data: () => ({
    searchResults: [],
    suggestedProducts: [],
    searchTerm: '',
  }),
  computed: {
    ...mapGetters({
      vxTrendingTerms: 'search/getTrendingTerms',
      vxWording: 'navigation/getContent',
      vxSearchIsOpen: 'navigation/getSearchMobileOpen',
      vxContent: 'navigation/getContent',
      vxCurrency: 'currency/getCurrency',
    }),
  },
  watch: {
    searchTerm(value) {
      this.search()
    },
  },
  mounted() {
    this.vxRetrieveTrendingTerms()
  },
  methods: {
    ...mapActions({
      vxOpenSearchMobile: 'navigation/toggleSearchMobile',
      vxCloseAll: 'navigation/closeAllMobile',
      vxRetrieveTrendingTerms: 'search/retrieveTrendingTerms',
    }),
    search: _.debounce(async function () {
      try {
        if (!this.searchTerm) {
          this.searchResults = []
          this.suggestedProducts = []
          return
        }

        const searchTerm = this.searchTerm.toLowerCase()
        const siteId = this.getSiteId()
        const { data } = await this.$axios.get(
          `https://${siteId}.a.searchspring.io/api/suggest/query?siteId=${siteId}&query=${searchTerm}`
        )

        if (
          (!data?.suggested || data?.suggested?.completed?.length <= 0) &&
          (!data?.alternatives || data?.alternatives?.length <= 0)
        )
          return

        const highlightedSuggestion = []
        const suggestions = []

        if (data.alternatives.length > 0) {
          suggestions.push(...data.alternatives)
        }
        if (data.suggested.type === 'exact') {
          suggestions.push(data.suggested)
        } else if (data?.suggested?.completed?.length > 0) {
          suggestions.push(...data?.suggested?.completed)
        }

        suggestions.forEach((suggestion) => {
          let highlighted = suggestion.text
            ? suggestion.text.toLowerCase()
            : suggestion.token.toLowerCase()

          if (!highlighted) return

          if (highlighted.includes(searchTerm)) {
            highlighted = highlighted.replace(
              searchTerm,
              `<span class="font-medium">${searchTerm}</span>`
            )
          } else {
            const words = searchTerm.split(' ')
            words.forEach((word) => {
              highlighted = highlighted.replace(
                word,
                `<span class="font-medium">${word}</span>`
              )
            })
          }

          highlightedSuggestion.push({
            label: highlighted,
            text: suggestion.text || suggestion.token,
          })
        })

        const suggested = data.suggested.text || data.alternatives[0].text
        const productResponse = await this.$axios.get(
          `https://${siteId}.a.searchspring.io/api/search/autocomplete.json?siteId=${siteId}&resultsFormat=native&format=json&resultsPerPage=6&q=${suggested}`
        )

        if (productResponse.data) {
          const suggestedProducts = this.$searchspring.parseProducts.parse(
            productResponse.data,
            this.vxCurrency
          )?.resultProducts

          try {
            this.$gtmEvents.DLSearchPage(suggestedProducts)
          } catch (error) {
            this.$sentryException(error, {
              source: 'Search Results',
              level: 'warning',
              extra: 'Search Results - failed to log gtm event',
            })
          }

          this.suggestedProducts = suggestedProducts
        }

        this.searchResults = highlightedSuggestion
      } catch (error) {
        this.$sentryException(error, {
          source: 'Default Layout',
          level: 'warning',
          extra: `Autocomplete result failed`,
        })
      }
    }, 300),
    getSiteId() {
      return this.$config.SEARCHSPRING_SITE_IDS[this.$i18n.locale.toUpperCase()]
    },
    clearSearch() {
      this.searchResults = []
      this.suggestedProducts = []
      this.$emit('clear-search')
      this.searchTerm = ''
      this.blurSearch()
    },
    handleKeydown(event) {
      if (
        !isKeypressEnter(event) &&
        !isKeypressSpace(event) &&
        !isKeypressLeftArrow(event) &&
        !isKeypressRightArrow(event)
      )
        return

      let currentElement = this.$refs[event.target.id]
      let targetElement = null

      if (!currentElement) return

      if (Array.isArray(currentElement)) {
        currentElement = currentElement[0].$el || currentElement[0]
      }

      if (isKeypressLeftArrow(event)) {
        // LEFT ARROW

        targetElement = currentElement.previousElementSibling
          ? this.$refs[currentElement.previousElementSibling.id]
          : null
      } else if (isKeypressRightArrow(event)) {
        // RIGHT ARROW
        targetElement = currentElement.nextElementSibling
          ? this.$refs[event.target.nextElementSibling.id]
          : null
      }

      if (Array.isArray(targetElement)) {
        targetElement = targetElement[0].$el || targetElement[0]
      }

      // if (targetElement && targetElement.tabIndex === -1) {
      //   currentElement.tabIndex = -1
      //   targetElement.tabIndex = 0
      //   targetElement.focus()
      // }
    },
    focusSearch() {
      this.vxOpenSearchMobile()
    },
    goToSearchTerm() {
      this.$router.push(
        this.localePath(`/search?q=${this.searchTerm.toLowerCase()}`)
      )
      this.clearSearch()
    },
    blurSearch() {
      this.vxCloseAll()
    },
  },
}
