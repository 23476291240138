const fr = Object.freeze({
  siteId: process.env.SEARCHSPRING_SITE_ID_FR,
})

const en = Object.freeze({
  siteId: process.env.SEARCHSPRING_SITE_ID_EN,
})

export default {
  fr,
  en,
}
