import { render, staticRenderFns } from "./header-mobile.vue?vue&type=template&id=1de3cdff&scoped=true"
import script from "./header-mobile.vue?vue&type=script&lang=js"
export * from "./header-mobile.vue?vue&type=script&lang=js"
import style0 from "./header-mobile.vue?vue&type=style&index=0&id=1de3cdff&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1de3cdff",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationMobile: require('/home/vsts/work/1/s/components/navigation/navigation-mobile.vue').default})
