
export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sizeMobile() {
      if (this.blok.size_mobile === 'full') return 'full'
      if (this.blok.size_mobile === '1/2') return 'half'
      if (this.blok.size_mobile === '1/4') return 'quart'
      return null
    },
    sizeDesktop() {
      if (this.blok.size_desktop === 'full') return 'full'
      if (this.blok.size_desktop === '1/2') return 'half'
      if (this.blok.size_desktop === '1/4') return 'quart'
      return null
    },
  },
}
