import Categories from './category'
import Search from './search'
import ParseProducts from './parseProducts'
import Events from './events'

export default ({ app, store, $axios }, inject) => {
  const searchSpring = {
    categories: new Categories({ axios: $axios, locale: app.i18n.locale }),
    search: new Search({ axios: $axios, locale: app.i18n.locale }),
    parseProducts: new ParseProducts({
      store,
      locale: app.i18n.locale,
    }),
    events: new Events({ locale: app.i18n.locale }),
  }

  inject('searchspring', searchSpring)
}
