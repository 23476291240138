
export default {
  name: 'LoleBackdrop',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    onHover: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile() {
      return !this.$store.getters['responsive/getIsWindowLarge']
    },
  },
}
