
import Picture from '~/components/picture/lole-picture'
export default {
  components: { Picture },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    hoverred: false,
  }),
}
