export const namespaced = true

export const state = () => ({
  snackbars: [],
  snackbarsStory: {
    snackbar_error_title: 'Error',
  },
  topBar: {
    country: '',
    isOpen: false,
    alreadyLoaded: false,
    story: {},
  },
  subheader: null,
  content: {
    isActive: false,
    data: null,
    component: null,
  },
  addToCartNotification: null,
  addToCartBundleNotification: null,
})

let nextId = 1

export const mutations = {
  setSnackbarsStory(state, snackbarsStory) {
    state.snackbarsStory = snackbarsStory
  },
  setSubHeader(state, subheader) {
    state.subheader = parseSubHeader(subheader)
  },
  pushSnackbar(state, notification) {
    state.snackbars.push({ ...notification, id: nextId++ })
  },
  removeSnackbar(state, notificationToRemove) {
    state.snackbars = state.snackbars.filter(
      (notification) => notification.id !== notificationToRemove.id
    )
  },
  setTopBarCountry(state, country) {
    state.topBar.country = country
  },
  removeTopBarCountry(state) {
    state.topBar.isOpen = false
  },
  setAlreadyLoaded(state) {
    state.topBar.alreadyLoaded = true
  },
  setTopBar(state) {
    state.topBar.isOpen = true
  },
  setTopBarSwitch(state, topBarSwitch) {
    state.topBar.story = topBarSwitch
  },
  closeContent(state) {
    state.content.isActive = false
  },
  setContent(state, content) {
    state.content = content
  },
  setAddToCartNotification(state, notification) {
    state.addToCartNotification = notification
  },
  setAddToCartBundleNotification(state, notification) {
    state.addToCartBundleNotification = notification
  },
  removeAddToCartNotification(state) {
    state.addToCartNotification = null
  },
  removeAddToCartBundleNotification(state) {
    state.addToCartBundleNotification = null
  },
}

export const getters = {
  getSnackbarStory(state) {
    return state.snackbarsStory
  },
  getTopBar(state) {
    return state.topBar
  },
  getContent(state) {
    return state.content
  },
  getSubHeader(state) {
    return state.subheader
  },
  getAddToCartNotification(state) {
    return state.addToCartNotification
  },
  getAddToCartBundleNotification(state) {
    return state.addToCartBundleNotification
  },
}

export const actions = {
  pushSnackbar({ commit }, notification) {
    commit('pushSnackbar', notification)
  },
  pushSnackbarError({ commit, getters }) {
    const title = getters['notification/getSnackbarStory']
      ? getters['notification/getSnackbarStory'].snackbar_error_title
      : ''
    commit('pushSnackbar', {
      type: 'error',
      title,
    })
  },
  removeSnackbar({ commit }, notification) {
    commit('removeSnackbar', notification)
  },
}

export function parseSubHeader(story) {
  const result = {
    list: [],
  }
  story.list.forEach((i) => {
    if (i.isActive) {
      if (i.component === 'surheader_banner_item')
        result.list.push({
          id: i._uid,
          text: i.text.content || null,
        })
      if (i.component === 'surheader_banner_item_countdown')
        result.list.push({
          id: i._uid,
          end_date: i.end_date.replace(' ', 'T') || null,
          wording_countdown: {
            text: i.text || null,
            text_btn: i.text_btn || null,
            link_btn: i.link_btn || null,
            days: i.countdown_days || null,
            hours: i.countdown_hours || null,
            minutes: i.countdown_minutes || null,
            seconds: i.countdown_seconds || null,
          },
        })
    }
  })
  return result
}
