class GoogleAnaylticsEvents {
  constructor($gtm, app) {
    this.$gtm = $gtm
    this.app = app
    this.env = app.$config
  }

  // 1.04 VIEW_ITEM_LIST
  GA4ViewItemList(productsList, listName, startIndex) {
    if (!this.isGA4Enabled()) return
    const products = []
    productsList.forEach((product, index) => {
      products.push({
        item_id: product.internal_type,
        item_name: product.title,
        currency: product.currency,
        index: startIndex + index,
        item_list_name: listName,
        price: parseFloat(product.price),
        discount: product.strikeout_price
          ? parseFloat(product.strikeout_price - product.price)
          : 0,
      })
    })
    this.$gtm.push({ ecommerce: null })
    this.$gtm.push({
      event: 'view_item_list',
      ecommerce: {
        items: products,
      },
    })
  }

  // 1.05 VIEW_ITEM
  GA4ViewItem(product) {
    if (!this.isGA4Enabled()) return
    this.$gtm.push({ ecommerce: null })
    this.$gtm.push({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_id: product.internal_type,
            item_name: product.title,
            currency: product.currency,
            price: parseFloat(product.price),
            discount: product.strikeout_price
              ? parseFloat(product.strikeout_price - product.price)
              : 0,
            quantity: 1,
          },
        ],
      },
    })
  }

  // 1.06 VIEW_CART
  GA4ViewCart(productsList, type) {
    if (!this.isGA4Enabled()) return
    const products = []
    if (type === 'cart') {
      productsList.forEach((product) => {
        products.push({
          item_id: product.node.merchandise.sku.split('-')[0],

          item_name:
            product.node.attributes.find((a) => a.key === '_title')?.value ||
            product.node.attributes.find((a) => a.key === 'title')?.value,
          currency: product.node.merchandise.price.currencyCode,
          price: parseFloat(product.node.merchandise.price.amount),
          discount: product.node.merchandise.compareAtPrice
            ? parseFloat(
                product.node.merchandise.compareAtPrice.amount -
                  product.node.merchandise.price.amount
              )
            : 0,
          quantity: product.node.quantity,
        })
      })
    } else if (type === 'checkout') {
      productsList.forEach((product) => {
        products.push({
          item_id: product.node.variant.sku.split('-')[0],
          item_name: product.node.title,
          currency: product.node.variant.price.currencyCode,
          price: parseFloat(product.node.variant.price.amount),
          discount: product.node.variant.compareAtPrice
            ? parseFloat(
                product.node.variant.compareAtPrice.amount -
                  product.node.variant.price.amount
              )
            : 0,
          quantity: product.node.quantity,
        })
      })
    }
    this.$gtm.push({ ecommerce: null })
    this.$gtm.push({
      event: 'view_cart',
      ecommerce: {
        items: products,
      },
    })
  }

  // 3.01 SELECT_ITEM
  GA4SelectItem(product) {
    if (!this.isGA4Enabled()) return
    this.$gtm.push({ ecommerce: null })
    this.$gtm.push({
      event: 'select_item',
      ecommerce: {
        items: [
          {
            item_id: product.internal_type,
            item_name: product.title,
            currency: product.currency,
            price: parseFloat(product.price),
            discount: product.strikeout_price
              ? parseFloat(product.strikeout_price - product.price)
              : 0,
            quantity: 1,
          },
        ],
      },
    })
  }

  // 3.02 ADD_TO_CART
  GA4AddToCart(product) {
    try {
      if (!this.isGA4Enabled()) return
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'add_to_cart',
        ecommerce: {
          items: [
            {
              item_id: product.internal_type || 'not-set',
              item_name: product.title,
              currency: product.currency,
              price: parseFloat(product.price),
              discount: product.strikeout_price
                ? parseFloat(product.strikeout_price - product.price)
                : 0,
              quantity: 1,
            },
          ],
        },
      })
    } catch (error) {
      throw new Error(`GA4AddToCart() error: ${error}`)
    }
  }

  // 3.03 INC_UPDATE_ADD_TO_CART
  GA4IncUpdateAddToCart(product, quantity) {
    if (!this.isGA4Enabled()) return
    this.$gtm.push({ ecommerce: null })
    this.$gtm.push({
      event: 'add_to_cart',
      ecommerce: {
        items: [
          {
            item_id: product.sku.split('-')[0],
            item_name: product.title,
            currency: product.currency,
            price: parseFloat(product.price),
            discount: product.strikeoutPrice
              ? parseFloat(product.strikeoutPrice - product.price)
              : 0,
            quantity,
          },
        ],
      },
    })
  }

  // 3.04 DEC_UPDATE_ADD_TO_CART
  GA4DecUpdateAddToCart(product, quantity) {
    if (!this.isGA4Enabled()) return
    this.$gtm.push({ ecommerce: null })
    this.$gtm.push({
      event: 'remove_from_cart',
      ecommerce: {
        items: [
          {
            item_id: product.sku.split('-')[0],
            item_name: product.title,
            currency: product.currency,
            price: parseFloat(product.price),
            discount: product.strikeoutPrice
              ? parseFloat(product.strikeoutPrice - product.price)
              : 0,
            quantity,
          },
        ],
      },
    })
  }

  // FUNCTIONS
  getCurrency() {
    const country = this.app.i18n.locale.substring(3, 5)
    return country === 'us' ? 'USD' : country === 'ca' ? 'CAD' : 'EUR'
  }

  // GA4 ENABLED FLAG
  isGA4Enabled() {
    return !this.env.DISABLED_GA4_EVENTS
  }
}
export default ({ $gtm, app }, inject) => {
  inject('googleAnalyticsEvents', new GoogleAnaylticsEvents($gtm, app))
}
