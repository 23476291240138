
export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    itemActive: 1,
    openMenuMobileForHer: false,
    openMenuMobileForHim: false,
  }),
  computed: {
    forHerMenu() {
      return this.blok.menu_1.filter((item) => item.active)
    },
    forHimMenu() {
      return this.blok.menu_2.filter((item) => item.active)
    },
  },
}
