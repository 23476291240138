export const state = () => ({
  accountMenuOpen: false,
  accountMenuMobileOpen: false,
  switchLocationOpen: false,
  searchDesktopOpen: false,
  searchMobileOpen: false,
  content: null,
})

export const getters = {
  getAccountMenuMobileOpen(state) {
    return state.accountMenuMobileOpen
  },
  getSwitchLocationOpen(state) {
    return state.switchLocationOpen
  },
  getSearchDesktopOpen(state) {
    return state.searchDesktopOpen
  },
  getSearchMobileOpen(state) {
    return state.searchMobileOpen
  },
  getContent(state) {
    return state.content
  },
}

export const mutations = {
  setAccountMenuMobileOpen(state, value) {
    state.accountMenuMobileOpen = value
  },
  setSwitchLocationOpen(state, value) {
    state.switchLocationOpen = value
  },
  setSearchDesktopOpen(state, value) {
    state.searchDesktopOpen = value
  },
  setSearchMobileOpen(state, value) {
    state.searchMobileOpen = value
  },
  setContent(state, value) {
    state.content = value
  },
}

export const actions = {
  toggleAccountMenuMobile({ commit, getters, dispatch }) {
    dispatch('closeAllDesktop')
    commit('getAccountMenuMobileOpen', !getters.getAccountMenuMobileOpen)
    if (process.client) {
      document.body.classList.add('prevent-scroll-mobile')
    }
  },
  toggleSwitchLocation({ commit, getters, dispatch }) {
    dispatch('closeAllDesktop')
    commit('setSwitchLocationOpen', !getters.getSwitchLocationOpen)
    if (process.client) {
      document.body.classList.add('prevent-scroll-mobile')
    }
  },
  toggleSearchDesktop({ commit, getters, dispatch }) {
    dispatch('closeAllDesktop')
    commit('setSearchDesktopOpen', !getters.searchDesktopOpen)
    if (process.client) {
      document.body.classList.add('prevent-scroll-mobile')
    }
  },
  toggleSearchMobile({ commit, getters, dispatch }) {
    dispatch('closeAllMobile')
    commit('setSearchMobileOpen', !getters.searchMobileOpen)
    if (process.client) {
      document.body.classList.add('prevent-scroll-mobile')
    }
  },
  closeAllDesktop({ commit, dispatch }) {
    commit('setSwitchLocationOpen', false)
    commit('setSearchDesktopOpen', false)
    dispatch('menu/closeMenuDesktop', {}, { root: true })
    commit('notification/removeAddToCartNotification', null, { root: true })
    commit('notification/removeAddToCartBundleNotification', null, {
      root: true,
    })
    document.body.classList.remove('prevent-scroll-mobile')
  },
  closeAllMobile({ commit, dispatch }) {
    commit('setSearchMobileOpen', false)
    commit('setAccountMenuMobileOpen', false)
    dispatch('menu/closeMenuMobile', {}, { root: true })
    commit('notification/removeAddToCartNotification', null, { root: true })
    commit('notification/removeAddToCartBundleNotification', null, {
      root: true,
    })
    document.body.classList.remove('prevent-scroll-mobile')
  },
}
