
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    onHover: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
}
