import { cart, userErrors } from '../objects/cart'
// https://shopify.dev/docs/api/storefront/2024-04/mutations/cartCreate
export const cartCreate = `
mutation ($input: CartInput!, $countryCode: CountryCode!)
@inContext(country: $countryCode){
  cartCreate(input: $input) {
    cart ${cart}
    userErrors ${userErrors}
  }
}`
