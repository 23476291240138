import { address } from '../objects/address'
// https://shopify.dev/docs/api/storefront/2024-04/mutations/customerAddressUpdate
export const customerAddressUpdate = `
mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
  customerAddressUpdate(customerAccessToken: $customerAccessToken, id: $id, address: $address) {
    customerAddress ${address}
    customerUserErrors {
      code
      field
      message
    }
  }
}
`
