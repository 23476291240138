export const state = () => ({})

export const getters = {}

export const mutations = {}

export const actions = {
  async getOrders({ rootGetters, commit, dispatch }, cursor) {
    try {
      const { accessToken } = rootGetters['customer/getCustomerAccessToken']
      const data = await this.app.$shopify.customer.customerOrders(
        accessToken,
        cursor
      )
      const orders = rootGetters['customer/getCustomer'].orders
      orders.edges = orders.edges.concat(data.edges)
      orders.pageInfo.hasNextPage = data.pageInfo.hasNextPage
      orders.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage
      dispatch('getOrdersStatus', cursor)
      return data.edges
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'store',
        level: 'warning',
        extra: 'Store customer - getOrders',
      })
    }
  },
  async getOrdersStatus({ rootGetters }, cursor) {
    try {
      const { id } = rootGetters['customer/getCustomer']
      const { data } = await this.$axios.post('/api/shopify/getOrdersStatus', {
        locale: this.app.i18n.locale,
        customerId: id,
        cursor,
      })
      const orders = rootGetters['customer/getCustomer'].orders.edges
      const statusOrders = data.orders.edges
      statusOrders.forEach(({ node }) => {
        if (
          node &&
          node.fulfillments &&
          node.fulfillments[0] &&
          node.fulfillments[0].displayStatus === 'DELIVERED'
        ) {
          const targetIndex = orders.findIndex((order) => {
            return order.node.name === node.name
          })
          if (targetIndex !== -1) {
            orders[targetIndex].node.fulfillmentStatus = 'DELIVERED'
            orders[targetIndex].node.deliveredAt =
              node.fulfillments[0].deliveredAt || null
          }
        }
      })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'store',
        level: 'warning',
        extra: 'Store customer - getOrdersStatus',
      })
    }
  },
  async getOrderById({ rootGetters }, orderId) {
    try {
      const customer = await rootGetters['customer/getCustomer']
      if (!customer) return

      const { data } = await this.$axios.post('/api/shopify/getOrderById', {
        locale: this.app.i18n.locale,
        customer,
        orderId,
      })

      return data
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'store',
        level: 'warning',
        extra: 'Store customer - getOrderById',
      })
    }
  },
}
