
import LolePicture from '~/components/picture/lole-picture.vue'
export default {
  components: { LolePicture },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    hovered: false,
  }),
  computed: {
    isVideo() {
      return this.blok?.image?.filename?.includes('mp4')
    },
  },
}
