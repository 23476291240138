
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleAccordion() {
      this.$emit('toggleAccordion')
    },
    isExternalLink(link) {
      return link.includes('http')
    },
  },
}
