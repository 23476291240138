import { customer } from '../objects/customer'
// https://shopify.dev/docs/api/storefront/2024-04/mutations/customerUpdate
export const customerUpdate = `
  mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
    customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
      customer ${customer}
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`
