
import Picture from '~/components/picture/lole-picture.vue'
import Button from '~/components/buttons/Button.vue'
export default {
  components: { Picture, Button },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    classItems() {
      if (this.blok.nb_items_by_row === '1') return 'w-1/2 lg:w-full'
      if (this.blok.nb_items_by_row === '2') return 'w-1/2 lg:w-1/2'
      if (this.blok.nb_items_by_row === '3') return 'w-1/2 lg:w-1/3'
      if (this.blok.nb_items_by_row === '4') return 'w-1/2 lg:w-1/4'
      if (this.blok.nb_items_by_row === '5') return 'w-1/2 lg:w-1/5'
      if (this.blok.nb_items_by_row === '6') return 'w-1/2 lg:w-1/6'
      if (this.blok.nb_items_by_row === '7') return 'w-1/2 lg:w-1/7'
      if (this.blok.nb_items_by_row === '8') return 'w-1/2 lg:w-1/8'
      else return 'w-1/3'
    },
  },
  methods: {
    isExternalLink(link) {
      return !!link.includes('http')
    },
  },
}
