import { customer } from '../objects/customer'
// https://shopify.dev/docs/api/storefront/2024-04/mutations/customerCreate
export const customerCreate = `
mutation customerCreate($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    customer ${customer}
    customerUserErrors {
      code
      field
      message
    }
  }
}
`
