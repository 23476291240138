import { collection } from './queries'

export default class Product {
  constructor({ $axios, Shopify, app, error }) {
    this.$axios = $axios
    this.Shopify = Shopify
    this.app = app
    this.error = error
  }

  async retrieveCollection(handle) {
    const body = JSON.stringify({
      query: collection,
      variables: {
        handle,
      },
    })
    try {
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      return data
    } catch (e) {
      throw new Error(e)
    }
  }
}
