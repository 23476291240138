
import { mapGetters } from 'vuex'
import CountryTopBar from '~/components/notification/lole-country-top-bar'
import SubHeader from '~/components/navigation/sub-header'
import NavigationMobile from '~/components/navigation/navigation-mobile'
import MessageHeader from '~/components/navigation/message-header'

export default {
  components: {
    CountryTopBar,
    SubHeader,
    NavigationMobile,
    MessageHeader,
  },
  data: () => ({
    isFixed: false,
    isScrollUp: false,
  }),
  computed: {
    ...mapGetters({
      vxScroll: 'getScroll',
      vxIsOpenMobile: 'menu/getIsOpenMobile',
    }),
  },
  watch: {
    'vxScroll.current'() {
      this.vxScroll.current > document.getElementById('header').offsetTop
        ? (this.isFixed = true)
        : (this.isFixed = false)

      /**
       * These lines commented as a temp fix
       * for vibrating navbar on scroll up
       * in mobile
       */
      // this.vxScroll.current < this.vxScroll.previous &&
      // this.vxScroll.previous - this.vxScroll.current > 1
      //   ? (this.isScrollUp = true)
      //   : (this.isScrollUp = false)
    },
  },
}
