// https://shopify.dev/docs/api/storefront/2024-04/objects/collection
export const collection = `
query ($handle: String!) {
  collection(handle: $handle) {
    handle
    title
    description
    metafields(identifiers: [
      {
        namespace: "lang_fr",
        key: "title_tag"
      },
      {
        namespace: "lang_fr",
        key: "description_tag"
      },
      {
        namespace: "lang_fr",
        key: "title"
      },
      {
        namespace: "lang_fr",
        key: "description"
      },
      {
        namespace: "global",
        key: "title_tag"
      },
      {
        namespace: "global",
        key: "description_tag"
      },
    ]) {
      namespace
      key
      value
    }
  }
}`
