export default ({ app }, inject) => {
  const timeout = 10000
  const redisClient = {
    get: async (key) => {
      try {
        return await app.$axios.$post(
          '/api/redis/getKey',
          {
            key,
          },
          { timeout }
        )
      } catch (e) {
        console.log('DEBUG_REDIS_GET_ERROR', e)
      }
    },
    set: async (key, value, ttl) => {
      try {
        const obj = await app.$axios.$post(
          '/api/redis/setKey',
          {
            key,
            value,
            ttl,
          },
          { timeout }
        )

        return obj
      } catch (e) {
        console.log('DEBUG_REDIS_SET_ERROR', e)
      }
    },
    delete: async (key) => {
      try {
        const obj = await app.$axios.$post('/api/redis/deleteKey', {
          key,
        })
        return obj
      } catch (e) {
        console.log('DEBUG_REDIS_DELETE_ERROR', e)
      }
    },
    getHashAll: async (key) => {
      try {
        return await app.$axios.$post(
          '/api/redis/getHashAll',
          {
            key,
          },
          { timeout }
        )
      } catch (e) {
        console.log('DEBUG_REDIS_GET_HASH_ALL_ERROR', e)
      }
    },
    getHash: async (key, field) => {
      try {
        return await app.$axios.$post(
          '/api/redis/getHash',
          {
            key,
            field,
          },
          { timeout }
        )
      } catch (e) {
        console.log('DEBUG_REDIS_GET_HASH_KEY_ERROR', e)
      }
    },
    setHash: async (key, field, value, ttl) => {
      try {
        return await app.$axios.$post(
          '/api/redis/setHash',
          {
            key,
            field,
            value,
            ttl,
          },
          { timeout }
        )
      } catch (e) {
        console.log('DEBUG_REDIS_SET_HASH_KEY_ERROR', e)
      }
    },
  }

  inject('redisClient', redisClient)
}
