// https://shopify.dev/docs/api/storefront/2024-04/mutations/customerAddressDelete
export const customerAddressDelete = `
mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
  customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
    deletedCustomerAddressId
    customerUserErrors {
      code
      field
      message
    }
  }
}
`
