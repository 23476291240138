
import { mapGetters, mapActions } from 'vuex'
import SwitchLocation from '~/components/common/lole-switch-location.vue'

export default {
  components: { SwitchLocation },
  data: () => ({
    showSwitchLocator: false,
    level2: null,
  }),
  computed: {
    ...mapGetters({
      vxContentMenu: 'menu/getContentMenu',
      vxContentSwitchLocator: 'common/getSwitchLocator',
      vxCurrency: 'currency/getCurrency',
      vxCustomer: 'customer/getCustomer',
      vxLoyalty: 'loyalty/getProfile',
      vxNavigationContent: 'navigation/getContent',
    }),
    menuLevel1() {
      if (this.vxContentMenu && this.vxContentMenu.level1.length > 0) {
        return this.vxContentMenu.level1.filter((x) => {
          return x.showOnMobile
        })
      } else return []
    },
  },
  methods: {
    ...mapActions({
      vxCloseMenuMobile: 'menu/closeMenuMobile',
      vxLogout: 'customer/logout',
    }),
    setLevel2(data) {
      this.level2 = {
        name: data.name,
        link: data.link,
        list_1_name: data.list_1_name || null,
        list_1: data.list_1.filter((x) => {
          return x.showOnMobile
        }),
        list_2_name: data.list_2_name || null,
        list_2: data.list_2.filter((x) => {
          return x.showOnMobile
        }),
        list_3_name: data.list_3_name || null,
        list_3: data.list_3.filter((x) => {
          return x.showOnMobile
        }),
        list_4_name: data.list_4_name || null,
        list_4: data.list_4.filter((x) => {
          return x.showOnMobile
        }),
      }
    },
    isActive(name) {
      if (this.level2 && this.level2.name) return name === this.level2.name
      else return false
    },
    getLinkSupport() {
      if (this.$i18n.locale === 'fr-ca')
        return 'https://support.lolelife.com/hc/fr-ca'
      if (this.$i18n.locale === 'en-ca')
        return 'https://support.lolelife.com/hc/en-ca'
      if (this.$i18n.locale === 'fr-eu')
        return 'https://support.lolelife.com/hc/fr-fr'
      if (this.$i18n.locale === 'en-eu')
        return 'https://support.lolelife.com/hc/en-gb'
      if (this.$i18n.locale === 'en-us')
        return 'https://support.lolelife.com/hc/en-us'
    },
    closeAll() {
      this.showSwitchLocator = false
      this.vxCloseMenuMobile()
    },
    signOut() {
      this.vxLogout()
      this.closeAll()
      this.$router.push(this.localePath('index'))
    },
    isWithoutChild(item) {
      return !(
        item.list_1.length > 0 ||
        item.list_2.length > 0 ||
        item.list_3.length > 0 ||
        item.list_4.length > 0
      )
    },
  },
}
