
import LolePicture from '~/components/picture/lole-picture.vue'
export default {
  components: { LolePicture },
  props: {
    blok: { type: Object, required: true },
  },
  data: () => ({
    bgColor: null,
  }),
  beforeMount() {
    if (this.blok.background_color)
      this.bgColor = this.formatColor(this.blok.background_color)
  },
  methods: {
    formatColor(color) {
      return color.charAt(0) === '#' ? color : `#${color}`
    },
  },
}
