export default {
  methods: {
    getDateFormatted(date) {
      if (!date) return null
      const lang = this.$i18n.locale.split('-')[0]
      this.$dayjs.locale(lang)
      switch (lang) {
        case 'fr':
          return this.$dayjs(date).format('D MMMM YYYY')
        case 'en':
          return this.$dayjs(date).format('MMMM D, YYYY')
        default:
          return null
      }
    },
  },
}
