class FbEvents {
  constructor($fb, app) {
    this.$fb = $fb
    this.app = app
  }

  AddToCart(p) {
    try {
      const params = {
        value: parseFloat(p.product.price),
        currency: p.product.currency,
        content_type: 'product',
        content_ids: [p.product.sku],
        content_name: p.product.title,
        quantity: p.variant.quantity,
      }
      if (this.$fb?.fbq) this.$fb.fbq('track', 'AddToCart', params)
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'FB Event Plugin',
        level: 'warning',
        extra: `AddToCart`,
      })
    }
  }

  ViewContent(product) {
    try {
      const ids = product.sizes.map((size) => {
        return size.sku
      })
      const params = {
        content_type: 'product',
        content_ids: ids,
        value: parseFloat(product.price),
        content_name: product.title,
        currency: product.currency,
        content_category: '',
      }
      if (this.$fb?.fbq) this.$fb.fbq('track', 'ViewContent', params)
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'FB Event Plugin',
        level: 'warning',
        extra: `ViewContent`,
      })
    }
  }

  // FUNCTIONS
  getCurrency() {
    const country = this.app.i18n.locale.substring(3, 5)
    return country === 'us' ? 'USD' : country === 'ca' ? 'CAD' : 'EUR'
  }
}

export default ({ $fb, app }, inject) => {
  inject('fbEvents', new FbEvents($fb, app))
}
