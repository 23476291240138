// https://shopify.dev/docs/api/storefront/2024-04/objects/MailingAddress
export const address = `{
    firstName
    lastName
    address1
    city
    provinceCode
    zip
    country
    phone
    id
  }`
