// https://shopify.dev/docs/api/storefront/2024-04/objects/product
export const productsByInternalTypes = `
query ($queryString: String, $amountProducts: Int, $countryCode: CountryCode! )
@inContext(country: $countryCode) {
  products(first: $amountProducts, query: $queryString) {
    edges {
      node {
        id
        handle
        metafield(namespace: "pim", key: "data") {
          namespace
          key
          value
        }
        tags
        variants(first: 100) {
          edges {
            node {
              id
              sku
              price {
                amount
                currencyCode
              }
              compareAtPrice {
                amount
                currencyCode
              }
              quantityAvailable
              availableForSale
              selectedOptions {
                name
                value
              }
            }
          }
        }
        options(first: 3) {
          id
          name
          values
        }
        media(first: 250) {
          edges {
            node {
              mediaContentType
              ... on MediaImage {
                previewImage {
                  altText
                  height
                  width
                  url
                }
              }
              ... on Video {
                sources {
                  format
                  mimeType
                  url
                }
              }
            }
          }
        }
      }
    }
  }
}
`
