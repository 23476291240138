import { render, staticRenderFns } from "./lole-snackbar.vue?vue&type=template&id=0a657bcd&scoped=true"
import script from "./lole-snackbar.vue?vue&type=script&lang=js"
export * from "./lole-snackbar.vue?vue&type=script&lang=js"
import style0 from "./lole-snackbar.vue?vue&type=style&index=0&id=0a657bcd&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a657bcd",
  null
  
)

export default component.exports