
import { mapGetters, mapActions } from 'vuex'
import Swiper from '~/components/swiper'
export default {
  components: { Swiper },
  data() {
    return {
      collections: [],
      collectionLoaded: null,
    }
  },
  computed: {
    ...mapGetters({
      vxStory: 'common/getConfirmationStory',
      vxCurrency: 'currency/getCurrency',
    }),
  },
  async beforeMount() {
    if (Array.isArray(this.vxStory?.handles_carousel)) {
      for (const { handle_collection, title } of this.vxStory
        .handles_carousel) {
        const productsForHandle = await this.fetchCollectionFromSearchSpring(
          handle_collection,
          this.vxCurrency
        )
        this.collections.push({
          title,
          products: productsForHandle,
        })
      }
      if (this.collections?.length > 0)
        this.collectionLoaded = this.collections[0]
    }
  },
  methods: {
    ...mapActions({
      vxCloseAllMobile: 'navigation/closeAllMobile',
      vxCloseAllDesktop: 'navigation/closeAllDesktop',
    }),
    loadCollection(collection) {
      this.collectionLoaded = collection
    },
    closeAll() {
      this.vxCloseAllMobile()
      this.vxCloseAllDesktop()
    },
  },
}
