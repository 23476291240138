class KlaviyoEvents {
  constructor($klaviyo, app) {
    this.$klaviyo = $klaviyo
    this.app = app
  }

  async ActiveOnSite(path, customer) {
    try {
      const activeOnSite = await this.app.$axios
        .post('/api/klaviyo/activeOnSite', {
          path,
          customer,
        })
        .catch((error) => {
          return error
        })
      return activeOnSite.status === 200
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'Klaviyo Events Plugin',
        level: 'warning',
        extra: `ActiveOnSite`,
      })
    }
  }

  async AddToCart({ product, variant }, customer) {
    try {
      const item = {
        $value: parseFloat(product?.price),
        item_name: product?.title,
        item_id: product?.id.replace('gid://shopify/Product/', ''),
        item_sku: `${product?.internal_type}-${product?.internal_color_code}-${variant?.size_name}`,
        item_imageUrl: product?.medias?.[0]?.src || '',
        item_url: `https://www.lolelife.com/${
          this.app.i18n.locale.includes('us') ? '' : `${this.app.i18n.locale}/`
        }product/${product?.handle}`,
        item_price: parseFloat(product?.price),
        item_quantity: variant?.quantity,
      }
      const addToCart = await this.app.$axios
        .post('/api/klaviyo/addToCart', {
          product: item,
          customer,
        })
        .catch((error) => {
          return error
        })
      return addToCart?.status === 200
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'Klaviyo Events Plugin - AddToCart()',
        level: 'warning',
        extra: e,
      })
    }
  }

  async ViewedProduct(product, customer) {
    try {
      const item = {
        $value: parseFloat(product.price),
        item_name: product.title,
        item_id: product.id.replace('gid://shopify/Product/', ''),
        item_sku: `${product.internal_type}-${product.internal_color_code}`,
        item_imageUrl: product.medias[0].src || '',
        item_url: `https://www.lolelife.com/${
          this.app.i18n.locale.includes('us') ? '' : `${this.app.i18n.locale}/`
        }product/${product.handle}`,
        item_price: parseFloat(product.price),
        item_compareAtPrice: parseFloat(product.strikeout_price) || null,
      }
      const viewedProduct = await this.app.$axios
        .post('/api/klaviyo/viewedProduct', {
          product: item,
          customer,
        })
        .catch((error) => {
          return error
        })
      return viewedProduct.status === 200
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'Klaviyo Events Plugin',
        level: 'warning',
        extra: `ViewedProduct`,
      })
    }
  }
}

export default ({ $klaviyo, app }, inject) => {
  inject('klaviyoEvents', new KlaviyoEvents($klaviyo, app))
}
