import { address } from '../objects/address'
// https://shopify.dev/docs/api/storefront/2024-04/mutations/customerAddressCreate
export const customerAddressCreate = `
mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
  customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
    customerAddress ${address}
    customerUserErrors {
      code
      field
      message
    }
  }
}`
