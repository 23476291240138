
import { mapGetters } from 'vuex'
import FooterMobileList from '~/components/footer/footer-mobile-list'
import FooterNewsletterForm from '~/components/footer/footer-newsletter-form'
import SvgAccessibility from '~/assets/icons/accessibility.svg?inline'
export default {
  components: { FooterMobileList, FooterNewsletterForm, SvgAccessibility },
  data: () => ({
    closeAllList: false,
  }),
  computed: {
    ...mapGetters({
      vxFooter: 'common/getFooter',
      vxNavigationContent: 'navigation/getContent',
    }),
    upponFooter() {
      return this.vxFooter?.uponFooter_list
    },
    list1() {
      return this.vxFooter?.list1_list
    },
    list2() {
      return this.vxFooter?.list2_list
    },
    list3() {
      return this.vxFooter?.list3_list
    },
    socialNetworks() {
      return this.vxFooter?.socialNetwork_list
    },
    subFooterLinks() {
      return this.vxFooter?.subFooter_links
    },
  },
  methods: {
    closeAll() {
      this.closeAllList = true
    },
    isExternalLink(link) {
      return link.includes('http')
    },
  },
}
