// https://shopify.dev/docs/api/storefront/2024-04/objects/Checkout
const money = `{
  amount
  currencyCode
}`

const lineItems = `{
  edges {
    node {
      id
      quantity
      title
      discountAllocations {
        allocatedAmount ${money}
      }
      customAttributes {
        key
        value
      }
      variant {
        id
        title
        price ${money}
        compareAtPrice ${money}
        image {
          url
        }
        sku
        quantityAvailable
        availableForSale
      }
    }
  }
}`

export const checkout = `{
  id
  webUrl
  lineItems (first: 150) ${lineItems}
  createdAt
  completedAt
  customAttributes {
    key
    value
  }
  subtotalPrice ${money}
  totalPrice ${money}
  totalTax ${money}
}`

export const userErrors = `{
  field
  message
}`
