
import { mapActions, mapGetters } from 'vuex'
import SearchMobile from '~/components/navigation/search-mobile'
export default {
  components: {
    SearchMobile,
  },
  data: () => ({
    searchTerm: '',
  }),
  computed: {
    ...mapGetters({
      vxCartTotalQuantity: 'cart/getTotalQuantity',
      vxContent: 'navigation/getContent',
      vxSearchIsOpen: 'navigation/getSearchMobileOpen',
      vxCustomer: 'customer/getCustomer',
    }),
    nbProductCart() {
      return this.vxCartTotalQuantity
    },
  },
  methods: {
    ...mapActions({
      vxOpenMenuMobile: 'menu/openMenuMobile',
      vxOpenSearchMobile: 'navigation/toggleSearchMobile',
      vxCloseAll: 'navigation/closeAllMobile',
    }),
    focusOnSearch() {
      this.vxOpenSearchMobile()
      // this.$refs.searchInput.focus()
    },
    clearSearch() {
      this.searchTerm = ''
      this.blurSearch()
    },
    blurSearch() {
      this.vxCloseAll()
    },
  },
}
