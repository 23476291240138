import { parseCommon } from './common/helpers'

function getSearchspringSiteId(app) {
  return app.$config.SEARCHSPRING_SITE_IDS[app?.i18n.locale.toUpperCase()]
}

function getSearchUri(app, page) {
  const siteId = getSearchspringSiteId(app)
  const resultsPerPage = 36
  const resultsFormat = 'native'

  const apiUrl = `https://api.searchspring.net/api/search/search.json?siteId=${siteId}&resultsFormat=${resultsFormat}&format=json&page=${page}&resultsPerPage=${resultsPerPage}`
  return apiUrl
}

export const state = () => ({
  trendingTerms: [],
  isShowingFilters: false,
  isReady: false,
  isSearching: false,
  searchParameters: {
    collectionHandle: null,
    filters: [],
    prices: {
      low: 0,
      high: 500,
    },
    activePriceFacets: [],
    query: '',
    page: 1,
    sort: {
      direction: null,
      field: null,
      label: null,
    },
  },
  searchResults: {
    sortingOptions: [],
    products: [],
    pagination: {},
    didYouMean: '',
    facets: [],
    banners: { top: null },
  },
  story: {
    content: null,
  },
  banners: null,
  pageTitle: '',
  pageDescription: '',
  collectionTitle: '',
  collectionDescription: '',
  searchPageTitle: null,
  categories: [],
  paginationIndex: 1,
  carouselColorsFilter: {},
  plpScrollPosition: 0,
})

export const getters = {
  getIsReady(state) {
    return state.isReady
  },
  getIsShowingFilters(state) {
    return state.isShowingFilters
  },
  getIsSearching(state) {
    return state.isSearching
  },
  getPagination(state) {
    return state.searchResults.pagination
  },
  getTrendingTerms(state) {
    return state.trendingTerms
  },
  getSearchResults(state) {
    return state.searchResults
  },
  getSearchFilters(state) {
    return state.searchParameters.filters
  },
  getSearchPrices(state) {
    return state.searchParameters.prices
  },
  getSearchParameters(state) {
    return state.searchParameters
  },
  getSearchParameter: (state) => (param) => {
    return state.searchParameters[param]
  },
  getSearchPageTitle: (state) => {
    return state.searchPageTitle
  },
  getSelectedSortingOption(state) {
    if (
      !state.searchParameters.sort.field &&
      !state.searchParameters.sort.direction &&
      !state.searchParameters.sort.label
    ) {
      return {
        direction: '',
        field: '',
        label: state.story.content.results_sort_filters_default_option,
      }
    } else if (
      state.searchParameters.sort?.field &&
      state.searchParameters.sort?.direction &&
      !state.searchParameters.sort?.label
    ) {
      const option = state.searchResults.sortingOptions.find(
        ({ field, direction }) =>
          field === state.searchParameters.sort.field &&
          direction === state.searchParameters.sort.direction
      )
      state.searchParameters.sort.label = option?.label || ''
    }

    return state.searchParameters.sort
  },
  getSearchParameterString(state) {
    const params = []

    if (state.searchParameters.query)
      params.push(`q=${state.searchParameters.query}`)
    if (state.searchParameters.filters.length > 0) {
      state.searchParameters.filters.forEach((filter) => {
        params.push(filter)
      })
    }
    if (state.searchParameters.prices.low > 0) {
      params.push(
        `filter.ss_final_price.low=${state.searchParameters.prices.low}`
      )
    }

    if (state.searchParameters.prices.high < 500) {
      params.push(
        `filter.ss_final_price.high=${state.searchParameters.prices.high}`
      )
    }
    if (state.searchParameters.page > 1) {
      params.push(`page=${state.searchParameters.page}`)
    }
    if (
      state.searchParameters.sort.field &&
      state.searchParameters.sort.direction
    ) {
      params.push(
        `sort.${state.searchParameters.sort.field}=${state.searchParameters.sort.direction}`
      )
    }

    return params.length > 0 ? `&${params.join('&')}` : ''
  },
  getStoryContent(state) {
    return state.story.content
  },
  getBanners(state) {
    return state.banners
  },
  getPageTitle(state) {
    return state.pageTitle
  },
  getPaginationIndex(state) {
    return state.paginationIndex
  },
  getPlpScrollPosition(state) {
    return state.plpScrollPosition
  },
  getPageDescription(state) {
    return state.pageDescription
  },
  getCollectionHandle(state) {
    return state?.searchParameters?.collectionHandle || null
  },
  getCollectionTitle(state) {
    return state.collectionTitle
  },
  getCollectionDescription(state) {
    return state.collectionDescription
  },
  hasSearchFilter: (state) => (value) => {
    return state.searchParameters.filters.includes(value)
  },
  getCategoriesStory(state) {
    return state.categories
  },
  getCarouselColorsFilter(state) {
    return state.carouselColorsFilter
  },
  hasFiltersSet(state) {
    return (
      state.searchParameters.filters.length > 0 ||
      state.searchParameters.prices.low > 0 ||
      state.searchParameters.prices.high < 500
    )
  },
  getActivePriceFacets(state) {
    return state.searchParameters.activePriceFacets
  },
}

export const mutations = {
  setIsReady(state, value) {
    state.isReady = value
  },
  setIsShowingFilters(state, value) {
    state.isShowingFilters = value
  },
  setIsSearching(state, value) {
    state.isSearching = value
  },
  setTrendingTerms(state, value) {
    state.trendingTerms = value
  },
  setSearchFilters(state, payload) {
    state.searchParameters.filters = payload
  },
  addSearchFilter(state, value) {
    state.searchParameters.filters.push(value)
  },
  removeSearchFilter(state, value) {
    state.searchParameters.filters = state.searchParameters.filters.filter(
      (filterValue) => filterValue !== value
    )
  },
  setSearchPage(state, value) {
    state.paginationIndex = value
  },
  setPlpScrollPosition(state, value) {
    state.plpScrollPosition = value
  },
  clearSearchParameters(state) {
    state.searchParameters = {
      filters: [],
      prices: {
        low: null,
        high: null,
      },
      page: 1,
      sort: '',
    }
  },
  setSearchParameters(state, object) {
    if (object.collectionHandle)
      state.searchParameters.collectionHandle = object?.collectionHandle || null
    if (object.filters) state.searchParameters.filters = object.filters
    if (object.prices) state.searchParameters.prices = object.prices
    if (object.query) state.searchParameters.query = object.query
    if (object.page) state.searchParameters.page = object.page
    if (object.sort) state.searchParameters.sort = object.sort
  },
  setSearchResults(state, object) {
    if (object.sortingOptions)
      state.searchResults.sortingOptions = object.sortingOptions
    if (object.products)
      state.searchResults.products = [
        ...state.searchResults.products,
        ...object.products,
      ]
    if (object.pagination) state.searchResults.pagination = object.pagination
    if (object.didYouMean) state.searchResults.didYouMean = object.didYouMean
    if (object.facets) state.searchResults.facets = object.facets
    if (object.banners) state.searchResults.banners = object.banners
  },
  setFilter(state, value) {
    state.searchParameters.filters.push(value)
  },
  removeFilter(state, value) {
    state.searchParameters.filters = state.searchParameters.filters.filter(
      (filter) => {
        return filter !== value
      }
    )
  },
  setStory(state, value) {
    state.story.content = value
  },
  setBanners(state, value) {
    state.banners = value
  },
  setPageTitle(state, value) {
    state.pageTitle = value
  },
  setPageDescription(state, value) {
    state.pageDescription = value
  },
  setCollectionTitle(state, value) {
    state.collectionTitle = value
  },
  setCollectionDescription(state, value) {
    state.collectionDescription = value
  },
  setSearchPageTitle(state) {
    state.searchPageTitle = `${state.story.content.page_search_title} "${state.searchParameters.query}"`
  },
  setCategoriesStory(state, value) {
    state.categories = value
  },
  setCarouselColorsFilter(state, value) {
    state.carouselColorsFilter = value
  },
  setActivePriceFacet(state, value) {
    state.searchParameters.activePriceFacets = value
  },
  resetFilters(state) {
    state.searchParameters.filters = []
    state.searchParameters.prices = {
      low: 0,
      high: 500,
    }
    state.searchParameters.activePriceFacets = []
  },
  resetSearchCollectionHandle(state) {
    state.searchParameters.collectionHandle = null
  },
  resetSearchParameters(state) {
    state.searchParameters.filters = []
    state.searchParameters.prices = {
      low: 0,
      high: 500,
    }
    state.searchParameters.page = 1
    state.searchParameters.sort = {
      direction: null,
      field: null,
      label: null,
    }
    state.searchPageTitle = null
    state.pageTitle = ''
    state.pageDescription = ''
    state.collectionTitle = ''
    state.collectionDescription = ''
    state.plpScrollPosition = 0
  },
  resetSearchResults(state, mode) {
    if (mode === 'filtering') {
      state.paginationIndex = 1
      state.searchResults.products = []
    } else {
      state.paginationIndex = 1
      state.searchResults.sortingOptions = []
      state.searchResults.products = []
      state.searchResults.pagination = {}
      state.searchResults.didYouMean = ''
      state.searchResults.facets = []
      state.searchResults.banners = { top: null }
    }
  },
  resetSearchQuery(state) {
    state.searchPageTitle = null
    state.searchParameters.query = ''
  },
}

export const actions = {
  async initBanners({ commit }) {
    // STORYBLOK - SET VERSION
    try {
      await this.app.$storyblok.setVersion()
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'store',
        level: 'fatal',
        extra: 'init application failed on storyblok set version',
      })
      this.app.context.error({
        statusCode: 500,
        message: 'Store search/initBanners failed',
      })
    }
    const { story } = await this.$storyblok.getStory(
      '/pages/collection-page/banners_collection_page'
    )
    const { bannersStory } = parseCommon([story])
    commit('search/setBanners', bannersStory, { root: true })
  },
  async retrieveTrendingTerms({ commit, getters }) {
    try {
      if (getters?.getTrendingTerms?.length > 0) return
      const siteId = getSearchspringSiteId(this.app)
      const { data } = await this.app.$axios.get(
        `https://${siteId}.a.searchspring.io/api/suggest/trending?siteId=${siteId}`
      )
      commit('setTrendingTerms', data.trending.queries)
    } catch (error) {
      this.app.$sentryException(error, {
        source: 'Default Layout',
        level: 'warning',
        extra: `Autocomplete result failed`,
      })
    }
  },
  async queryShopify({ getters, commit }) {
    try {
      const searchParameters = getters.getSearchParameters
      let title = ''
      let description = ''
      let titleSEO = ''
      let descriptionSEO = ''
      const { data } = await this.app.$shopify.collection.retrieveCollection(
        searchParameters.collectionHandle
      )
      const language = this.app.i18n.locale.substring(0, 2)
      const metafields = data.collection.metafields.filter(function (el) {
        return el != null
      })
      metafields.forEach((meta) => {
        if (language === 'fr') {
          if (meta.namespace === 'lang_fr' && meta.key === 'title_tag')
            titleSEO = meta.value
          if (meta.namespace === 'lang_fr' && meta.key === 'description_tag')
            descriptionSEO = meta.value
          if (meta.namespace === 'lang_fr' && meta.key === 'title')
            title = meta.value
          if (meta.namespace === 'lang_fr' && meta.key === 'description')
            description = meta.value
        } else {
          if (data.collection.title) titleSEO = title = data.collection.title
          if (data.collection.description)
            descriptionSEO = description = data.collection.description
        }
      })
      commit('setCollectionTitle', title)
      commit('setCollectionDescription', description)
      commit('setPageTitle', titleSEO)
      commit('setPageDescription', descriptionSEO)
    } catch (e) {}
  },
  async querySearchspring({ commit, getters, dispatch, rootGetters }) {
    try {
      const vxCurrency = rootGetters['currency/getCurrency']
      await dispatch('initBanners')
      commit('setIsSearching', true)
      let searchQuery = getters.getSearchParameterString
      if (vxCurrency === 'CAD')
        searchQuery = searchQuery.replaceAll('ss_final_price', 'ss_price_ca')
      if (vxCurrency === 'EUR')
        searchQuery = searchQuery.replaceAll('ss_final_price', 'ss_price_eu')

      const searchParameters = getters.getSearchParameters
      const uri = getSearchUri(this.app, getters.getPaginationIndex)

      if (searchParameters?.collectionHandle) {
        const altSearchQuery = `&bgfilter.collection_handle=${searchParameters.collectionHandle}&${searchQuery}`
        searchQuery = altSearchQuery
      }

      // GET SEARCH RESULTS
      const { data: searchResults } = await this.$axios.get(uri + searchQuery)
      let resultData = searchResults

      // DID YOU MEAN
      let didYouMean = ''
      if (resultData?.merchandising?.redirect) {
        window.location.replace(resultData.merchandising.redirect)
        commit('resetSearchResults')
        commit('setIsSearching', false)
        return
      }
      if (resultData?.results?.length <= 0 && resultData?.didYouMean?.query) {
        didYouMean = resultData.didYouMean.query
        const searchQueryParts = searchQuery
          .split('&')
          .filter((part) => !part.includes('q='))
          .push(`q=${didYouMean}`)
          .join('&')
        const { data: correctedResults } = await this.$axios.get(
          uri + searchQueryParts
        )
        resultData = correctedResults
      }

      // NO RESULTS
      if (resultData?.results?.length <= 0) {
        commit('resetSearchResults')
        commit('setIsSearching', false)
        return
      }

      // SORTING OPTIONS & PAGINATION
      this.sortingOptions = resultData?.sorting?.options || []
      this.pagination = resultData?.pagination

      // PRODUCTS LIST
      const currency = rootGetters['currency/getCurrency']
      const products = await this.app.$searchspring.parseProducts.parse(
        resultData,
        currency
      )?.resultProducts

      const searchData = getters.getSearchParameters
      const storyblokBanners = getters.getBanners

      // TOP BANNERS
      const topBanner =
        storyblokBanners.topBanners.filter((banner) => {
          const collectionHandles = banner.collectionHandles
            .split(',')
            .map((handle) => handle.trim())
          return collectionHandles.includes(searchData.collectionHandle)
        })?.[0] || null

      // INLINE BANNERS
      try {
        const activeInlineBanners = storyblokBanners.inlineBanners
          .filter((banner) => {
            const collectionHandles = banner.collectionHandles
              .split(',')
              .map((handle) => handle.trim())
            const bannerPages = banner.pages.split(',')
            const currentPage = resultData.pagination.currentPage.toString()

            const matchesCollectionHandle = collectionHandles.includes(
              searchData.collectionHandle
            )
            const matchesPage = bannerPages.includes(currentPage)

            return matchesCollectionHandle && matchesPage
          })
          .sort((a, b) => {
            return a.position - b.position
          })

        if (activeInlineBanners.length > 0) {
          // Insert inline banners into the products array
          activeInlineBanners.forEach((banner) => {
            if (banner?.component === 'inline_banner_collection_page') {
              products.splice(banner.position - 1, 0, {
                banner,
                type: banner.doubleWidth ? 'double card' : ' card',
              })
            }
          })
          // DO IT after to avoid wrong position
          // Insert full inline banners into the products array
          activeInlineBanners.forEach((banner) => {
            if (banner?.component === 'full_inline_banner_collection_page') {
              products.splice((banner.line - 1) * 4, 0, {
                banner,
                type: 'full',
              })
            }
          })
        }
      } catch (error) {
        this.$sentryException(error, {
          source: 'Search component',
          level: 'fatal',
          extra: `Failed to format inline banners : ${error}`,
        })
      }

      commit('setSearchResults', {
        sortingOptions: resultData.sorting ? resultData.sorting.options : [],
        products,
        pagination: resultData.pagination,
        facets: resultData.facets,
        didYouMean,
        banners: {
          top: topBanner,
        },
      })

      if (products?.length === 0) {
        try {
          this.app.$gtmEvents.UAEmptySearch(searchQuery.replace('&q=', ''))
        } catch (e) {
          this.app.$sentryException(e, {
            source: 'GTM page internal search',
            level: 'warning',
            extra: 'GTM page internal search UAEmptySearch failed',
          })
        }
      }
    } catch (error) {
      this.app.$sentryException(error, {
        source: 'Search component',
        level: 'fatal',
        extra: `SS API call failed`,
      })
    }
    commit('setIsSearching', false)
  },
  async resetAllSearch({ commit, dispatch }) {
    commit('resetSearchQuery')
    commit('resetFilters')
    await dispatch('querySearchspring')
  },
}
