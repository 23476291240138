
import { mapGetters, mapActions } from 'vuex'
import MenuMobile from '~/components/menu/menu-mobile'
import Backdrop from '~/components/common/lole-backdrop'
export default {
  components: { MenuMobile, Backdrop },
  computed: {
    ...mapGetters({
      vxIsOpenMobile: 'menu/getIsOpenMobile',
    }),
  },
  methods: {
    ...mapActions({
      vxCloseMobile: 'menu/closeMenuMobile',
    }),
    closeAll() {
      setTimeout(() => {
        this.vxCloseMobile()
      }, 200)
    },
  },
}
