
import { mapGetters } from 'vuex'
import LoleCountdown from '~/components/notification/lole-countdown'
export default {
  components: { LoleCountdown },
  computed: {
    ...mapGetters({
      vxSubHeader: 'notification/getSubHeader',
    }),
  },
}
