export const state = () => ({
  isOpenMobile: false,
  isOpenDesktop: false,
  content: null,
  menuDesktopLoaded: null,
})

export const getters = {
  getIsOpenMobile(state) {
    return state.isOpenMobile
  },
  getIsOpenDesktop(state) {
    return state.isOpenDesktop
  },
  getContentMenu(state) {
    return state.content
  },
  getMenuDesktopLoaded(state) {
    return state.menuDesktopLoaded
  },
  getMenuLvl2Flattened(state) {
    if (state.content) {
      const tmp = []
      state.content.level1.forEach((a) => {
        tmp.push(a.list_1)
        tmp.push(a.list_2)
        tmp.push(a.list_3)
        tmp.push(a.list_4)
      })
      return tmp.flat()
    }
  },
}

export const mutations = {
  setIsOpenMobile(state, value) {
    state.isOpenMobile = value
  },
  setIsOpenDesktop(state, value) {
    state.isOpenDesktop = value
  },
  setContentMenu(state, value) {
    state.content = value
  },
  setMenuDesktopLoaded(state, value) {
    state.menuDesktopLoaded = value
  },
}

export const actions = {
  openMenuMobile({ commit }) {
    if (process.client) {
      document.body.classList.add('prevent-scroll-mobile')
    }
    commit('setIsOpenMobile', true)
  },
  closeMenuMobile({ commit }) {
    document.body.classList.remove('prevent-scroll-mobile')
    commit('setIsOpenMobile', false)
  },
  openMenuDesktop({ commit }, menu) {
    commit('setIsOpenDesktop', true)
    commit('setMenuDesktopLoaded', menu.name)
  },
  closeMenuDesktop({ commit }) {
    document.body.classList.remove('prevent-scroll-mobile')
    commit('setIsOpenDesktop', false)
    commit('setMenuDesktopLoaded', null)
  },
}
