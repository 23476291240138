
import { mapGetters } from 'vuex'
import { parseProductFromShopify } from '~/helpers/shopify/product'
import Swiper from '~/components/swiper'
import ProductCard from '~/components/product-card/ProductCard'
export default {
  components: {
    Swiper,
    ProductCard,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      products: [],
    }
  },
  computed: {
    ...mapGetters({
      vxCurrency: 'currency/getCurrency',
    }),
  },
  watch: {
    vxCurrency() {
      if (this?.blok?.products?.length > 0) this.getProductsByIds()
      else if (this?.blok?.collection_handle)
        this.getProductsByCollectionHandle()
    },
  },
  mounted() {
    try {
      // Determine if we use the list of products ids and color or collection_handle
      // Search by ids/color_code
      if (this?.blok?.products?.length > 0) this.getProductsByIds()
      // Search by collection_handle
      else if (this?.blok?.collection_handle)
        this.getProductsByCollectionHandle()
      // Empty
      else return
    } catch (e) {
      this.$sentryException(e, {
        source: 'component-static-carousel-products',
        component: 'Static Carousel Products',
        level: 'danger',
        extra: 'failed to load static carousel products',
      })
    }
  },
  methods: {
    async getProductsByIds() {
      const parsedIds = this?.blok?.products?.reduce((acc, item, index) => {
        acc[index] = {
          id: btoa(`gid://shopify/Product/${item.product_id}`),
          color: item?.color_code,
          image: item?.overwrite_image?.filename || null,
          additionalText: item?.additional_text || null,
        }
        return acc
      }, {})

      const productIds = Object.values(parsedIds).map(({ id }) => id)

      // FETCH DATA
      const { data } = await this.$shopify.product.retrieveProducts(
        productIds,
        this.vxCurrency
      )
      if (data.errors) throw new Error(JSON.stringify(data.errors))

      this.products = data?.data?.nodes?.reduce((acc, product, index) => {
        const productFromObj = parsedIds[index]
        const parsedProduct = parseProductFromShopify(
          product,
          this.$i18n.locale,
          productFromObj?.color
        )?.result

        if (productFromObj?.image) {
          parsedProduct.medias[0].src = productFromObj.image
        }

        acc.push({
          data: parsedProduct,
          additionalText: productFromObj?.additionalText?.content || null,
        })
        return acc
      }, [])
    },
    async getProductsByCollectionHandle() {
      try {
        const siteId =
          this.$config.SEARCHSPRING_SITE_IDS[this.$i18n.locale.toUpperCase()]
        const url = `https://api.searchspring.net/api/search/search.json?siteId=${siteId}&resultsFormat=native&format=json&bgfilter.collection_handle=${this.blok.collection_handle}&resultsPerPage=10&page=1`
        const { data } = await this.$axios.get(url)
        if (data?.results?.length > 0) {
          const { resultProducts } = this.$searchspring.parseProducts.parse(
            data,
            this.vxCurrency
          )

          this.products = []

          resultProducts.forEach((product) => {
            this.products.push({
              data: product,
              additionalText: null,
            })
          })
        }
      } catch (e) {
        this.$sentryException(e, {
          source: 'Homepage - Carousel Products',
          level: 'warning',
          extra: `Error : ${e}`,
        })
      }
    },
    async parseResults(data) {
      this.products = await this.$searchspring.parseProducts.parse(
        data,
        this.vxCurrency
      )?.resultProducts
    },
  },
}
