
import { mapGetters } from 'vuex'
import Swiper from '~/components/swiper'
import ProductCard from '~/components/product-card/ProductCard'
export default {
  components: {
    Swiper,
    ProductCard,
  },
  props: {
    wording: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      products: [],
    }
  },
  computed: {
    ...mapGetters({
      vxCurrency: 'currency/getCurrency',
    }),
  },
  watch: {
    async vxCurrency() {
      await this.getCollectionProducts()
    },
  },
  async mounted() {
    await this.getCollectionProducts()
  },
  methods: {
    async getCollectionProducts() {
      this.products = await this.fetchCollectionFromSearchSpring(
        this.wording?.e404_carousel_collection_handle,
        this.vxCurrency
      )
    },
  },
}
