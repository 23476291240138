export const state = () => ({})

export const getters = {}

export const mutations = {}

export const actions = {
  async addProductsToCart({ rootGetters, dispatch }, { products }) {
    await dispatch('cart/addProductsToCart', { products }, { root: true })

    // FB & KLAVIYO ADD TO CART EVENT TRACKING
    const customer = rootGetters['customer/getCustomer']
    for (const product of products) {
      try {
        this.$fbEvents.AddToCart(product)
        if (customer) this.$klaviyoEvents.AddToCart(product, customer)
      } catch (e) {
        this.app.$sentryException(e, {
          source: 'STORE - product',
          level: 'warning',
          extra: 'GTM & FB failed to add to cart',
        })
      }
    }
  },
  async updateProductsToCart({ dispatch }, { products }) {
    await dispatch('cart/updateLinesToCart', { products }, { root: true })
  },
  async goToCheckout({ dispatch }, GAIdSession) {
    await dispatch('cart/goToCheckoutWithCart', { GAIdSession }, { root: true })
  },
}
