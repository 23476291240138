import { render, staticRenderFns } from "./component-homepage-horizontal-showcase.vue?vue&type=template&id=a3377a9e&scoped=true"
import script from "./component-homepage-horizontal-showcase.vue?vue&type=script&lang=js"
export * from "./component-homepage-horizontal-showcase.vue?vue&type=script&lang=js"
import style0 from "./component-homepage-horizontal-showcase.vue?vue&type=style&index=0&id=a3377a9e&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3377a9e",
  null
  
)

export default component.exports