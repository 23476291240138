
import { mapGetters, mapActions } from 'vuex'
import LoleBackdrop from '~/components/common/lole-backdrop'

export default {
  name: 'LoleSearchBar',
  components: { LoleBackdrop },
  data: () => ({
    searchValue: '',
  }),
  computed: {
    ...mapGetters({
      vxStory: 'common/getSearchStory',
    }),
  },
  methods: {
    ...mapActions({
      vxCloseAll: 'menu/closeAll',
    }),
    closeSearch() {
      this.searchValue = ''
      this.vxCloseAll()
    },
    submit() {
      const lang = this.$i18n.locale
      this.$router.push({
        path: `/${lang}/search?q=${this.searchValue}`,
      })
      this.searchValue = ''
      this.vxCloseAll()
    },
  },
}
