export const state = () => ({
  profile: null,
})

export const getters = {
  getProfile(state) {
    return state.profile
  },
  getAllReviews(state) {
    return state.profile ? state.profile.results : null
  },
  getPublishedReviews(state) {
    return state.profile
      ? state.profile.results.filter(
          (result) => result.review.reviewState === 1
        )
      : null
  },
  getUnpublishedReviews(state) {
    return state.profile
      ? state.profile.results.filter(
          (result) => result.review.reviewState !== 1
        )
      : null
  },
  getReviewedProductIds(state) {
    return state.profile
      ? state.profile.results.map((result) =>
          result.review.productId.toString()
        )
      : null
  },
  getReviewedProductsGroupedByOrderId(state) {
    return state.profile
      ? state.profile.results.reduce((acc, result) => {
          if (!acc[result.orderId]) {
            acc[result.orderId] = []
          }
          acc[result.orderId].push(result.review.productId)
          return acc
        }, {})
      : null
  },
}

export const mutations = {
  setProfile(state, profile) {
    state.profile = profile
  },
}

export const actions = {
  async init({ commit, rootGetters }, customer) {
    try {
      const profile = getters.getProfile
      if (!profile.email && customer && customer.email) {
        await this.$axios
          .post('/api/stamped/reviews/getReviewsByCustomer', {
            email: customer.email,
            locale: this.app.i18n.locale,
          })
          .catch((error) => {
            return error
          })
          .then((result) => {
            if (result.data) {
              commit('setProfile', result.data)
            } else {
              throw new Error('STAMPED - Customer not found ' + result)
            }
          })
      }
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'stamped store',
        level: 'warning',
        extra: 'Store - STAMPED - init(): ' + e,
      })
      throw new Error(e)
    }
  },
  clear({ commit }) {
    try {
      commit('setProfile', null)
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'stamped store',
        level: 'warning',
        extra: 'Store STAMPED - clear()',
      })
      throw new Error(e)
    }
  },
}
