export default {
  methods: {
    getCurrency(locale) {
      if (!locale) return null
      switch (true) {
        case this.$i18n.locale.includes('ca'):
          return 'CAD'
        case this.$i18n.locale.includes('us'):
          return 'USD'
        case this.$i18n.locale.includes('eu'):
          return 'EUR'
        default:
          return null
      }
    },
  },
}
