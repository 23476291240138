export const state = () => ({
  storesList: [],
  storeDetails: {},
})

export const getters = {
  getStoresList(state) {
    return state.storesList
  },
}

export const mutations = {
  setStoresList(state, stores) {
    state.storesList = stores
  },
  setStoreDetails(state, store) {
    state.storeDetails = store
  },
}

export const actions = {
  async getPlacesDetailsByPlaceIds({ commit }, ids) {
    try {
      const stores = []
      const promises = ids.map(async (id) => {
        const { result } = await this.$googleApi.getPlaceDetails(id)
        if (result) stores.push(result)
      })
      await Promise.all(promises).then(() => {
        const idMap = new Map(ids.map((id, index) => [id, index]))
        commit(
          'setStoresList',
          stores.sort((a, b) => idMap.get(a.place_id) - idMap.get(b.place_id))
        )
        commit('setStoreDetails', null)
      })
    } catch (e) {
      this.$sentryException(e, {
        source: 'store - stores',
        level: 'warning',
        extra: 'Failed getPlacesDetailsByPlaceIds',
      })
    }
  },
  async getPlacesDetailsByPLaceId({ commit, getters }, id) {
    try {
      const result = getters.getStoresList.filter((store) => {
        return store.place_id === id
      })
      if (result.length === 0) {
        const call = await this.$googleApi.getPlaceDetails(id)
        commit('setStoreDetails', call.result)
      } else commit('setStoreDetails', result[0])
    } catch (e) {
      this.$sentryException(e, {
        source: 'store - stores',
        level: 'warning',
        extra: 'Failed getPlacesDetailsByPLaceId',
      })
    }
  },
}
