import btoa from 'btoa'
import {
  customerAccessTokenCreate,
  customerAccessTokenRenew,
  customerAccessTokenDelete,
  customerCreate,
  customerRecover,
  customerActivate,
  customerReset,
  customerUpdate,
  customerAddressCreate,
  customerAddressUpdate,
  customerAddressDelete,
} from './mutations'
import { retrieveCustomer, customerOrders } from './queries'

const transformErrors = (prefix, arrayOfErrors) => {
  let errorMessage = prefix
  arrayOfErrors.forEach((e) => (errorMessage += ' || ' + e.message))
  return errorMessage
}

export default class Customer {
  constructor({ $axios, Shopify, app, error }) {
    this.$axios = $axios
    this.Shopify = Shopify
    this.app = app
    this.error = error
  }

  async customerAccessTokenCreate(input) {
    try {
      const body = JSON.stringify({
        query: customerAccessTokenCreate,
        variables: {
          input: {
            ...input,
          },
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length)
        throw new Error(
          transformErrors('[Customer AccessTokenCreate errors]', data.errors)
        )

      if (
        data.data.customerAccessTokenCreate.customerUserErrors &&
        data.data.customerAccessTokenCreate.customerUserErrors.length
      )
        throw new Error(
          transformErrors(
            '[Customer AccessTokenCreate errors]',
            data.data.customerAccessTokenCreate.customerUserErrors
          )
        )
      return data.data.customerAccessTokenCreate.customerAccessToken
    } catch (e) {
      throw new Error(e)
    }
  }

  async customerAccessTokenRenew(accessToken) {
    try {
      const body = JSON.stringify({
        query: customerAccessTokenRenew,
        variables: {
          customerAccessToken: accessToken,
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length) {
        throw new Error(
          transformErrors('[Customer accessTokenRenew errors]', data.errors)
        )
      }
      if (
        data.data.customerAccessTokenRenew.userErrors &&
        data.data.customerAccessTokenRenew.userErrors.length
      ) {
        throw new Error(
          transformErrors(
            '[Customer accessTokenRenew userErrors]',
            data.data.customerAccessTokenRenew.userErrors
          )
        )
      }
      return data.data.customerAccessTokenRenew.customerAccessToken
    } catch (e) {
      throw new Error(e)
    }
  }

  async customerAccessTokenDelete(accessToken) {
    try {
      const body = JSON.stringify({
        query: customerAccessTokenDelete,
        variables: {
          customerAccessToken: accessToken,
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length)
        throw new Error(
          transformErrors('[Customer deleteToken errors]', data.errors)
        )
      if (
        data.data.customerAccessTokenDelete.userErrors &&
        data.data.customerAccessTokenDelete.userErrors.length
      )
        throw new Error(
          transformErrors(
            '[Customer deleteToken userErrors]',
            data.data.customerAccessTokenDelete.userErrors
          )
        )
      return data.data.customerAccessTokenDelete
    } catch (e) {
      throw new Error(e)
    }
  }

  async retrieveCustomer(customerAccessToken) {
    try {
      const body = JSON.stringify({
        query: retrieveCustomer,
        variables: {
          customerAccessToken,
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length)
        throw new Error(
          transformErrors('[Customer retriveUser errors]', data.errors)
        )
      if (typeof data.data.customer === 'undefined')
        throw new Error(
          transformErrors('[Customer retriveUser undefined]', [
            { message: "can't fetch user" },
          ])
        )
      return data.data.customer
    } catch (e) {
      throw new Error(e)
    }
  }

  async customerCreate(input) {
    try {
      const body = JSON.stringify({
        query: customerCreate,
        variables: {
          input: {
            acceptsMarketing: false,
            ...input,
          },
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length)
        throw new Error(transformErrors('[Customer Create error]', data.errors))
      if (
        data.data.customerCreate.customerUserErrors &&
        data.data.customerCreate.customerUserErrors.length
      )
        throw new Error(
          transformErrors(
            '[Customer Create userErrors]',
            data.data.customerCreate.customerUserErrors
          )
        )

      return data.data.customerCreate
    } catch (e) {
      throw new Error(e)
    }
  }

  async customerRecover(email) {
    try {
      const body = JSON.stringify({
        query: customerRecover,
        variables: {
          email,
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length)
        throw new Error(
          transformErrors('[Customer recover error]', data.errors)
        )
      if (data.data.customerUserErrors && data.data.customerUserErrors.length)
        throw new Error(
          transformErrors(
            '[Customer recover error]',
            data.data.customerUserErrors
          )
        )
      return data.data
    } catch (e) {
      throw new Error(e)
    }
  }

  async customerActivate(id, token, password) {
    const idUser = btoa('gid://shopify/Customer/' + id)
    const body = JSON.stringify({
      query: customerActivate,
      variables: {
        id: idUser,
        input: {
          activationToken: token,
          password,
        },
      },
    })
    try {
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length)
        throw new Error(
          transformErrors('[Customer activate errors]', data.errors)
        )
      if (
        data.data.customerActivate.customerUserErrors &&
        data.data.customerActivate.customerUserErrors.length
      )
        throw new Error(
          transformErrors(
            '[Customer activate customerUserErrors]',
            data.data.customerActivate.customerUserErrors
          )
        )
    } catch (e) {
      throw new Error(e)
    }
  }

  async customerReset(id, token, password) {
    try {
      const idUser = btoa('gid://shopify/Customer/' + id)
      const body = JSON.stringify({
        query: customerReset,
        variables: {
          id: idUser,
          input: {
            resetToken: token,
            password,
          },
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length)
        throw new Error(
          transformErrors('[Customer resetPassword errors]', data.errors)
        )
      if (
        data.data.customerReset.customerUserErrors &&
        data.data.customerReset.customerUserErrors.length
      )
        throw new Error(
          transformErrors(
            '[Customer resetPassword customerUserErrors]',
            data.data.customerUserErrors
          )
        )
    } catch (e) {
      throw new Error(e)
    }
  }

  async customerUpdate(accessToken, input) {
    try {
      const body = JSON.stringify({
        query: customerUpdate,
        variables: {
          customerAccessToken: accessToken,
          customer: {
            acceptsMarketing: false,
            ...input,
          },
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length) {
        throw new Error(transformErrors('[Customer update error]', data.errors))
      }
      if (
        data.data.customerUpdate.customerUserErrors &&
        data.data.customerUpdate.customerUserErrors.length
      ) {
        throw new Error(
          transformErrors(
            '[Customer update customerUserErrors]',
            data.data.customerUpdate.customerUserErrors
          )
        )
      }
      return data.data.customerUpdate
    } catch (e) {
      throw new Error(e)
    }
  }

  async customerOrders(accessToken, cursor) {
    try {
      const body = JSON.stringify({
        query: customerOrders,
        variables: {
          customerAccessToken: accessToken,
          cursor,
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length)
        throw new Error(
          transformErrors('[Customer getOrders errors]', data.errors)
        )
      if (typeof data.data.customer === 'undefined')
        throw new Error(
          transformErrors('[Customer getOrders undefined]', [
            { message: "can't fetch user" },
          ])
        )
      return data.data.customer.orders
    } catch (e) {
      throw new Error(e)
    }
  }

  async customerAddressCreate(accessToken, input) {
    try {
      const body = JSON.stringify({
        query: customerAddressCreate,
        variables: {
          customerAccessToken: accessToken,
          address: {
            ...input,
          },
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length)
        throw new Error(
          transformErrors('[Customer AddressCreate errors]', data.errors)
        )

      if (
        data.data.customerAddressCreate.customerUserErrors &&
        data.data.customerAddressCreate.customerUserErrors.length
      )
        throw new Error(
          transformErrors(
            '[Customer AddressCreate customerUserErrors]',
            data.data.customerAddressCreate.customerUserErrors
          )
        )

      return data.data.customerAddressCreate
    } catch (e) {
      throw new Error(e)
    }
  }

  async customerAddressUpdate(accessToken, addressID, input) {
    try {
      const body = JSON.stringify({
        query: customerAddressUpdate,
        variables: {
          customerAccessToken: accessToken,
          id: addressID,
          address: {
            ...input,
          },
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length)
        throw new Error(
          transformErrors('[Customer updateAddress errors]', data.errors)
        )

      if (
        data.data.customerAddressUpdate.customerUserErrors &&
        data.data.customerAddressUpdate.customerUserErrors.length
      )
        throw new Error(
          transformErrors(
            '[Customer updateAddress customerUserErrors]',
            data.data.customerAddressUpdate.customerUserErrors
          )
        )

      return data.data.customerAddressUpdate
    } catch (e) {
      throw new Error(e)
    }
  }

  async customerAddressDelete(accessToken, addressID) {
    try {
      const body = JSON.stringify({
        query: customerAddressDelete,
        variables: {
          customerAccessToken: accessToken,
          id: addressID,
        },
      })

      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (data.errors && data.errors.length)
        throw new Error(
          transformErrors('[Customer addressDelete errors]', data.errors)
        )
      if (
        data.data.customerAddressDelete.customerUserErrors &&
        data.data.customerAddressDelete.customerUserErrors.length
      )
        throw new Error(
          transformErrors(
            '[Customer addressDelete customerUserErrors]',
            data.data.customerAddressDelete.customerUserErrors
          )
        )
      return data.data.customerAddressDelete
    } catch (e) {
      throw new Error(e)
    }
  }
}
