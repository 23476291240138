import Vue from 'vue'

const resizeUrl = (option, image) => {
  const imageService = '//img2.storyblok.com/'
  const path = image.replace('https://a.storyblok.com', '')

  return imageService + option + path
}

Vue.directive('img-storyblok', {
  bind(el, binding) {
    const url = binding.value
    if (!url) return console.error('url needed')
    if (url.split('https://a').length < 2)
      return console.errror('https://a.storyblok.com format needed')

    const sizes = '(max-width: 400px) 100vw, 80vw'
    const suffixResize = 'x0'
    const suffixW = 'w'
    const options = ['900', '500', '200']

    let srcset = `${binding.value} 1200w, `

    srcset += options
      .map((w) => {
        return 'http:' + resizeUrl(w + suffixResize, url) + ' ' + w + suffixW
      })
      .join(', ')
    el.setAttribute('srcset', srcset)
    el.setAttribute('sizes', sizes)
  },
})
