
import { mapState } from 'vuex'
import LoleSnackbar from '~/components/notification/lole-snackbar'
export default {
  components: {
    LoleSnackbar,
  },
  computed: {
    ...mapState({
      snackbars: (state) => state.notification.snackbars,
      story: (state) => state.notification.snackbarsStory,
    }),
    isMobile() {
      return !this.$store.getters['responsive/getIsWindowLarge']
    },
  },
}
