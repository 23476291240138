export const PdpBreadcrumb = () => import('../../components/product-page/pdp-breadcrumb.vue' /* webpackChunkName: "components/pdp-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const PdpCarouselBottom = () => import('../../components/product-page/pdp-carousel-bottom.vue' /* webpackChunkName: "components/pdp-carousel-bottom" */).then(c => wrapFunctional(c.default || c))
export const PdpCarouselCategory = () => import('../../components/product-page/pdp-carousel-category.vue' /* webpackChunkName: "components/pdp-carousel-category" */).then(c => wrapFunctional(c.default || c))
export const PdpIgphotos = () => import('../../components/product-page/pdp-igphotos.vue' /* webpackChunkName: "components/pdp-igphotos" */).then(c => wrapFunctional(c.default || c))
export const PdpLogs = () => import('../../components/product-page/pdp-logs.vue' /* webpackChunkName: "components/pdp-logs" */).then(c => wrapFunctional(c.default || c))
export const ProductPage = () => import('../../components/product-page/product-page.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c))
export const PdpBundles = () => import('../../components/product-page/pdp-bundles/index.vue' /* webpackChunkName: "components/pdp-bundles" */).then(c => wrapFunctional(c.default || c))
export const PdpBundlesQuickadd = () => import('../../components/product-page/pdp-bundles/quickadd.vue' /* webpackChunkName: "components/pdp-bundles-quickadd" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxAddToCart = () => import('../../components/product-page/pdp-buybox/add-to-cart.vue' /* webpackChunkName: "components/pdp-buybox-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxAfterpay = () => import('../../components/product-page/pdp-buybox/afterpay.vue' /* webpackChunkName: "components/pdp-buybox-afterpay" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxColorSwatchesItem = () => import('../../components/product-page/pdp-buybox/color-swatches-item.vue' /* webpackChunkName: "components/pdp-buybox-color-swatches-item" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxColorSwatches = () => import('../../components/product-page/pdp-buybox/color-swatches.vue' /* webpackChunkName: "components/pdp-buybox-color-swatches" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxCtaMain = () => import('../../components/product-page/pdp-buybox/cta-main.vue' /* webpackChunkName: "components/pdp-buybox-cta-main" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxFittingGuide = () => import('../../components/product-page/pdp-buybox/fitting-guide.vue' /* webpackChunkName: "components/pdp-buybox-fitting-guide" */).then(c => wrapFunctional(c.default || c))
export const PdpBuybox = () => import('../../components/product-page/pdp-buybox/index.vue' /* webpackChunkName: "components/pdp-buybox" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxPriceInfo = () => import('../../components/product-page/pdp-buybox/price-info.vue' /* webpackChunkName: "components/pdp-buybox-price-info" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxProductTitle = () => import('../../components/product-page/pdp-buybox/product-title.vue' /* webpackChunkName: "components/pdp-buybox-product-title" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxQuickaddModal = () => import('../../components/product-page/pdp-buybox/quickadd-modal.vue' /* webpackChunkName: "components/pdp-buybox-quickadd-modal" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxReturnsShipping = () => import('../../components/product-page/pdp-buybox/returns-shipping.vue' /* webpackChunkName: "components/pdp-buybox-returns-shipping" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxSizeGuide = () => import('../../components/product-page/pdp-buybox/size-guide.vue' /* webpackChunkName: "components/pdp-buybox-size-guide" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxSizeSelect = () => import('../../components/product-page/pdp-buybox/size-select.vue' /* webpackChunkName: "components/pdp-buybox-size-select" */).then(c => wrapFunctional(c.default || c))
export const PdpBuyboxStampedReviews = () => import('../../components/product-page/pdp-buybox/stamped-reviews.vue' /* webpackChunkName: "components/pdp-buybox-stamped-reviews" */).then(c => wrapFunctional(c.default || c))
export const PdpDescriptionBenefits = () => import('../../components/product-page/pdp-description/benefits.vue' /* webpackChunkName: "components/pdp-description-benefits" */).then(c => wrapFunctional(c.default || c))
export const PdpDescription = () => import('../../components/product-page/pdp-description/index.vue' /* webpackChunkName: "components/pdp-description" */).then(c => wrapFunctional(c.default || c))
export const PdpDescriptionMaterialCare = () => import('../../components/product-page/pdp-description/material-care.vue' /* webpackChunkName: "components/pdp-description-material-care" */).then(c => wrapFunctional(c.default || c))
export const PdpDescriptionProductDetails = () => import('../../components/product-page/pdp-description/product-details.vue' /* webpackChunkName: "components/pdp-description-product-details" */).then(c => wrapFunctional(c.default || c))
export const PdpDescriptionSizeFit = () => import('../../components/product-page/pdp-description/size-fit.vue' /* webpackChunkName: "components/pdp-description-size-fit" */).then(c => wrapFunctional(c.default || c))
export const PdpFeaturesFeatureIconsItem = () => import('../../components/product-page/pdp-features/feature-icons-item.vue' /* webpackChunkName: "components/pdp-features-feature-icons-item" */).then(c => wrapFunctional(c.default || c))
export const PdpFeatures = () => import('../../components/product-page/pdp-features/index.vue' /* webpackChunkName: "components/pdp-features" */).then(c => wrapFunctional(c.default || c))
export const PdpGallery = () => import('../../components/product-page/pdp-gallery/index.vue' /* webpackChunkName: "components/pdp-gallery" */).then(c => wrapFunctional(c.default || c))
export const PdpProductCalloutsCarouselProducts = () => import('../../components/product-page/pdp-product-callouts/carousel-products.vue' /* webpackChunkName: "components/pdp-product-callouts-carousel-products" */).then(c => wrapFunctional(c.default || c))
export const PdpProductCallouts = () => import('../../components/product-page/pdp-product-callouts/index.vue' /* webpackChunkName: "components/pdp-product-callouts" */).then(c => wrapFunctional(c.default || c))
export const PdpReviews = () => import('../../components/product-page/pdp-reviews/index.vue' /* webpackChunkName: "components/pdp-reviews" */).then(c => wrapFunctional(c.default || c))
export const PdpReviewsReviewForm = () => import('../../components/product-page/pdp-reviews/review-form.vue' /* webpackChunkName: "components/pdp-reviews-review-form" */).then(c => wrapFunctional(c.default || c))
export const PdpReviewsReviewStars = () => import('../../components/product-page/pdp-reviews/review-stars.vue' /* webpackChunkName: "components/pdp-reviews-review-stars" */).then(c => wrapFunctional(c.default || c))
export const PdpReviewsReviewTemplate = () => import('../../components/product-page/pdp-reviews/review-template.vue' /* webpackChunkName: "components/pdp-reviews-review-template" */).then(c => wrapFunctional(c.default || c))
export const ButtonsButton = () => import('../../components/buttons/Button.vue' /* webpackChunkName: "components/buttons-button" */).then(c => wrapFunctional(c.default || c))
export const ButtonsLoleBtn = () => import('../../components/buttons/lole-btn.vue' /* webpackChunkName: "components/buttons-lole-btn" */).then(c => wrapFunctional(c.default || c))
export const CarouselBannerSlide = () => import('../../components/carousel/carousel-banner-slide.vue' /* webpackChunkName: "components/carousel-banner-slide" */).then(c => wrapFunctional(c.default || c))
export const CartCarouselCart = () => import('../../components/cart/CarouselCart.vue' /* webpackChunkName: "components/cart-carousel-cart" */).then(c => wrapFunctional(c.default || c))
export const Cart = () => import('../../components/cart/Cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c))
export const CartEditQuantity = () => import('../../components/cart/CartEditQuantity.vue' /* webpackChunkName: "components/cart-edit-quantity" */).then(c => wrapFunctional(c.default || c))
export const CartEmpty = () => import('../../components/cart/CartEmpty.vue' /* webpackChunkName: "components/cart-empty" */).then(c => wrapFunctional(c.default || c))
export const CartNotification = () => import('../../components/cart/CartNotification.vue' /* webpackChunkName: "components/cart-notification" */).then(c => wrapFunctional(c.default || c))
export const CartNotificationBundle = () => import('../../components/cart/CartNotificationBundle.vue' /* webpackChunkName: "components/cart-notification-bundle" */).then(c => wrapFunctional(c.default || c))
export const CartNotificationsCarousels = () => import('../../components/cart/CartNotificationsCarousels.vue' /* webpackChunkName: "components/cart-notifications-carousels" */).then(c => wrapFunctional(c.default || c))
export const CartProduct = () => import('../../components/cart/CartProduct.vue' /* webpackChunkName: "components/cart-product" */).then(c => wrapFunctional(c.default || c))
export const CartProductPromoMessage = () => import('../../components/cart/CartProductPromoMessage.vue' /* webpackChunkName: "components/cart-product-promo-message" */).then(c => wrapFunctional(c.default || c))
export const CartPromoMessage = () => import('../../components/cart/CartPromoMessage.vue' /* webpackChunkName: "components/cart-promo-message" */).then(c => wrapFunctional(c.default || c))
export const CommonBackdrop = () => import('../../components/common/backdrop.vue' /* webpackChunkName: "components/common-backdrop" */).then(c => wrapFunctional(c.default || c))
export const CommonCurrencyDetectModal = () => import('../../components/common/currency-detect-modal.vue' /* webpackChunkName: "components/common-currency-detect-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonLoleBackdrop = () => import('../../components/common/lole-backdrop.vue' /* webpackChunkName: "components/common-lole-backdrop" */).then(c => wrapFunctional(c.default || c))
export const CommonLoleRecentlyViewed = () => import('../../components/common/lole-recently-viewed.vue' /* webpackChunkName: "components/common-lole-recently-viewed" */).then(c => wrapFunctional(c.default || c))
export const CommonLoleSwitchLocation = () => import('../../components/common/lole-switch-location.vue' /* webpackChunkName: "components/common-lole-switch-location" */).then(c => wrapFunctional(c.default || c))
export const ErrorCarouselErrorPage = () => import('../../components/error/carousel-error-page.vue' /* webpackChunkName: "components/error-carousel-error-page" */).then(c => wrapFunctional(c.default || c))
export const ErrorDevLogs = () => import('../../components/error/dev-logs.vue' /* webpackChunkName: "components/error-dev-logs" */).then(c => wrapFunctional(c.default || c))
export const Error404 = () => import('../../components/error/error-404.vue' /* webpackChunkName: "components/error404" */).then(c => wrapFunctional(c.default || c))
export const Error500 = () => import('../../components/error/error-500.vue' /* webpackChunkName: "components/error500" */).then(c => wrapFunctional(c.default || c))
export const ErrorXxx = () => import('../../components/error/error-xxx.vue' /* webpackChunkName: "components/error-xxx" */).then(c => wrapFunctional(c.default || c))
export const FooterDesktop = () => import('../../components/footer/footer-desktop.vue' /* webpackChunkName: "components/footer-desktop" */).then(c => wrapFunctional(c.default || c))
export const FooterMobileListItem = () => import('../../components/footer/footer-mobile-list-item.vue' /* webpackChunkName: "components/footer-mobile-list-item" */).then(c => wrapFunctional(c.default || c))
export const FooterMobileList = () => import('../../components/footer/footer-mobile-list.vue' /* webpackChunkName: "components/footer-mobile-list" */).then(c => wrapFunctional(c.default || c))
export const FooterMobile = () => import('../../components/footer/footer-mobile.vue' /* webpackChunkName: "components/footer-mobile" */).then(c => wrapFunctional(c.default || c))
export const FooterNewsletterForm = () => import('../../components/footer/footer-newsletter-form.vue' /* webpackChunkName: "components/footer-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const GoogleMapsLoleGoogleMaps = () => import('../../components/google-maps/lole-google-maps.vue' /* webpackChunkName: "components/google-maps-lole-google-maps" */).then(c => wrapFunctional(c.default || c))
export const HeaderDesktop = () => import('../../components/header/header-desktop.vue' /* webpackChunkName: "components/header-desktop" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobile = () => import('../../components/header/header-mobile.vue' /* webpackChunkName: "components/header-mobile" */).then(c => wrapFunctional(c.default || c))
export const LinkLoleLink = () => import('../../components/link/lole-link.vue' /* webpackChunkName: "components/link-lole-link" */).then(c => wrapFunctional(c.default || c))
export const ListViewGrid = () => import('../../components/list/view-grid.vue' /* webpackChunkName: "components/list-view-grid" */).then(c => wrapFunctional(c.default || c))
export const LoadersLoleLoader1 = () => import('../../components/loaders/lole-loader-1.vue' /* webpackChunkName: "components/loaders-lole-loader1" */).then(c => wrapFunctional(c.default || c))
export const LoadersLoleLoader = () => import('../../components/loaders/lole-loader.vue' /* webpackChunkName: "components/loaders-lole-loader" */).then(c => wrapFunctional(c.default || c))
export const MenuLoleContentNotifications = () => import('../../components/menu/lole-content-notifications.vue' /* webpackChunkName: "components/menu-lole-content-notifications" */).then(c => wrapFunctional(c.default || c))
export const MenuLoleSearchBar = () => import('../../components/menu/lole-search-bar.vue' /* webpackChunkName: "components/menu-lole-search-bar" */).then(c => wrapFunctional(c.default || c))
export const MenuLoleSearchResults = () => import('../../components/menu/lole-search-results.vue' /* webpackChunkName: "components/menu-lole-search-results" */).then(c => wrapFunctional(c.default || c))
export const MenuDesktop = () => import('../../components/menu/menu-desktop.vue' /* webpackChunkName: "components/menu-desktop" */).then(c => wrapFunctional(c.default || c))
export const MenuMobile = () => import('../../components/menu/menu-mobile.vue' /* webpackChunkName: "components/menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/menu/menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const ModalLoleModal = () => import('../../components/modal/lole-modal.vue' /* webpackChunkName: "components/modal-lole-modal" */).then(c => wrapFunctional(c.default || c))
export const NavigationMessageHeader = () => import('../../components/navigation/message-header.vue' /* webpackChunkName: "components/navigation-message-header" */).then(c => wrapFunctional(c.default || c))
export const NavigationDesktop = () => import('../../components/navigation/navigation-desktop.vue' /* webpackChunkName: "components/navigation-desktop" */).then(c => wrapFunctional(c.default || c))
export const NavigationMobile = () => import('../../components/navigation/navigation-mobile.vue' /* webpackChunkName: "components/navigation-mobile" */).then(c => wrapFunctional(c.default || c))
export const NavigationSearchDesktop = () => import('../../components/navigation/search-desktop.vue' /* webpackChunkName: "components/navigation-search-desktop" */).then(c => wrapFunctional(c.default || c))
export const NavigationSearchMobile = () => import('../../components/navigation/search-mobile.vue' /* webpackChunkName: "components/navigation-search-mobile" */).then(c => wrapFunctional(c.default || c))
export const NavigationSubHeader = () => import('../../components/navigation/sub-header.vue' /* webpackChunkName: "components/navigation-sub-header" */).then(c => wrapFunctional(c.default || c))
export const NotificationLoleContentContainer = () => import('../../components/notification/lole-content-container.vue' /* webpackChunkName: "components/notification-lole-content-container" */).then(c => wrapFunctional(c.default || c))
export const NotificationLoleContentNotifications = () => import('../../components/notification/lole-content-notifications.vue' /* webpackChunkName: "components/notification-lole-content-notifications" */).then(c => wrapFunctional(c.default || c))
export const NotificationLoleCountdown = () => import('../../components/notification/lole-countdown.vue' /* webpackChunkName: "components/notification-lole-countdown" */).then(c => wrapFunctional(c.default || c))
export const NotificationLoleCountryTopBar = () => import('../../components/notification/lole-country-top-bar.vue' /* webpackChunkName: "components/notification-lole-country-top-bar" */).then(c => wrapFunctional(c.default || c))
export const NotificationLoleSiteNotifications = () => import('../../components/notification/lole-site-notifications.vue' /* webpackChunkName: "components/notification-lole-site-notifications" */).then(c => wrapFunctional(c.default || c))
export const NotificationLoleSnackbarContainer = () => import('../../components/notification/lole-snackbar-container.vue' /* webpackChunkName: "components/notification-lole-snackbar-container" */).then(c => wrapFunctional(c.default || c))
export const NotificationLoleSnackbar = () => import('../../components/notification/lole-snackbar.vue' /* webpackChunkName: "components/notification-lole-snackbar" */).then(c => wrapFunctional(c.default || c))
export const PictoError404 = () => import('../../components/picto/error-404.vue' /* webpackChunkName: "components/picto-error404" */).then(c => wrapFunctional(c.default || c))
export const PictoError500 = () => import('../../components/picto/error-500.vue' /* webpackChunkName: "components/picto-error500" */).then(c => wrapFunctional(c.default || c))
export const PictureLolePicture = () => import('../../components/picture/lole-picture.vue' /* webpackChunkName: "components/picture-lole-picture" */).then(c => wrapFunctional(c.default || c))
export const PictureLoleVideoPicture = () => import('../../components/picture/lole-video-picture.vue' /* webpackChunkName: "components/picture-lole-video-picture" */).then(c => wrapFunctional(c.default || c))
export const Price = () => import('../../components/price/price.vue' /* webpackChunkName: "components/price" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/product-card/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductFlap = () => import('../../components/product-card/ProductFlap.vue' /* webpackChunkName: "components/product-flap" */).then(c => wrapFunctional(c.default || c))
export const RewardsBanner = () => import('../../components/rewards-page/rewards-banner.vue' /* webpackChunkName: "components/rewards-banner" */).then(c => wrapFunctional(c.default || c))
export const RewardsCtaJoinSignIn = () => import('../../components/rewards-page/rewards-cta-join-sign-in.vue' /* webpackChunkName: "components/rewards-cta-join-sign-in" */).then(c => wrapFunctional(c.default || c))
export const RewardsFaqItem = () => import('../../components/rewards-page/rewards-faq-item.vue' /* webpackChunkName: "components/rewards-faq-item" */).then(c => wrapFunctional(c.default || c))
export const RewardsFaq = () => import('../../components/rewards-page/rewards-faq.vue' /* webpackChunkName: "components/rewards-faq" */).then(c => wrapFunctional(c.default || c))
export const RewardsMenu = () => import('../../components/rewards-page/rewards-menu.vue' /* webpackChunkName: "components/rewards-menu" */).then(c => wrapFunctional(c.default || c))
export const RewardsPoints = () => import('../../components/rewards-page/rewards-points.vue' /* webpackChunkName: "components/rewards-points" */).then(c => wrapFunctional(c.default || c))
export const RewardsSteps = () => import('../../components/rewards-page/rewards-steps.vue' /* webpackChunkName: "components/rewards-steps" */).then(c => wrapFunctional(c.default || c))
export const RewardsTable = () => import('../../components/rewards-page/rewards-table.vue' /* webpackChunkName: "components/rewards-table" */).then(c => wrapFunctional(c.default || c))
export const SearchspringBacktotop = () => import('../../components/searchspring/backtotop.vue' /* webpackChunkName: "components/searchspring-backtotop" */).then(c => wrapFunctional(c.default || c))
export const SearchspringBreadcrumbs = () => import('../../components/searchspring/breadcrumbs.vue' /* webpackChunkName: "components/searchspring-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const SearchspringCarouselColorsFilter = () => import('../../components/searchspring/carouselColorsFilter.vue' /* webpackChunkName: "components/searchspring-carousel-colors-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchspringFooter = () => import('../../components/searchspring/footer.vue' /* webpackChunkName: "components/searchspring-footer" */).then(c => wrapFunctional(c.default || c))
export const SearchspringHeader = () => import('../../components/searchspring/header.vue' /* webpackChunkName: "components/searchspring-header" */).then(c => wrapFunctional(c.default || c))
export const Searchspring = () => import('../../components/searchspring/index.vue' /* webpackChunkName: "components/searchspring" */).then(c => wrapFunctional(c.default || c))
export const SearchspringRelatedCategories = () => import('../../components/searchspring/relatedCategories.vue' /* webpackChunkName: "components/searchspring-related-categories" */).then(c => wrapFunctional(c.default || c))
export const SigninSignupFormSignIn = () => import('../../components/signin-signup/FormSignIn.vue' /* webpackChunkName: "components/signin-signup-form-sign-in" */).then(c => wrapFunctional(c.default || c))
export const SigninSignupFormSignUp = () => import('../../components/signin-signup/FormSignUp.vue' /* webpackChunkName: "components/signin-signup-form-sign-up" */).then(c => wrapFunctional(c.default || c))
export const SizeLoleSizeV2 = () => import('../../components/size/lole-size-v2.vue' /* webpackChunkName: "components/size-lole-size-v2" */).then(c => wrapFunctional(c.default || c))
export const SizeLoleSize = () => import('../../components/size/lole-size.vue' /* webpackChunkName: "components/size-lole-size" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideLoleSizeGuide = () => import('../../components/size-guide/lole-size-guide.vue' /* webpackChunkName: "components/size-guide-lole-size-guide" */).then(c => wrapFunctional(c.default || c))
export const SizeGuideLoleSizeTable = () => import('../../components/size-guide/lole-size-table.vue' /* webpackChunkName: "components/size-guide-lole-size-table" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageColumns = () => import('../../components/static-page/lole-static-page-columns.vue' /* webpackChunkName: "components/static-page-lole-static-page-columns" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageCtaBanner = () => import('../../components/static-page/lole-static-page-cta-banner.vue' /* webpackChunkName: "components/static-page-lole-static-page-cta-banner" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageExclusiveDealCard = () => import('../../components/static-page/lole-static-page-exclusive-deal-card.vue' /* webpackChunkName: "components/static-page-lole-static-page-exclusive-deal-card" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageH1 = () => import('../../components/static-page/lole-static-page-h1.vue' /* webpackChunkName: "components/static-page-lole-static-page-h1" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageH2 = () => import('../../components/static-page/lole-static-page-h2.vue' /* webpackChunkName: "components/static-page-lole-static-page-h2" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageHeader = () => import('../../components/static-page/lole-static-page-header.vue' /* webpackChunkName: "components/static-page-lole-static-page-header" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageHeroVideo = () => import('../../components/static-page/lole-static-page-hero-video.vue' /* webpackChunkName: "components/static-page-lole-static-page-hero-video" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageImageCarousel = () => import('../../components/static-page/lole-static-page-image-carousel.vue' /* webpackChunkName: "components/static-page-lole-static-page-image-carousel" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageImageCollage = () => import('../../components/static-page/lole-static-page-image-collage.vue' /* webpackChunkName: "components/static-page-lole-static-page-image-collage" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageInfoCard3 = () => import('../../components/static-page/lole-static-page-info-card-3.vue' /* webpackChunkName: "components/static-page-lole-static-page-info-card3" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageInfoCard = () => import('../../components/static-page/lole-static-page-info-card.vue' /* webpackChunkName: "components/static-page-lole-static-page-info-card" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageLeadershipCard = () => import('../../components/static-page/lole-static-page-leadership-card.vue' /* webpackChunkName: "components/static-page-lole-static-page-leadership-card" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageProfileCard = () => import('../../components/static-page/lole-static-page-profile-card.vue' /* webpackChunkName: "components/static-page-lole-static-page-profile-card" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageRow = () => import('../../components/static-page/lole-static-page-row.vue' /* webpackChunkName: "components/static-page-lole-static-page-row" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageSectionTitleImage = () => import('../../components/static-page/lole-static-page-section-title-image.vue' /* webpackChunkName: "components/static-page-lole-static-page-section-title-image" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageSectionTitle = () => import('../../components/static-page/lole-static-page-section-title.vue' /* webpackChunkName: "components/static-page-lole-static-page-section-title" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageText = () => import('../../components/static-page/lole-static-page-text.vue' /* webpackChunkName: "components/static-page-lole-static-page-text" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageTitleParagraph = () => import('../../components/static-page/lole-static-page-title-paragraph.vue' /* webpackChunkName: "components/static-page-lole-static-page-title-paragraph" */).then(c => wrapFunctional(c.default || c))
export const StaticPageLoleStaticPageWaveHeader = () => import('../../components/static-page/lole-static-page-wave-header.vue' /* webpackChunkName: "components/static-page-lole-static-page-wave-header" */).then(c => wrapFunctional(c.default || c))
export const Swiper = () => import('../../components/swiper/index.vue' /* webpackChunkName: "components/swiper" */).then(c => wrapFunctional(c.default || c))
export const TextLoleH1 = () => import('../../components/text/lole-h1.vue' /* webpackChunkName: "components/text-lole-h1" */).then(c => wrapFunctional(c.default || c))
export const TextLoleH2 = () => import('../../components/text/lole-h2.vue' /* webpackChunkName: "components/text-lole-h2" */).then(c => wrapFunctional(c.default || c))
export const TextLoleH3 = () => import('../../components/text/lole-h3.vue' /* webpackChunkName: "components/text-lole-h3" */).then(c => wrapFunctional(c.default || c))
export const TextLoleH4 = () => import('../../components/text/lole-h4.vue' /* webpackChunkName: "components/text-lole-h4" */).then(c => wrapFunctional(c.default || c))
export const TextLoleP = () => import('../../components/text/lole-p.vue' /* webpackChunkName: "components/text-lole-p" */).then(c => wrapFunctional(c.default || c))
export const AccountGiftCardSavedItem = () => import('../../components/account/gift-card/gift-card-saved-item.vue' /* webpackChunkName: "components/account-gift-card-saved-item" */).then(c => wrapFunctional(c.default || c))
export const AccountGiftCardsSaved = () => import('../../components/account/gift-card/gift-cards-saved.vue' /* webpackChunkName: "components/account-gift-cards-saved" */).then(c => wrapFunctional(c.default || c))
export const AccountMenuAccountMobile = () => import('../../components/account/menu/menu-account-mobile.vue' /* webpackChunkName: "components/account-menu-account-mobile" */).then(c => wrapFunctional(c.default || c))
export const AccountMenuAccount = () => import('../../components/account/menu/menu-account.vue' /* webpackChunkName: "components/account-menu-account" */).then(c => wrapFunctional(c.default || c))
export const AccountOrdersOrderCard = () => import('../../components/account/orders/order-card.vue' /* webpackChunkName: "components/account-orders-order-card" */).then(c => wrapFunctional(c.default || c))
export const AccountOverviewCarouselAccount = () => import('../../components/account/overview/overview-carousel-account.vue' /* webpackChunkName: "components/account-overview-carousel-account" */).then(c => wrapFunctional(c.default || c))
export const AccountOverviewNewCustomer = () => import('../../components/account/overview/overview-new-customer.vue' /* webpackChunkName: "components/account-overview-new-customer" */).then(c => wrapFunctional(c.default || c))
export const AccountOverviewRewardsBronzeCard = () => import('../../components/account/overview/overview-rewards-bronze-card.vue' /* webpackChunkName: "components/account-overview-rewards-bronze-card" */).then(c => wrapFunctional(c.default || c))
export const AccountOverviewRewardsEmployeeCard = () => import('../../components/account/overview/overview-rewards-employee-card.vue' /* webpackChunkName: "components/account-overview-rewards-employee-card" */).then(c => wrapFunctional(c.default || c))
export const AccountOverviewRewardsGoldCard = () => import('../../components/account/overview/overview-rewards-gold-card.vue' /* webpackChunkName: "components/account-overview-rewards-gold-card" */).then(c => wrapFunctional(c.default || c))
export const AccountOverviewRewardsProgram = () => import('../../components/account/overview/overview-rewards-program.vue' /* webpackChunkName: "components/account-overview-rewards-program" */).then(c => wrapFunctional(c.default || c))
export const AccountOverviewRewardsSilverCard = () => import('../../components/account/overview/overview-rewards-silver-card.vue' /* webpackChunkName: "components/account-overview-rewards-silver-card" */).then(c => wrapFunctional(c.default || c))
export const AccountPersonalInformationAddressBookForm = () => import('../../components/account/personal-information/address-book-form.vue' /* webpackChunkName: "components/account-personal-information-address-book-form" */).then(c => wrapFunctional(c.default || c))
export const AccountPersonalInformationBirthdayForm = () => import('../../components/account/personal-information/birthday-form.vue' /* webpackChunkName: "components/account-personal-information-birthday-form" */).then(c => wrapFunctional(c.default || c))
export const AccountPersonalInformationChangePasswordForm = () => import('../../components/account/personal-information/change-password-form.vue' /* webpackChunkName: "components/account-personal-information-change-password-form" */).then(c => wrapFunctional(c.default || c))
export const AccountPersonalInformationEditAddressForm = () => import('../../components/account/personal-information/edit-address-form.vue' /* webpackChunkName: "components/account-personal-information-edit-address-form" */).then(c => wrapFunctional(c.default || c))
export const AccountPersonalInformationInterestsForm = () => import('../../components/account/personal-information/interests-form.vue' /* webpackChunkName: "components/account-personal-information-interests-form" */).then(c => wrapFunctional(c.default || c))
export const AccountPersonalInformationNameContactForm = () => import('../../components/account/personal-information/name-contact-form.vue' /* webpackChunkName: "components/account-personal-information-name-contact-form" */).then(c => wrapFunctional(c.default || c))
export const AccountPersonalInformationNewAddressFormVue = () => import('../../components/account/personal-information/new-address-form.vue.vue' /* webpackChunkName: "components/account-personal-information-new-address-form-vue" */).then(c => wrapFunctional(c.default || c))
export const AccountReviewsOrderReviewsCard = () => import('../../components/account/reviews/order-reviews-card.vue' /* webpackChunkName: "components/account-reviews-order-reviews-card" */).then(c => wrapFunctional(c.default || c))
export const AccountReviewsReviewForm = () => import('../../components/account/reviews/review-form.vue' /* webpackChunkName: "components/account-reviews-review-form" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitPage = () => import('../../components/bundle-page/outfit/outfit-page.vue' /* webpackChunkName: "components/bundle-page-outfit-page" */).then(c => wrapFunctional(c.default || c))
export const CommonProductGalleryMobile = () => import('../../components/common/product-gallery/gallery-mobile.vue' /* webpackChunkName: "components/common-product-gallery-mobile" */).then(c => wrapFunctional(c.default || c))
export const CommonProductGalleryTemplate = () => import('../../components/common/product-gallery/gallery-template.vue' /* webpackChunkName: "components/common-product-gallery-template" */).then(c => wrapFunctional(c.default || c))
export const CommonProductGalleryZoom = () => import('../../components/common/product-gallery/gallery-zoom.vue' /* webpackChunkName: "components/common-product-gallery-zoom" */).then(c => wrapFunctional(c.default || c))
export const FormCheckbox = () => import('../../components/form/checkbox/Checkbox.vue' /* webpackChunkName: "components/form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FormErrorForm = () => import('../../components/form/error/ErrorForm.vue' /* webpackChunkName: "components/form-error-form" */).then(c => wrapFunctional(c.default || c))
export const FormHelpersInputPassword = () => import('../../components/form/helpers/HelpersInputPassword.vue' /* webpackChunkName: "components/form-helpers-input-password" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../../components/form/input/Input.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const FormInputPassword = () => import('../../components/form/input/InputPassword.vue' /* webpackChunkName: "components/form-input-password" */).then(c => wrapFunctional(c.default || c))
export const FormRadioBtnLoleRadioBtn = () => import('../../components/form/radio-btn/lole-radio-btn.vue' /* webpackChunkName: "components/form-radio-btn-lole-radio-btn" */).then(c => wrapFunctional(c.default || c))
export const FormSelectLoleSelect = () => import('../../components/form/select/lole-select.vue' /* webpackChunkName: "components/form-select-lole-select" */).then(c => wrapFunctional(c.default || c))
export const FormTextareaLoleTextarea = () => import('../../components/form/textarea/lole-textarea.vue' /* webpackChunkName: "components/form-textarea-lole-textarea" */).then(c => wrapFunctional(c.default || c))
export const RewardsEarnPointsReferFriend = () => import('../../components/rewards-page/rewards-earn-points/rewards-earn-points-refer-friend.vue' /* webpackChunkName: "components/rewards-earn-points-refer-friend" */).then(c => wrapFunctional(c.default || c))
export const RewardsEarnPointsSubscribeNewsletter = () => import('../../components/rewards-page/rewards-earn-points/rewards-earn-points-subscribe-newsletter.vue' /* webpackChunkName: "components/rewards-earn-points-subscribe-newsletter" */).then(c => wrapFunctional(c.default || c))
export const RewardsEarnPoints = () => import('../../components/rewards-page/rewards-earn-points/rewards-earn-points.vue' /* webpackChunkName: "components/rewards-earn-points" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentFullInlineBanner = () => import('../../components/searchspring/plp-content/FullInlineBanner.vue' /* webpackChunkName: "components/searchspring-plp-content-full-inline-banner" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentInlineBanner = () => import('../../components/searchspring/plp-content/InlineBanner.vue' /* webpackChunkName: "components/searchspring-plp-content-inline-banner" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentEmpty = () => import('../../components/searchspring/plp-content/empty.vue' /* webpackChunkName: "components/searchspring-plp-content-empty" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContent = () => import('../../components/searchspring/plp-content/index.vue' /* webpackChunkName: "components/searchspring-plp-content" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentTopBanner = () => import('../../components/searchspring/plp-content/topBanner.vue' /* webpackChunkName: "components/searchspring-plp-content-top-banner" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageCarouselCollections = () => import('../../components/storyblok/components-homepage/component-homepage-carousel-collections.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-carousel-collections" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageCarouselProducts = () => import('../../components/storyblok/components-homepage/component-homepage-carousel-products.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-carousel-products" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageHorizontalShowcase = () => import('../../components/storyblok/components-homepage/component-homepage-horizontal-showcase.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-horizontal-showcase" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageRow = () => import('../../components/storyblok/components-homepage/component-homepage-row.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-row" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageSeparator = () => import('../../components/storyblok/components-homepage/component-homepage-separator.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-separator" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageVerticalShowcase = () => import('../../components/storyblok/components-homepage/component-homepage-vertical-showcase.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-vertical-showcase" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticBanner = () => import('../../components/storyblok/components-static/component-static-banner.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-banner" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticCarouselCollections = () => import('../../components/storyblok/components-static/component-static-carousel-collections.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-carousel-collections" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticCarouselV3 = () => import('../../components/storyblok/components-static/component-static-carousel-v3.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-carousel-v3" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticCarouselWithContent = () => import('../../components/storyblok/components-static/component-static-carousel-with-content.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-carousel-with-content" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticCarousel = () => import('../../components/storyblok/components-static/component-static-carousel.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-carousel" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticGrid = () => import('../../components/storyblok/components-static/component-static-grid.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-grid" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticListArticles = () => import('../../components/storyblok/components-static/component-static-list-articles.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-list-articles" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticListProducts = () => import('../../components/storyblok/components-static/component-static-list-products.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-list-products" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticMenuAnchor = () => import('../../components/storyblok/components-static/component-static-menu-anchor.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-menu-anchor" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticNewsletterForm = () => import('../../components/storyblok/components-static/component-static-newsletter-form.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticSeparator = () => import('../../components/storyblok/components-static/component-static-separator.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-separator" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticSwitch2 = () => import('../../components/storyblok/components-static/component-static-switch-2.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-switch2" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticSwitch = () => import('../../components/storyblok/components-static/component-static-switch.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-switch" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticTableInfo = () => import('../../components/storyblok/components-static/component-static-table-info.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-table-info" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticComponentStaticTestimonials = () => import('../../components/storyblok/components-static/component-static-testimonials.vue' /* webpackChunkName: "components/storyblok-components-static-component-static-testimonials" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleBuybox = () => import('../../components/bundle-page/outfit/bundle-buybox/index.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-buybox" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleBuyboxReturnsShipping = () => import('../../components/bundle-page/outfit/bundle-buybox/returns-shipping.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-buybox-returns-shipping" */).then(c => wrapFunctional(c.default || c))
export const BundleBreadcrumb = () => import('../../components/bundle-page/outfit/bundle-common/bundle-breadcrumb.vue' /* webpackChunkName: "components/bundle-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const BundleTitle = () => import('../../components/bundle-page/outfit/bundle-common/bundle-title.vue' /* webpackChunkName: "components/bundle-title" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleCommonFittingGuide = () => import('../../components/bundle-page/outfit/bundle-common/fitting-guide.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-common-fitting-guide" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleCommonSizeGuide = () => import('../../components/bundle-page/outfit/bundle-common/size-guide.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-common-size-guide" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleGallery = () => import('../../components/bundle-page/outfit/bundle-gallery/index.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-gallery" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleModalDetails = () => import('../../components/bundle-page/outfit/bundle-modal-details/index.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-modal-details" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleModalDetailsContentFeaturesIcon = () => import('../../components/bundle-page/outfit/bundle-modal-details/modal-details-content-features-icon.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-modal-details-content-features-icon" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleModalDetailsContentFeatures = () => import('../../components/bundle-page/outfit/bundle-modal-details/modal-details-content-features.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-modal-details-content-features" */).then(c => wrapFunctional(c.default || c))
export const BundleFittingGuide = () => import('../../components/bundle-page/outfit/bundle-product/bundle-fitting-guide.vue' /* webpackChunkName: "components/bundle-fitting-guide" */).then(c => wrapFunctional(c.default || c))
export const BundleProductAddToBundle = () => import('../../components/bundle-page/outfit/bundle-product/bundle-product-add-to-bundle.vue' /* webpackChunkName: "components/bundle-product-add-to-bundle" */).then(c => wrapFunctional(c.default || c))
export const BundleProductColorBlock = () => import('../../components/bundle-page/outfit/bundle-product/bundle-product-color-block.vue' /* webpackChunkName: "components/bundle-product-color-block" */).then(c => wrapFunctional(c.default || c))
export const BundleProductColors = () => import('../../components/bundle-page/outfit/bundle-product/bundle-product-colors.vue' /* webpackChunkName: "components/bundle-product-colors" */).then(c => wrapFunctional(c.default || c))
export const BundleProductPrice = () => import('../../components/bundle-page/outfit/bundle-product/bundle-product-price.vue' /* webpackChunkName: "components/bundle-product-price" */).then(c => wrapFunctional(c.default || c))
export const BundleProductSizes = () => import('../../components/bundle-page/outfit/bundle-product/bundle-product-sizes.vue' /* webpackChunkName: "components/bundle-product-sizes" */).then(c => wrapFunctional(c.default || c))
export const BundleProductTitle = () => import('../../components/bundle-page/outfit/bundle-product/bundle-product-title.vue' /* webpackChunkName: "components/bundle-product-title" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleProduct = () => import('../../components/bundle-page/outfit/bundle-product/index.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-product" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleSizeGuide = () => import('../../components/bundle-page/outfit/bundle-size-guide/index.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-size-guide" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFilters = () => import('../../components/searchspring/plp-content/plp-filters/index.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpResults = () => import('../../components/searchspring/plp-content/plp-results/index.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-results" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBannerHtml = () => import('../../components/storyblok/components-homepage/component-homepage-banner/component-homepage-banner-html.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-banner-html" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBannerSvgContainer = () => import('../../components/storyblok/components-homepage/component-homepage-banner/component-homepage-banner-svg-container.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-banner-svg-container" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBannerSvg = () => import('../../components/storyblok/components-homepage/component-homepage-banner/component-homepage-banner-svg.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-banner-svg" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBannerVideo = () => import('../../components/storyblok/components-homepage/component-homepage-banner/component-homepage-banner-video.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-banner-video" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBanner = () => import('../../components/storyblok/components-homepage/component-homepage-banner/component-homepage-banner.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-banner" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBannerSlider = () => import('../../components/storyblok/components-homepage/component-homepage-banner-slider/index.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-banner-slider" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBannerV2 = () => import('../../components/storyblok/components-homepage/component-homepage-banner-v2/component-homepage-banner-v2.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-banner-v2" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticBannersComponentStaticBannerHtml = () => import('../../components/storyblok/components-static/banners/component-static-banner-html.vue' /* webpackChunkName: "components/storyblok-components-static-banners-component-static-banner-html" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticBannersComponentStaticBannerSvg = () => import('../../components/storyblok/components-static/banners/component-static-banner-svg.vue' /* webpackChunkName: "components/storyblok-components-static-banners-component-static-banner-svg" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticBaseComponentStaticBaseButton = () => import('../../components/storyblok/components-static/base/component-static-base-button.vue' /* webpackChunkName: "components/storyblok-components-static-base-component-static-base-button" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticBaseComponentStaticBaseImage = () => import('../../components/storyblok/components-static/base/component-static-base-image.vue' /* webpackChunkName: "components/storyblok-components-static-base-component-static-base-image" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticBaseComponentStaticBaseLink = () => import('../../components/storyblok/components-static/base/component-static-base-link.vue' /* webpackChunkName: "components/storyblok-components-static-base-component-static-base-link" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticBaseComponentStaticBaseParagraph = () => import('../../components/storyblok/components-static/base/component-static-base-paragraph.vue' /* webpackChunkName: "components/storyblok-components-static-base-component-static-base-paragraph" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticBaseComponentStaticBaseText = () => import('../../components/storyblok/components-static/base/component-static-base-text.vue' /* webpackChunkName: "components/storyblok-components-static-base-component-static-base-text" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticBaseComponentStaticBaseTitle = () => import('../../components/storyblok/components-static/base/component-static-base-title.vue' /* webpackChunkName: "components/storyblok-components-static-base-component-static-base-title" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticBaseComponentStaticBaseVideo = () => import('../../components/storyblok/components-static/base/component-static-base-video.vue' /* webpackChunkName: "components/storyblok-components-static-base-component-static-base-video" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticStructureComponentStaticColumn = () => import('../../components/storyblok/components-static/structure/component-static-column.vue' /* webpackChunkName: "components/storyblok-components-static-structure-component-static-column" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsStaticStructureComponentStaticRow = () => import('../../components/storyblok/components-static/structure/component-static-row.vue' /* webpackChunkName: "components/storyblok-components-static-structure-component-static-row" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleModalDetailsModalDetailsContentMoreDetails = () => import('../../components/bundle-page/outfit/bundle-modal-details/modal-details-content-more-details/index.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-modal-details-modal-details-content-more-details" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleModalDetailsModalDetailsContentMoreDetailsMaterialCare = () => import('../../components/bundle-page/outfit/bundle-modal-details/modal-details-content-more-details/material-care.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-modal-details-modal-details-content-more-details-material-care" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleModalDetailsModalDetailsContentMoreDetailsProductDetails = () => import('../../components/bundle-page/outfit/bundle-modal-details/modal-details-content-more-details/product-details.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-modal-details-modal-details-content-more-details-product-details" */).then(c => wrapFunctional(c.default || c))
export const BundlePageOutfitBundleModalDetailsModalDetailsContentMoreDetailsSizeFit = () => import('../../components/bundle-page/outfit/bundle-modal-details/modal-details-content-more-details/size-fit.vue' /* webpackChunkName: "components/bundle-page-outfit-bundle-modal-details-modal-details-content-more-details-size-fit" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersFilterAllfiltersActiveFilterToggle = () => import('../../components/searchspring/plp-content/plp-filters/filter-allfilters/activeFilterToggle.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-filter-allfilters-active-filter-toggle" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersFilterListItem = () => import('../../components/searchspring/plp-content/plp-filters/filter-allfilters/filterListItem.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-filter-list-item" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersFilterAllfilters = () => import('../../components/searchspring/plp-content/plp-filters/filter-allfilters/index.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-filter-allfilters" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersFilterAllfiltersSortyBy = () => import('../../components/searchspring/plp-content/plp-filters/filter-allfilters/sortyBy.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-filter-allfilters-sorty-by" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersFilterCheckbox = () => import('../../components/searchspring/plp-content/plp-filters/filter-checkbox/index.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-filter-checkbox" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersFilterColorSwatch = () => import('../../components/searchspring/plp-content/plp-filters/filter-color/color-swatch.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-filter-color-swatch" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersFilterColor = () => import('../../components/searchspring/plp-content/plp-filters/filter-color/index.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-filter-color" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersFilterPrices = () => import('../../components/searchspring/plp-content/plp-filters/filter-prices/index.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-filter-prices" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersFilterReset = () => import('../../components/searchspring/plp-content/plp-filters/filter-reset/index.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-filter-reset" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersFilterSize = () => import('../../components/searchspring/plp-content/plp-filters/filter-size/index.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-filter-size" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersFilterSortby = () => import('../../components/searchspring/plp-content/plp-filters/filter-sortby/index.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-filter-sortby" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersUiFilterDropdown = () => import('../../components/searchspring/plp-content/plp-filters/ui/filter-dropdown.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-ui-filter-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SearchspringPlpContentPlpFiltersUiFilterToggle = () => import('../../components/searchspring/plp-content/plp-filters/ui/filter-toggle.vue' /* webpackChunkName: "components/searchspring-plp-content-plp-filters-ui-filter-toggle" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBannerContent = () => import('../../components/storyblok/components-homepage/component-homepage-banner/banner/banner-content.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-banner-content" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBannerItemButton = () => import('../../components/storyblok/components-homepage/component-homepage-banner/content/component-homepage-banner-item-button.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-banner-item-button" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBannerItemSvg = () => import('../../components/storyblok/components-homepage/component-homepage-banner/content/component-homepage-banner-item-svg.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-banner-item-svg" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBannerItemText = () => import('../../components/storyblok/components-homepage/component-homepage-banner/content/component-homepage-banner-item-text.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-banner-item-text" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBaseButton = () => import('../../components/storyblok/components-homepage/component-homepage-banner-v2/base/component-homepage-base-button.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-base-button" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBaseText = () => import('../../components/storyblok/components-homepage/component-homepage-banner-v2/base/component-homepage-base-text.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-base-text" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageBaseTitle = () => import('../../components/storyblok/components-homepage/component-homepage-banner-v2/base/component-homepage-base-title.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-base-title" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageColumn = () => import('../../components/storyblok/components-homepage/component-homepage-banner-v2/structure/component-homepage-column.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-column" */).then(c => wrapFunctional(c.default || c))
export const StoryblokComponentsHomepageComponentHomepageRowV2 = () => import('../../components/storyblok/components-homepage/component-homepage-banner-v2/structure/component-homepage-row-v2.vue' /* webpackChunkName: "components/storyblok-components-homepage-component-homepage-row-v2" */).then(c => wrapFunctional(c.default || c))
export const BundlePageKitKitSectionKitProductModalDetailsModalDetailsContentMoreDetailsReviews = () => import('../../components/bundle-page/kit/kit-section/kit-product-modal-details/modal-details-content-more-details/reviews.vue' /* webpackChunkName: "components/bundle-page-kit-kit-section-kit-product-modal-details-modal-details-content-more-details-reviews" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
