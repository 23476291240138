
import { mapGetters } from 'vuex'
import ProductCard from '@/components/product-card/ProductCard'
export default {
  components: { ProductCard },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    products: [],
  }),
  computed: {
    ...mapGetters({
      vxCurrency: 'currency/getCurrency',
    }),
  },
  watch: {
    async vxCurrency() {
      await this.getStaticComponentProducts()
    },
  },
  async beforeMount() {
    await this.getStaticComponentProducts()
  },
  methods: {
    async getStaticComponentProducts() {
      if (this.blok?.products?.length > 0)
        this.products = await this.fetchProductsFromShopify(
          this.blok?.products,
          this.vxCurrency
        )
    },
  },
}
