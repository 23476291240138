// https://shopify.dev/docs/api/storefront/2024-04/objects/product
export const giftCardFetch = `
query giftCardFetch ($countryCode: CountryCode!)
@inContext(country: $countryCode) {
  product(handle: "lole-gift-card") {
    title
    images(first: 3) {
      edges {
        node {
          altText
          url
        }
      }
    }
    variants(first: 100) {
      edges {
        node {
          id
          title
          price {
            amount
            currencyCode
          }
        }
      }
    }
  }
}`
