import atob from 'atob'

export const state = () => ({
  id: null,
  lines: null,
  totalQuantity: 0,
  checkoutUrl: null,
  totalAmount: null,
  subtotalAmount: null,
  totalTaxAmount: null,
})

export const getters = {
  getCartId(state) {
    return state.id
  },
  getCart(state) {
    return {
      id: state.id,
      lines: state.lines,
      totalQuantity: state.totalQuantity,
      checkoutUrl: state.checkoutUrl,
      totalAmount: state.totalAmount,
      subtotalAmount: state.subtotalAmount,
      totalTaxAmount: state.totalTaxAmount,
    }
  },
  getTotalQuantity(state) {
    return state.totalQuantity
  },
  getCartLines(state) {
    return state.lines && state.lines.edges ? state.lines.edges : []
  },
}

export const mutations = {
  setCart(
    state,
    {
      id,
      lines,
      totalQuantity,
      checkoutUrl,
      cost: { totalAmount, subtotalAmount, totalTaxAmount },
    }
  ) {
    state.id = id
    state.lines = lines
    state.totalQuantity = totalQuantity
    state.checkoutUrl = checkoutUrl
    state.totalAmount = totalAmount
    state.subtotalAmount = subtotalAmount
    state.totalTaxAmount = totalTaxAmount
  },
}

export const actions = {
  async createCart({ commit, rootGetters }, { products }) {
    try {
      const currency = rootGetters['currency/getCurrency']
      const lines = products?.length > 0 ? buildLinesArray(products) : []
      const { cart, userErrors } = await this.app.$shopify.cart.create(
        lines,
        currency
      )

      if (userErrors.length > 0) throw new Error(userErrors)
      else {
        commit('setCart', cart)
        await this.app.$cookies.set('lole-cart', cart.id, {
          maxAge: 60 * 60 * 24 * 30,
          path: '/',
        })
      }
    } catch (error) {
      this.app.$sentryException(error, {
        source: 'ERROR STORE - cart/createCart(): ',
        level: 'fatal',
        extra: error,
      })
    }
  },

  async retrieveCart({ commit, rootGetters, dispatch }, { cartId }) {
    try {
      const currency = rootGetters['currency/getCurrency']
      const cart = await this.app.$shopify.cart.retrieve(cartId, currency)
      if (!cart) throw new Error(`error retrieving cart -- ${cart} -- ${cartId} -- ${currency}`)
      commit('setCart', cart)
      const productsToUpdate = cart.lines.edges.reduce((acc, { node }) => {
        if (node.merchandise.price.amount === '0.0') {
          acc.push({
            quantity: 0,
            variant: {
              id: node.merchandise.id,
            },
          })
        }
        return acc
      }, [])

      if (productsToUpdate.length)
        await dispatch('updateLinesToCart', { products: productsToUpdate })

      await this.app.$cookies.remove('lole-cart')
      await this.app.$cookies.set('lole-cart', cart.id, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      })
    } catch (error) {
      await this.app.$cookies.remove('lole-cart')
      this.app.$sentryException(error, {
        source: 'STORE cart',
        level: 'fatal',
        extra: `ERROR STORE - cart/retrieveCart(): ${error}`,
      })
    }
  },
  async updateLinesToCart({ getters, commit, rootGetters }, { products }) {
    try {
      const currency = rootGetters['currency/getCurrency']
      const cartId = getters.getCartId
      const cartLines = getters.getCartLines
      const { cart, userErrors } = await this.app.$shopify.cart.updateLines(
        cartId,
        findLinesToUpdate(products, cartLines),
        currency
      )
      if (userErrors.length > 0) throw new Error(userErrors)
      else commit('setCart', cart)
    } catch (error) {
      this.app.$sentryException(error, {
        source: 'STORE cart',
        level: 'fatal',
        extra: `ERROR STORE - cart/updateLinesToCart(): ${error}`,
      })
    }
  },
  async clear({ commit }) {
    try {
      commit('setCart', {
        id: null,
        lines: null,
        totalQuantity: 0,
        checkoutUrl: null,
        cost: {
          totalAmount: null,
          subtotalAmount: null,
          totalTaxAmount: null,
        },
      })
      await this.app.$cookies.remove('lole-cart')
    } catch (error) {
      this.app.$sentryException(error, {
        source: 'STORE cart',
        level: 'fatal',
        extra: `ERROR STORE - cart/clear(): ${error}`,
      })
    }
  },
  async addProductsToCart({ getters, dispatch, commit }, { products }) {
    try {
      if (getters.getCartId) await dispatch('addLinesToCart', { products })
      else await dispatch('createCart', { products })

      if (products.length > 1) {
        commit(
          'notification/setAddToCartBundleNotification',
          {
            error: false,
            products,
          },
          { root: true }
        )
      } else {
        commit(
          'notification/setAddToCartNotification',
          {
            error: false,
            product: {
              product: products[0].product,
              selectedVariant: products[0].variant,
              quantity: products[0].quantity,
            },
          },
          { root: true }
        )
      }
    } catch (error) {
      commit(
        'notification/setAddToCartNotification',
        {
          error: true,
          product: {
            product: products[0].product,
            selectedVariant: products[0].variant,
            quantity: products[0].quantity,
          },
        },
        { root: true }
      )
      this.app.$sentryException(error, {
        source: 'STORE cart',
        level: 'fatal',
        extra: `ERROR STORE - cart/addProductsToCart(): ${error} `,
      })
    }
  },
  async goToCheckoutWithCart({ getters, rootGetters }, { GAIdSession }) {
    try {
      const cart = getters.getCart
      const customer = rootGetters['customer/getBasicCustomer']

      let redirectionUrl = null
      const currentLang = this.app.i18n.locale
      const urlLocale = currentLang ? `&locale=${currentLang}` : ''
      const GAId = GAIdSession ? `&${GAIdSession}` : ''

      if (cart.checkoutUrl) {
        redirectionUrl = `${cart.checkoutUrl}${urlLocale}${GAId}`
      }

      if (customer) {
        const { data } = await this.$axios.post(
          window.location.origin + '/api/multipass',
          {
            customer,
            checkoutUrl: redirectionUrl,
          }
        )
        window.location.href = data
      } else {
        window.location.href = redirectionUrl
      }
    } catch (error) {
      this.app.$sentryException(error, {
        source: 'STORE cart',
        level: 'fatal',
        extra: `ERROR STORE - cart/goToCheckoutWithCart(): ${error}`,
      })
    }
  },
  async addLinesToCart({ getters, commit, rootGetters }, { products }) {
    try {
      const currency = rootGetters['currency/getCurrency']
      const cartId = getters.getCartId
      const lines = buildLinesArray(products)
      const { cart, userErrors } = await this.app.$shopify.cart.addLines(
        cartId,
        lines,
        currency
      )
      if (userErrors.length > 0) throw new Error(userErrors)
      else commit('setCart', cart)
    } catch (error) {
      throw new Error('ERROR STORE - cart/addLinesToCart()', error)
    }
  },
  async updateBuyerIdentity({ getters, commit }, currency) {
    try {
      const cartId = getters.getCartId

      if (!cartId || !currency) return

      const { cart, userErrors } =
        await this.app.$shopify.cart.updateBuyerIdentity(cartId, currency)

      if (userErrors.length > 0) throw new Error(userErrors)
      else commit('setCart', cart)
    } catch (error) {
      throw new Error('ERROR STORE - cart/updateBuyerIdentity()', error)
    }
  },
}

export function buildCustomAttributes(product, variant) {
  try {
    const ret = [
      {
        key: '_product_categorization',
        value: product?.productCategorization || 'not set',
      },
      {
        key: '_color_family',
        value: product?.normalized_color?.toLowerCase() || 'not set',
      },
      {
        key: '_season',
        value: product?.season || 'not set',
      },
      {
        key: '_color',
        value: product?.sample_color || product?.normalized_color,
      },
      { key: '_size', value: variant?.size_name || 'not set' },
      {
        key: '_site_link',
        value: product?.modelGroup
          ? `${product.modelGroup}?variant=${product.internal_type}-${product.internal_color_code}`
          : product.handle,
      },
      { key: '_title', value: product?.title || 'not set' },
    ]
    const image = product?.medias?.[0]?.src
      ? {
          key: '_image',
          value: product?.medias?.[0]?.src,
        }
      : null
    if (image) ret.push(image)
    const discountPrice = product?.discount_price
      ? {
          key: '_discount_price',
          value: product.discount_price,
        }
      : null
    if (discountPrice) ret.push(discountPrice)
    const isFinalSale = product.isFinalSale
      ? {
          key: '_is_final_sale',
          value: product.isFinalSale ? 'true' : 'false',
        }
      : null
    if (isFinalSale) ret.push(isFinalSale)
    return ret
  } catch (error) {
    throw new Error('ERROR STORE - cart/buildCustomAttributes()', error)
  }
}
export function buildLinesArray(products) {
  try {
    return products.map(({ product, variant }) => ({
      merchandiseId: variant?.id?.includes('gid://shopify/')
        ? variant.id
        : atob(variant.id),
      quantity: variant?.quantity || 1,
      attributes: buildCustomAttributes(product, variant),
    }))
  } catch (error) {
    throw new Error('ERROR STORE - cart/buildLinesArray()', error)
  }
}
export function findLinesToUpdate(products, cartLines) {
  try {
    return products.reduce((acc, product) => {
      const { node } = cartLines.find((line) => {
        return line?.node?.merchandise?.id === product.variant.id
      })
      if (node) {
        acc.push({
          id: node.id,
          merchandiseId: node.merchandise.id,
          quantity: product.quantity,
          attributes: node.attributes,
        })
      }
      return acc
    }, [])
  } catch (error) {
    throw new Error('ERROR STORE - cart/findLinesToUpdate()', error)
  }
}
