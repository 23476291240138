import {
  getFlapsProduct,
  getCategoryProduct,
  getPositionFrom,
  getFormatedPrice,
  getVariantIdDecoded,
} from '~/helpers/gtm'

class GtmEvents {
  constructor($gtm, app) {
    this.$gtm = $gtm
    this.app = app
  }

  // DATALAYER
  DLProductPage(product) {
    try {
      if (!product || !product.tags) return null
      const displayFlaps = getFlapsProduct(product.tags)
      const idColor = `${product.internal_type}-${product.internal_color_code}`
      this.$gtm.push({
        page: { type: 'product page' },
        product: {
          flap: displayFlaps.length === 0 ? 'empty' : displayFlaps,
          id: { color: idColor },
        },
      })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'GTM Event Plugin',
        level: 'warning',
        extra: `productDLProductPagePage`,
      })
    }
  }

  DLSearchPage(products) {
    try {
      if (!products) return null
      this.$gtm.push({
        search: { result: products.length },
      })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'GTM Event Plugin',
        level: 'warning',
        extra: `DLCollectionPage`,
      })
    }
  }

  // UNIVERSAL ANALYTICS
  UAProductDetails(product, position) {
    try {
      // https://developers.google.com/tag-manager/enhanced-ecommerce#details
      const displayFlaps = getFlapsProduct(product.tags)
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'productDetail',
        ecommerce: {
          detail: {
            actionField: { list: getPositionFrom(position) },
            products: [
              {
                name: product.title || null,
                id: product.internal_type || null,
                price: getFormatedPrice(product.price),
                brand: 'Lole',
                category: getCategoryProduct([]),
                variant: product.normalized_color || null,
                dimension12: displayFlaps.length === 0 ? 'empty' : displayFlaps,
                dimension14: `${product.internal_type}-${product.internal_color_code}`,
              },
            ],
          },
        },
      })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'GTM Event Plugin',
        level: 'warning',
        extra: `UAProductDetails`,
      })
    }
  }

  UAProductsImpression(products, position) {
    try {
      // https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
      const impressions = []
      products.forEach((product, index) => {
        const displayFlaps = getFlapsProduct(product.tags)
        impressions.push({
          name: product.title || null,
          id: product.internal_type || null,
          price: getFormatedPrice(product.price),
          brand: 'Lole',
          category: getCategoryProduct([]),
          variant: product.normalized_color || null,
          list: getPositionFrom(position),
          position: index + 1,
          dimension12: displayFlaps.length === 0 ? 'empty' : displayFlaps,
        })
      })
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'productsImpression',
        ecommerce: {
          currencyCode: products[0].currency,
          impressions,
        },
      })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'GTM Event Plugin',
        level: 'warning',
        extra: `UAProductsImpression`,
      })
    }
  }

  UAProductClick(product, position) {
    try {
      const displayFlaps = getFlapsProduct(product.tags)
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: { list: getPositionFrom(position) },
            products: [
              {
                name: product.title || null,
                id: product.internal_type || null,
                price: getFormatedPrice(product.price),
                brand: 'Lole',
                category: getCategoryProduct([]),
                variant: product.normalized_color || null,
                position: 1,
                dimension12: displayFlaps.length === 0 ? 'empty' : displayFlaps,
              },
            ],
          },
        },
      })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'GTM Event Plugin',
        level: 'warning',
        extra: `UAProductClick`,
      })
    }
  }

  UAPromotionImpressions(promotions) {
    // https://developers.google.com/tag-manager/enhanced-ecommerce#promo-impressions
    try {
      const promos = []
      promotions.forEach((promo) => {
        promos.push({
          id: promo.id,
          name: promo.name,
          creative: promo.creative,
          position: promo.position,
        })
      })
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'promoView',
        ecommerce: {
          promoview: {
            promotions: promos,
          },
        },
      })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'GTM Event Plugin',
        level: 'warning',
        extra: `UAPromotionImpressions`,
      })
    }
  }

  UAPromotionClick(promotion, callbackFunction) {
    // https://developers.google.com/tag-manager/enhanced-ecommerce#promo-clicks
    try {
      const promo = {
        id: promotion.id,
        name: promotion.name,
        creative: promotion.creative,
        position: promotion.position,
      }
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [promo],
          },
        },
        eventCallback: callbackFunction,
      })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'GTM Event Plugin',
        level: 'warning',
        extra: `UAPromotionClick`,
      })
    }
  }

  UAAddToCart(product, selectedVariant, quantity) {
    try {
      const displayFlaps = getFlapsProduct(product.tags)
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: product.currency,
          add: {
            products: [
              {
                name: product.title || null,
                id: product.internal_type || null,
                price: getFormatedPrice(product.price),
                brand: 'Lole',
                category: getCategoryProduct([]),
                variant: product.normalized_color || null,
                quantity,
                dimension16: getVariantIdDecoded(selectedVariant.variant_id),
                dimension12:
                  displayFlaps && displayFlaps.length === 0
                    ? 'empty'
                    : displayFlaps,
                dimension14: `${product.internal_type}-${product.internal_color_code}`,
                dimension15: `${product.internal_type}-${product.internal_color_code}-${selectedVariant.size}`,
              },
            ],
          },
        },
      })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'GTM Event Plugin',
        level: 'warning',
        extra: `UAAddToCart`,
      })
    }
  }

  UARemoveFromCart(product, selectedVariant, quantity) {
    try {
      this.$gtm.push({ ecommerce: null })
      this.$gtm.push({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              {
                name: product.title || null,
                id: product.internal_type || null,
                price: getFormatedPrice(product.price),
                brand: 'Lole',
                category: getCategoryProduct([]),
                variant: product.normalized_color || null,
                quantity,
                dimension16: getVariantIdDecoded(selectedVariant.variant_id),
                dimension14: `${product.internal_type}-${product.internal_color_code}`,
                dimension15: `${product.internal_type}-${product.internal_color_code}-${selectedVariant.size}`,
              },
            ],
          },
        },
      })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'GTM Event Plugin',
        level: 'warning',
        extra: `UAremoveFromCart`,
      })
    }
  }

  UAEmptySearch(search) {
    try {
      this.$gtm.push({
        event: 'emptyResult',
        libelle: search,
      })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'GTM Event Plugin',
        level: 'warning',
        extra: `UAEmptySearch`,
      })
    }
  }

  // EVENTS CUSTOM
  ECClickMenu(type, { level1, level2, level3 }) {
    try {
      if (type === 'adv') this.$gtm.push({ event: 'clickMenuAdv', level1 })
      else if (type === 'menu')
        this.$gtm.push({ event: 'clickMenu', level1, level2, level3 })
    } catch (e) {
      this.app.$sentryException(e, {
        source: 'GTM Event Plugin',
        level: 'warning',
        extra: `ECClickMenu`,
      })
    }
  }

  // FUNCTIONS
  getCurrency() {
    const country = this.app.i18n.locale.substring(3, 5)
    return country === 'us' ? 'USD' : country === 'ca' ? 'CAD' : 'EUR'
  }
}

export default ({ $gtm, app }, inject) => {
  inject('gtmEvents', new GtmEvents($gtm, app))
}
