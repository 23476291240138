
export default {
  name: 'LoleBtn',
  props: {
    bgyellow: {
      type: Boolean,
      default: false,
    },
    bgwhite: {
      type: Boolean,
      default: false,
    },
    bgblack: {
      type: Boolean,
      default: false,
    },
    bdblack: {
      type: Boolean,
      default: false,
    },
    bdeerieblack: {
      type: Boolean,
      default: false,
    },
    bdyellow: {
      type: Boolean,
      default: false,
    },
    txtWhite: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    thin: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
