import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _254acbc6 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _ddb8d87e = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _74d02e46 = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _795442df = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _77002223 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _255abfe8 = () => interopDefault(import('../pages/forget-password.vue' /* webpackChunkName: "pages/forget-password" */))
const _043e10de = () => interopDefault(import('../pages/gift-card.vue' /* webpackChunkName: "pages/gift-card" */))
const _45f15ad4 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _e5b6b050 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _4ed5b7d4 = () => interopDefault(import('../pages/returns.vue' /* webpackChunkName: "pages/returns" */))
const _4609e596 = () => interopDefault(import('../pages/rewards.vue' /* webpackChunkName: "pages/rewards" */))
const _14c2fba7 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _0c1478a1 = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _e0491ea2 = () => interopDefault(import('../pages/size-guide.vue' /* webpackChunkName: "pages/size-guide" */))
const _6ee6ab84 = () => interopDefault(import('../pages/store-locator/index.vue' /* webpackChunkName: "pages/store-locator/index" */))
const _3ffeccd6 = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _8c44e3f0 = () => interopDefault(import('../pages/yoga-classes.vue' /* webpackChunkName: "pages/yoga-classes" */))
const _d418f908 = () => interopDefault(import('../pages/account/gift-card.vue' /* webpackChunkName: "pages/account/gift-card" */))
const _ae420324 = () => interopDefault(import('../pages/account/membership-advantages.vue' /* webpackChunkName: "pages/account/membership-advantages" */))
const _d0f056f4 = () => interopDefault(import('../pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _0f7c2e00 = () => interopDefault(import('../pages/account/personal-information.vue' /* webpackChunkName: "pages/account/personal-information" */))
const _53a44066 = () => interopDefault(import('../pages/account/reviews/index.vue' /* webpackChunkName: "pages/account/reviews/index" */))
const _7b15639d = () => interopDefault(import('../pages/account/wardrobe.vue' /* webpackChunkName: "pages/account/wardrobe" */))
const _e0fe484e = () => interopDefault(import('../pages/homepage/library.vue' /* webpackChunkName: "pages/homepage/library" */))
const _182b31e4 = () => interopDefault(import('../pages/homepage/test.vue' /* webpackChunkName: "pages/homepage/test" */))
const _2f442333 = () => interopDefault(import('../pages/newsletter/subscribe.vue' /* webpackChunkName: "pages/newsletter/subscribe" */))
const _339f61ac = () => interopDefault(import('../pages/newsletter/success.vue' /* webpackChunkName: "pages/newsletter/success" */))
const _5dc37d1f = () => interopDefault(import('../pages/account/reviews/_orderId.vue' /* webpackChunkName: "pages/account/reviews/_orderId" */))
const _b3febf34 = () => interopDefault(import('../pages/bundle/lole-lookbook/_slug.vue' /* webpackChunkName: "pages/bundle/lole-lookbook/_slug" */))
const _f4e88174 = () => interopDefault(import('../pages/collections_old/_handle.vue' /* webpackChunkName: "pages/collections_old/_handle" */))
const _37ad74de = () => interopDefault(import('../pages/collections/_handle.vue' /* webpackChunkName: "pages/collections/_handle" */))
const _1b83ec35 = () => interopDefault(import('../pages/newsletter/_code.vue' /* webpackChunkName: "pages/newsletter/_code" */))
const _a3e7aa8a = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _1787e5fc = () => interopDefault(import('../pages/s/_handle.vue' /* webpackChunkName: "pages/s/_handle" */))
const _3cd197f2 = () => interopDefault(import('../pages/store-locator/_placeId.vue' /* webpackChunkName: "pages/store-locator/_placeId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _254acbc6,
    pathToRegexpOptions: {"strict":true},
    name: "about-us___en___default"
  }, {
    path: "/account",
    component: _ddb8d87e,
    pathToRegexpOptions: {"strict":true},
    name: "account___en___default"
  }, {
    path: "/careers",
    component: _74d02e46,
    pathToRegexpOptions: {"strict":true},
    name: "careers___en___default"
  }, {
    path: "/cart",
    component: _795442df,
    pathToRegexpOptions: {"strict":true},
    name: "cart___en___default"
  }, {
    path: "/en",
    component: _77002223,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/forget-password",
    component: _255abfe8,
    pathToRegexpOptions: {"strict":true},
    name: "forget-password___en___default"
  }, {
    path: "/fr",
    component: _77002223,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr"
  }, {
    path: "/gift-card",
    component: _043e10de,
    pathToRegexpOptions: {"strict":true},
    name: "gift-card___en___default"
  }, {
    path: "/privacy-policy",
    component: _45f15ad4,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___en___default"
  }, {
    path: "/reset-password",
    component: _e5b6b050,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password___en___default"
  }, {
    path: "/returns",
    component: _4ed5b7d4,
    pathToRegexpOptions: {"strict":true},
    name: "returns___en___default"
  }, {
    path: "/rewards",
    component: _4609e596,
    pathToRegexpOptions: {"strict":true},
    name: "rewards___en___default"
  }, {
    path: "/search",
    component: _14c2fba7,
    pathToRegexpOptions: {"strict":true},
    name: "search___en___default"
  }, {
    path: "/signin",
    component: _0c1478a1,
    pathToRegexpOptions: {"strict":true},
    name: "signin___en___default"
  }, {
    path: "/size-guide",
    component: _e0491ea2,
    pathToRegexpOptions: {"strict":true},
    name: "size-guide___en___default"
  }, {
    path: "/store-locator",
    component: _6ee6ab84,
    pathToRegexpOptions: {"strict":true},
    name: "store-locator___en___default"
  }, {
    path: "/terms-of-use",
    component: _3ffeccd6,
    pathToRegexpOptions: {"strict":true},
    name: "terms-of-use___en___default"
  }, {
    path: "/yoga-classes",
    component: _8c44e3f0,
    pathToRegexpOptions: {"strict":true},
    name: "yoga-classes___en___default"
  }, {
    path: "/account/gift-card",
    component: _d418f908,
    pathToRegexpOptions: {"strict":true},
    name: "account-gift-card___en___default"
  }, {
    path: "/account/membership-advantages",
    component: _ae420324,
    pathToRegexpOptions: {"strict":true},
    name: "account-membership-advantages___en___default"
  }, {
    path: "/account/orders",
    component: _d0f056f4,
    pathToRegexpOptions: {"strict":true},
    name: "account-orders___en___default"
  }, {
    path: "/account/personal-information",
    component: _0f7c2e00,
    pathToRegexpOptions: {"strict":true},
    name: "account-personal-information___en___default"
  }, {
    path: "/account/reviews",
    component: _53a44066,
    pathToRegexpOptions: {"strict":true},
    name: "account-reviews___en___default"
  }, {
    path: "/account/wardrobe",
    component: _7b15639d,
    pathToRegexpOptions: {"strict":true},
    name: "account-wardrobe___en___default"
  }, {
    path: "/en/about-us",
    component: _254acbc6,
    pathToRegexpOptions: {"strict":true},
    name: "about-us___en"
  }, {
    path: "/en/account",
    component: _ddb8d87e,
    pathToRegexpOptions: {"strict":true},
    name: "account___en"
  }, {
    path: "/en/careers",
    component: _74d02e46,
    pathToRegexpOptions: {"strict":true},
    name: "careers___en"
  }, {
    path: "/en/cart",
    component: _795442df,
    pathToRegexpOptions: {"strict":true},
    name: "cart___en"
  }, {
    path: "/en/forget-password",
    component: _255abfe8,
    pathToRegexpOptions: {"strict":true},
    name: "forget-password___en"
  }, {
    path: "/en/gift-card",
    component: _043e10de,
    pathToRegexpOptions: {"strict":true},
    name: "gift-card___en"
  }, {
    path: "/en/privacy-policy",
    component: _45f15ad4,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___en"
  }, {
    path: "/en/reset-password",
    component: _e5b6b050,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password___en"
  }, {
    path: "/en/returns",
    component: _4ed5b7d4,
    pathToRegexpOptions: {"strict":true},
    name: "returns___en"
  }, {
    path: "/en/rewards",
    component: _4609e596,
    pathToRegexpOptions: {"strict":true},
    name: "rewards___en"
  }, {
    path: "/en/search",
    component: _14c2fba7,
    pathToRegexpOptions: {"strict":true},
    name: "search___en"
  }, {
    path: "/en/signin",
    component: _0c1478a1,
    pathToRegexpOptions: {"strict":true},
    name: "signin___en"
  }, {
    path: "/en/size-guide",
    component: _e0491ea2,
    pathToRegexpOptions: {"strict":true},
    name: "size-guide___en"
  }, {
    path: "/en/store-locator",
    component: _6ee6ab84,
    pathToRegexpOptions: {"strict":true},
    name: "store-locator___en"
  }, {
    path: "/en/terms-of-use",
    component: _3ffeccd6,
    pathToRegexpOptions: {"strict":true},
    name: "terms-of-use___en"
  }, {
    path: "/en/yoga-classes",
    component: _8c44e3f0,
    pathToRegexpOptions: {"strict":true},
    name: "yoga-classes___en"
  }, {
    path: "/fr/about-us",
    component: _254acbc6,
    pathToRegexpOptions: {"strict":true},
    name: "about-us___fr"
  }, {
    path: "/fr/account",
    component: _ddb8d87e,
    pathToRegexpOptions: {"strict":true},
    name: "account___fr"
  }, {
    path: "/fr/careers",
    component: _74d02e46,
    pathToRegexpOptions: {"strict":true},
    name: "careers___fr"
  }, {
    path: "/fr/cart",
    component: _795442df,
    pathToRegexpOptions: {"strict":true},
    name: "cart___fr"
  }, {
    path: "/fr/forget-password",
    component: _255abfe8,
    pathToRegexpOptions: {"strict":true},
    name: "forget-password___fr"
  }, {
    path: "/fr/gift-card",
    component: _043e10de,
    pathToRegexpOptions: {"strict":true},
    name: "gift-card___fr"
  }, {
    path: "/fr/privacy-policy",
    component: _45f15ad4,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___fr"
  }, {
    path: "/fr/reset-password",
    component: _e5b6b050,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password___fr"
  }, {
    path: "/fr/returns",
    component: _4ed5b7d4,
    pathToRegexpOptions: {"strict":true},
    name: "returns___fr"
  }, {
    path: "/fr/rewards",
    component: _4609e596,
    pathToRegexpOptions: {"strict":true},
    name: "rewards___fr"
  }, {
    path: "/fr/search",
    component: _14c2fba7,
    pathToRegexpOptions: {"strict":true},
    name: "search___fr"
  }, {
    path: "/fr/signin",
    component: _0c1478a1,
    pathToRegexpOptions: {"strict":true},
    name: "signin___fr"
  }, {
    path: "/fr/size-guide",
    component: _e0491ea2,
    pathToRegexpOptions: {"strict":true},
    name: "size-guide___fr"
  }, {
    path: "/fr/store-locator",
    component: _6ee6ab84,
    pathToRegexpOptions: {"strict":true},
    name: "store-locator___fr"
  }, {
    path: "/fr/terms-of-use",
    component: _3ffeccd6,
    pathToRegexpOptions: {"strict":true},
    name: "terms-of-use___fr"
  }, {
    path: "/fr/yoga-classes",
    component: _8c44e3f0,
    pathToRegexpOptions: {"strict":true},
    name: "yoga-classes___fr"
  }, {
    path: "/homepage/library",
    component: _e0fe484e,
    pathToRegexpOptions: {"strict":true},
    name: "homepage-library___en___default"
  }, {
    path: "/homepage/test",
    component: _182b31e4,
    pathToRegexpOptions: {"strict":true},
    name: "homepage-test___en___default"
  }, {
    path: "/newsletter/subscribe",
    component: _2f442333,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-subscribe___en___default"
  }, {
    path: "/newsletter/success",
    component: _339f61ac,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-success___en___default"
  }, {
    path: "/en/account/gift-card",
    component: _d418f908,
    pathToRegexpOptions: {"strict":true},
    name: "account-gift-card___en"
  }, {
    path: "/en/account/membership-advantages",
    component: _ae420324,
    pathToRegexpOptions: {"strict":true},
    name: "account-membership-advantages___en"
  }, {
    path: "/en/account/orders",
    component: _d0f056f4,
    pathToRegexpOptions: {"strict":true},
    name: "account-orders___en"
  }, {
    path: "/en/account/personal-information",
    component: _0f7c2e00,
    pathToRegexpOptions: {"strict":true},
    name: "account-personal-information___en"
  }, {
    path: "/en/account/reviews",
    component: _53a44066,
    pathToRegexpOptions: {"strict":true},
    name: "account-reviews___en"
  }, {
    path: "/en/account/wardrobe",
    component: _7b15639d,
    pathToRegexpOptions: {"strict":true},
    name: "account-wardrobe___en"
  }, {
    path: "/en/homepage/library",
    component: _e0fe484e,
    pathToRegexpOptions: {"strict":true},
    name: "homepage-library___en"
  }, {
    path: "/en/homepage/test",
    component: _182b31e4,
    pathToRegexpOptions: {"strict":true},
    name: "homepage-test___en"
  }, {
    path: "/en/newsletter/subscribe",
    component: _2f442333,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-subscribe___en"
  }, {
    path: "/en/newsletter/success",
    component: _339f61ac,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-success___en"
  }, {
    path: "/fr/account/gift-card",
    component: _d418f908,
    pathToRegexpOptions: {"strict":true},
    name: "account-gift-card___fr"
  }, {
    path: "/fr/account/membership-advantages",
    component: _ae420324,
    pathToRegexpOptions: {"strict":true},
    name: "account-membership-advantages___fr"
  }, {
    path: "/fr/account/orders",
    component: _d0f056f4,
    pathToRegexpOptions: {"strict":true},
    name: "account-orders___fr"
  }, {
    path: "/fr/account/personal-information",
    component: _0f7c2e00,
    pathToRegexpOptions: {"strict":true},
    name: "account-personal-information___fr"
  }, {
    path: "/fr/account/reviews",
    component: _53a44066,
    pathToRegexpOptions: {"strict":true},
    name: "account-reviews___fr"
  }, {
    path: "/fr/account/wardrobe",
    component: _7b15639d,
    pathToRegexpOptions: {"strict":true},
    name: "account-wardrobe___fr"
  }, {
    path: "/fr/homepage/library",
    component: _e0fe484e,
    pathToRegexpOptions: {"strict":true},
    name: "homepage-library___fr"
  }, {
    path: "/fr/homepage/test",
    component: _182b31e4,
    pathToRegexpOptions: {"strict":true},
    name: "homepage-test___fr"
  }, {
    path: "/fr/newsletter/subscribe",
    component: _2f442333,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-subscribe___fr"
  }, {
    path: "/fr/newsletter/success",
    component: _339f61ac,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-success___fr"
  }, {
    path: "/",
    component: _77002223,
    pathToRegexpOptions: {"strict":true},
    name: "index___en___default"
  }, {
    path: "/en/account/reviews/:orderId",
    component: _5dc37d1f,
    pathToRegexpOptions: {"strict":true},
    name: "account-reviews-orderId___en"
  }, {
    path: "/en/bundle/lole-lookbook/:slug?",
    component: _b3febf34,
    pathToRegexpOptions: {"strict":true},
    name: "bundle-lole-lookbook-slug___en"
  }, {
    path: "/fr/account/reviews/:orderId",
    component: _5dc37d1f,
    pathToRegexpOptions: {"strict":true},
    name: "account-reviews-orderId___fr"
  }, {
    path: "/fr/bundle/lole-lookbook/:slug?",
    component: _b3febf34,
    pathToRegexpOptions: {"strict":true},
    name: "bundle-lole-lookbook-slug___fr"
  }, {
    path: "/account/reviews/:orderId",
    component: _5dc37d1f,
    pathToRegexpOptions: {"strict":true},
    name: "account-reviews-orderId___en___default"
  }, {
    path: "/bundle/lole-lookbook/:slug?",
    component: _b3febf34,
    pathToRegexpOptions: {"strict":true},
    name: "bundle-lole-lookbook-slug___en___default"
  }, {
    path: "/en/collections_old/:handle?",
    component: _f4e88174,
    pathToRegexpOptions: {"strict":true},
    name: "collections_old-handle___en"
  }, {
    path: "/en/collections/:handle?",
    component: _37ad74de,
    pathToRegexpOptions: {"strict":true},
    name: "collections-handle___en"
  }, {
    path: "/en/newsletter/:code?",
    component: _1b83ec35,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-code___en"
  }, {
    path: "/en/product/:slug?",
    component: _a3e7aa8a,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___en"
  }, {
    path: "/en/s/:handle?",
    component: _1787e5fc,
    pathToRegexpOptions: {"strict":true},
    name: "s-handle___en"
  }, {
    path: "/en/store-locator/:placeId?",
    component: _3cd197f2,
    pathToRegexpOptions: {"strict":true},
    name: "store-locator-placeId___en"
  }, {
    path: "/fr/collections_old/:handle?",
    component: _f4e88174,
    pathToRegexpOptions: {"strict":true},
    name: "collections_old-handle___fr"
  }, {
    path: "/fr/collections/:handle?",
    component: _37ad74de,
    pathToRegexpOptions: {"strict":true},
    name: "collections-handle___fr"
  }, {
    path: "/fr/newsletter/:code?",
    component: _1b83ec35,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-code___fr"
  }, {
    path: "/fr/product/:slug?",
    component: _a3e7aa8a,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___fr"
  }, {
    path: "/fr/s/:handle?",
    component: _1787e5fc,
    pathToRegexpOptions: {"strict":true},
    name: "s-handle___fr"
  }, {
    path: "/fr/store-locator/:placeId?",
    component: _3cd197f2,
    pathToRegexpOptions: {"strict":true},
    name: "store-locator-placeId___fr"
  }, {
    path: "/collections_old/:handle?",
    component: _f4e88174,
    pathToRegexpOptions: {"strict":true},
    name: "collections_old-handle___en___default"
  }, {
    path: "/collections/:handle?",
    component: _37ad74de,
    pathToRegexpOptions: {"strict":true},
    name: "collections-handle___en___default"
  }, {
    path: "/newsletter/:code?",
    component: _1b83ec35,
    pathToRegexpOptions: {"strict":true},
    name: "newsletter-code___en___default"
  }, {
    path: "/product/:slug?",
    component: _a3e7aa8a,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___en___default"
  }, {
    path: "/s/:handle?",
    component: _1787e5fc,
    pathToRegexpOptions: {"strict":true},
    name: "s-handle___en___default"
  }, {
    path: "/store-locator/:placeId?",
    component: _3cd197f2,
    pathToRegexpOptions: {"strict":true},
    name: "store-locator-placeId___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
