// https://shopify.dev/docs/api/storefront/2024-04/objects/cart
const money = `{
  amount
  currencyCode
}`

const lines = `{
  edges {
    node {
      id
      quantity
      merchandise {
        ... on ProductVariant {
          id
          compareAtPrice ${money}
          price ${money}
          sku
          quantityAvailable
        }
      }
      attributes {
        key
        value
      }
    }
  }
}`

export const cart = `{
  id
  createdAt
  updatedAt
  totalQuantity
  lines(first: 150) ${lines}
  checkoutUrl
  buyerIdentity {
    countryCode
    deliveryAddressPreferences
    email
    phone
  }
  cost {
    totalAmount ${money}
    subtotalAmount ${money}
    totalTaxAmount ${money}
  }
}`

export const userErrors = `{
  field
  message
}`
