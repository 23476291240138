
export default {
  props: {
    error: {
      type: Object,
      default: () => ({
        message: '',
        statusCode: null,
      }),
    },
  },
  computed: {
    isDev() {
      return process.env.NODE_ENV === 'development'
    },
  },
}
