export default {
  modelGroup: null,
  id: null,
  handle: null,
  title: null,
  sizes: [],
  medias: [],
  normalized_color: null,
  sample_color: null,
  internal_color_code: null,
  internal_type: null,
  price: null,
  strikeout_price: null,
  currency: null,
  isFinalSale: null,
  tags: null,
  flaps: [],
  tagsParsed: null,
  season: null,
  sku: null,
  intellisuggestData: null,
  intellisuggestSignature: null,
}
