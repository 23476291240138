// https://shopify.dev/docs/api/storefront/2024-04/objects/Order
export const orders = `{
  edges {
    node{
      id
      name
      statusUrl
      processedAt
      cancelReason
      fulfillmentStatus
      financialStatus
      totalPrice{
        amount
        currencyCode
      }
      lineItems(first: 100) {
        edges{
          node{
            quantity
            title
            variant {
              image {
                url
              }
              selectedOptions {
                name
                value
              }
              product {
                id
              }
              sku
            }
          }
        }
      }
      successfulFulfillments{
        trackingCompany
        trackingInfo{
          number
          url
        }
        fulfillmentLineItems(first: 100) {
          edges{
            node{
              lineItem{
                quantity
                title
              }
            }
          }
        }
      }
    }
    cursor
  }
  pageInfo {
    hasNextPage
    hasPreviousPage
  }
}`
