
export default {
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
  computed: {
    urlTarget() {
      return this.slide.open_new_tab ? '_blank' : '_self'
    },
  },
}
