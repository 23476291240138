class Storyblok {
  constructor({ $axios, env, locale, error, store, app }) {
    this.token = `token=${env.STORYBLOK_TOKEN}`
    this.version = `version=${env.STORYBLOK_VERSION}`
    this.$axios = $axios
    this.cache = Date.now()
    this.endUrl = `?${this.token}&${this.version}`
    this.baseUrl = 'https://api.storyblok.com/v2'
    this.locale = locale
    this.error = error
    this.store = store
    this.app = app
  }

  async getStory(url, parameters = '') {
    try {
      const { language, fallbackLang } = this.getLanguages()
      const langFolder =
        language === 'default' ? 'en' : language?.split('-')?.[0]

      const ret = await this.$axios.get(
        `${this.baseUrl}/cdn/stories/${langFolder}${url}${this.endUrl}&cv=${this.cache}&${parameters}&language=${language}&fallback_lang=${fallbackLang}`
      )
      return ret?.data
    } catch (err) {
      throw new Error(err)
    }
  }

  async getStories(search) {
    try {
      const { language, fallbackLang } = this.getLanguages()
      const ret = await this.$axios.get(
        `${this.baseUrl}/cdn/stories${this.endUrl}&${search}&per_page=100&cv=${this.cache}&language=${language}&fallback_lang=${fallbackLang}`
      )
      if (!ret.data.stories.length) {
        throw new Error('no stories found')
      }
      return ret.data
    } catch (err) {
      this.error({
        statusCode: err.response.status,
        message: err.response.data,
      })
      throw new Error(err)
    }
  }

  async setVersion() {
    try {
      const { data } = await this.$axios.get(
        `${this.baseUrl}/cdn/spaces/me?${this.token}`
      )
      this.cache = data.space.version
    } catch (e) {
      throw new Error(e)
    }
  }

  getLanguages() {
    let language = null
    let fallbackLang = null
    const currency = this.store.getters['currency/getCurrency']
    const locale = this.app.i18n.locale

    switch (currency) {
      case 'CAD':
        language = locale === 'en' ? 'en-ca' : 'fr-ca'
        fallbackLang = locale === 'en' ? 'default' : 'fr-ca'
        break
      case 'EUR':
        language = locale === 'en' ? 'en' : 'fr'
        fallbackLang = locale === 'en' ? 'default' : 'fr'
        break
      default:
        language = 'default'
        fallbackLang = 'en-ca'
        break
    }
    return {
      language,
      fallbackLang,
    }
  }
}

export default ({ $axios, env, app, error, store }, inject) => {
  const storyblok = new Storyblok({
    $axios,
    env,
    locale: app.i18n.locale,
    error,
    store,
    app,
  })
  inject('storyblok', storyblok)
}
