class SizesSorter {
  constructor() {
    this.sortArr = [
      'XXS/XS',
      'XXS',
      'XS/S',
      'XS',
      'S',
      'S/M',
      'M',
      'L',
      'L/XL',
      'XL',
      'XXL',
      'O/S',
      '2',
      '4',
      '6',
      '8',
      '10',
      '12',
      '14',
      '16',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
      '32',
      '33',
      '34',
      '35',
      '36',
      '37',
      '38',
      '39',
      '40',
      '30A',
      '30B',
      '30C',
      '30D',
      '32A',
      '32B',
      '32C',
      '32D',
      '34A',
      '34B',
      '34C',
      '34D',
      '36A',
      '36B',
      '36C',
      '36D',
      '38A',
      '38B',
      '38C',
      '38D',
      'XS/B',
      'XS/D',
      'S/B',
      'S/D',
      'M/B',
      'M/D',
      'L/B',
      'L/D',
      'XL/B',
      'XL/D',
    ]
    return Object.fromEntries(this.sortArr.map((size, index) => [size, index]))
  }
}

module.exports = new SizesSorter()
