
import { mapGetters } from 'vuex'
import SvgAccessibility from '~/assets/icons/accessibility.svg?inline'
import FooterNewsletterForm from '~/components/footer/footer-newsletter-form'
export default {
  components: { SvgAccessibility, FooterNewsletterForm },
  computed: {
    ...mapGetters({
      vxFooter: 'common/getFooter',
      vxNavigationContent: 'navigation/getContent',
    }),
    upponFooter() {
      return this.vxFooter?.uponFooter_list
    },
    socialNetworks() {
      return this.vxFooter?.socialNetwork_list
    },
    list1() {
      return this.vxFooter?.list1_list
    },
    list2() {
      return this.vxFooter?.list2_list
    },
    list3() {
      return this.vxFooter?.list3_list
    },
    subFooterLinks() {
      return this.vxFooter?.subFooter_links
    },
  },
  methods: {
    isExternalLink(link) {
      return link.includes('http')
    },
  },
}
