
import { mapGetters } from 'vuex'
import ProductCard from '~/components/product-card/ProductCard'
import Button from '~/components/buttons/Button'
export default {
  components: { ProductCard, Button },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      products: [],
    }
  },
  computed: {
    ...mapGetters({
      vxCurrency: 'currency/getCurrency',
    }),
    widthColumnTable() {
      return `w-1/${this.products.length - 1}`
    },
    internalTypesColors() {
      const test = this?.blok?.products?.reduce(
        (acc, { internal_type, color }) => {
          acc.push({
            internal_type: internal_type.toUpperCase(),
            colors: color,
          })
          return acc
        },
        []
      )
      return test
    },
  },
  watch: {
    async vxCurrency() {
      await this.getStaticComponentProducts()
    },
  },
  async beforeMount() {
    await this.getStaticComponentProducts()
  },
  methods: {
    async getStaticComponentProducts() {
      if (this.internalTypesColors.length) {
        const productsFetched = await this.fetchProductsFromShopify(
          this.internalTypesColors,
          this.vxCurrency
        )
        if (productsFetched?.length > 0) {
          this.products = productsFetched.reduce((acc, product) => {
            const tableWording = this.blok?.products.find(
              ({ internal_type }) =>
                internal_type.toUpperCase() ===
                product.internal_type.toUpperCase()
            )
            acc.push({
              tableWording,
              product,
            })
            return acc
          }, [])
        }
      }
    },
  },
}
