
import ComponentHomepageBannerSvgContainer from '~/components/storyblok/components-homepage/component-homepage-banner/component-homepage-banner-svg-container.vue'
export default {
  components: { ComponentHomepageBannerSvgContainer },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
}
