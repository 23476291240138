// https://shopify.dev/docs/api/storefront/2024-04/mutations/customerRecover
export const customerRecover = `
mutation customerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      code
      field
      message
    }
  }
}
`
