
export default {
  name: 'LoleLink',
  props: {
    link: {
      type: [String, Object],
      default: null,
    },
    isExternalLink: {
      type: Boolean,
      default: false,
    },
    isLinkDisabled: Boolean,
  },
  methods: {
    addingSlash(link) {
      if (typeof link !== 'string') return link
      return link.charAt(0) === '/' ? link : '/' + link
    },
  },
}
