
export default {
  name: 'LolePicture',
  props: {
    url: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      required: true,
    },
    sizeW: {
      type: Number,
      required: true,
    },
    srcSet: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    placeHolderSrc(sizeW) {
      return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${sizeW} ${sizeW}'%3E%3C/svg%3E`
    },
    transformImage(width) {
      if (!this.url || this.url === '') return this.getSampleImage(width)

      let imageService = ''
      let path = this.url
      let size = ''

      // STORYBLOK
      if (this.url.includes('storyblok')) {
        imageService = '//img2.storyblok.com/'
        if (this.url.includes('https://a.storyblok.com'))
          path = this.url.replace('https://a.storyblok.com', '')
        else path = this.url.replace('//a.storyblok.com', '')
        size = `${width}x0`
      }
      // SHOPIFY
      if (this.url.includes('cdn.shopify')) {
        path = this.url.replace('.jpg', `_${width}x.jpg`)
        // path =
        //   this.url +
        //   `&width=${width}&height=${Math.round(width / 0.71)}&crop=center`
      }
      return imageService + size + path
    },
    getSampleImage(width) {
      if (this.$storyblok._version === 'draft')
        return `https://via.placeholder.com/${width}`
    },
    getSrcSet() {
      return this.url === ''
        ? ''
        : `${this.transformImage('50')} 50w,
      ${this.transformImage('100')} 100w,
      ${this.transformImage('200')} 200w,
      ${this.transformImage('300')} 300w,
      ${this.transformImage('400')} 400w,
      ${this.transformImage('500')} 500w,
      ${this.transformImage('600')} 600w,
      ${this.transformImage('800')} 800w,
      ${this.transformImage('1000')} 1000w,
      ${this.transformImage('1200')} 1200w,
      ${this.transformImage('1600')} 1600w,
      ${this.transformImage('1900')} 1900w`
    },
  },
}
