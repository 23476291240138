
import LoleP from '~/components/text/lole-p'

export default {
  components: { LoleP },
  props: {
    snackbar: {
      type: Object,
      required: true,
    },
    delay: {
      type: Number,
      default: 3000,
    },
    noDelay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeout: null,
    }
  },
  computed: {
    mainIcon() {
      switch (this.snackbar.type) {
        case 'success':
          return 'check'
        case 'error':
          return 'close'
        case 'warning':
          return 'warning'
        case 'info':
          return 'info'
        default:
          return 'info'
      }
    },
    classObject() {
      return {
        container: true,
        success: this.snackbar.type === 'success',
        error: this.snackbar.type === 'error',
        info: this.snackbar.type === 'info',
        warning: this.snackbar.type === 'warning',
      }
    },
  },
  mounted() {
    if (!this.noDelay) {
      this.timeout = setTimeout(
        () => this.closeSnackbar(this.snackbar),
        this.delay
      )
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  methods: {
    closeSnackbar(snackbar) {
      this.$store.dispatch('notification/removeSnackbar', snackbar)
    },
  },
}
