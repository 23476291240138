import { render, staticRenderFns } from "./lole-content-notifications.vue?vue&type=template&id=e21809e2&scoped=true"
import script from "./lole-content-notifications.vue?vue&type=script&lang=js"
export * from "./lole-content-notifications.vue?vue&type=script&lang=js"
import style0 from "./lole-content-notifications.vue?vue&type=style&index=0&id=e21809e2&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e21809e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Menu: require('/home/vsts/work/1/s/components/menu/menu.vue').default,CartNotification: require('/home/vsts/work/1/s/components/cart/CartNotification.vue').default,CartNotificationBundle: require('/home/vsts/work/1/s/components/cart/CartNotificationBundle.vue').default})
