export const isProductSellable = (locale, config) => {
  try {
    return !(
      (locale.includes('ca') && config.DISABLED_SELLABLE_CA) ||
      (locale.includes('us') && config.DISABLED_SELLABLE_US) ||
      (locale.includes('eu') && config.DISABLED_SELLABLE_EU)
    )
  } catch (e) {
    return false
  }
}

export const getColorNamesByCode = (metaPim, colorCode, locale) => {
  return JSON.parse(metaPim)?.[locale]?.colors?.[colorCode]
}

export const parseProductFromShopify = (product, locale, color) => {
  try {
    const sizesSorter = require('~/helpers/sizesSorter.js')
    const result = {}
    const logs = []

    // LOCALE
    const lang = locale

    // METAFIELDS
    const metafields = JSON.parse(product?.metafield?.value)
    const metafieldColors = metafields?.[locale]?.colors
    const metafieldGlobal = metafields?.[locale]?.global

    // TODO: check if we need this at all
    let key
    const keys = Object.keys(metafieldGlobal)
    let n = keys.length
    const newobj = {}
    while (n--) {
      key = keys[n]
      newobj[key.toLowerCase()] =
        typeof metafieldGlobal[key] === 'string'
          ? metafieldGlobal[key].toLowerCase()
          : metafieldGlobal[key]
    }

    result.metafield = newobj
    result.modelGroup = metafieldGlobal?.model_group

    // PRODUCT CATEGORIZATION
    const forceEngLocale = lang.replace('fr', 'en')
    const metafieldsEng = metafields[forceEngLocale].global
    const PCResult = []
    const PCGender = metafieldsEng?.gender
    const PCNavCategory = metafieldsEng?.nav_category
    const PCNavDivision = metafieldsEng?.nav_division?.replaceAll('/', ',')
    if (PCGender) PCResult.push(PCGender)
    if (PCNavCategory) PCResult.push(PCNavCategory)
    if (PCNavDivision) PCResult.push(PCNavDivision)
    product.productCategorization = PCResult.join(' / ').toLocaleLowerCase()

    result.id = product?.id
    result.handleShopify = product?.handle
    result.handle = `${product?.handle}-${color}`
    result.locale = lang
    result.title = metafieldGlobal?.product_title
    result.description = metafieldGlobal?.long_description
    result.internal_type = metafieldGlobal?.internal_type
    result.internal_color_code = color
    result.features = metafieldGlobal?.feature_list_pdp
    result.benefits = metafieldGlobal?.benefits
    const v_Color = product.variants.edges.find(
      ({ node }) => node?.selectedOptions?.[1]?.value === color
    )
    result.strikeout_price =
      v_Color?.node?.compareAtPrice?.amount?.toString() || null
    result.price = v_Color?.node?.price?.amount?.toString()
    result.currency = v_Color?.node?.price?.currencyCode

    // COLORS
    const listColors = product.options.filter(
      ({ name }) => name.toLocaleLowerCase() === 'color'
    )
    const allColorSizes = product.variants.edges.reduce((acc, { node }) => {
      const variantColor = node?.selectedOptions?.[1].value
      const variantSize = node?.selectedOptions?.[0].value
      const priceToZero = node?.price?.amount === '0.0'
      if (node.availableForSale && !priceToZero) {
        if (!acc[variantColor]) acc[variantColor] = []
        acc[variantColor].push(variantSize)
      }
      return acc
    }, {})

    result.colors = listColors[0].values.reduce((acc, color) => {
      if (metafieldColors[color]) {
        const hasAvailability = !!allColorSizes?.[color]?.length
        if (hasAvailability) {
          const colorItem = {
            internal_color_code: color,
            availability: hasAvailability,
            availableSizes: allColorSizes?.[color] || null,
            normalized_color: metafieldColors?.[color]?.normalized_color,
            sample_color: metafieldColors?.[color]?.color_sample,
            internal_type: metafieldGlobal?.internal_type,
          }
          acc.push(colorItem)
        }
      } else {
        logs.push({
          message: `[HELPERS] Product(parseProduct) : fail colors metafields missing in ${result.handle} for color ${color}`,
        })
      }
      return acc
    }, [])

    // SIZES
    result.sizes = product.variants.edges
      .reduce((acc, { node }) => {
        const variantColor = node?.selectedOptions?.[1].value
        const variantSize = node?.selectedOptions?.[0].value

        if (variantColor === color) {
          const itemSize = {
            name: variantSize,
            availability: node.availableForSale,
            id: node.id,
            barcode: node.barcode,
            sku: node.sku,
            quantity: node.quantityAvailable,
          }
          acc.push(itemSize)
        }

        return acc
      }, [])
      .sort((a, b) => sizesSorter[a.name] - sizesSorter[b.name])

    result.availability =
      result?.sizes?.some((size) => size.availability) || false

    // MEDIAS
    const resultMedias = product?.media?.edges?.reduce((acc, { node }) => {
      if (
        node?.mediaContentType === 'IMAGE' &&
        node?.previewImage?.url?.includes(`${color.toUpperCase()}_`)
      ) {
        const imageFileName = node?.previewImage?.url
          ?.split('/')
          ?.pop()
          ?.split('.')?.[0]
        const position = parseInt(imageFileName?.split('_')?.pop()) || 0

        acc.push({
          src: node.previewImage.url,
          type: 'image',
          position,
        })
      }
      return acc
    }, [])
    const productVideo = metafieldColors?.[color]?.video_produit
    result.medias = resultMedias.sort((a, b) => a.position - b.position)

    // VIDEOS
    if (productVideo)
      result.medias.splice(1, 0, { src: productVideo, type: 'video' })

    result.flaps = metafieldColors?.[color]?.flaps_variant
    result.normalized_color = metafieldColors?.[color]?.normalized_color
    result.sample_color = metafieldColors?.[color]?.color_sample
    result.isPetsProduct = metafieldGlobal?.nav_division
      ? metafieldGlobal.nav_division.includes('ACCS_PET')
      : false
    result.isFinalSale = !!metafieldColors[color]?.final_sale
    result.icons = metafieldGlobal?.icons
    result.model_size_data = metafieldColors?.[color]?.model_size_data
    result.fitting_guide = metafieldGlobal?.fitting_guide
    result.tags = product?.tags
    const genderTag = product.tags.find(
      (tag) =>
        tag.includes('en_facet_gender') || tag.includes('fr_facet_gender')
    )
    result.gender = genderTag?.replace('en_facet_gender:', '')?.toLowerCase()
    result.sizes_details = {
      top_cut: metafieldGlobal?.top_cut,
      bottom_cut: metafieldGlobal?.bottom_cut,
      fit_intention: metafieldGlobal?.fit_intention,
      fit_details: metafieldGlobal?.fit_details,
      waist_type: metafieldGlobal?.waist_type,
      leg_shape: metafieldGlobal?.leg_shape,
      thickness: metafieldGlobal?.thickness,
      back_length: metafieldGlobal?.back_length_size,
      inside_leg_measurement: metafieldGlobal?.Inside_leg_measurement,
      length_information: metafieldGlobal?.length_information,
      model_size_data: metafieldColors?.[color]?.model_size_data,
      dimensions: {
        default: metafieldGlobal?.dimensions,
        width: metafieldGlobal?.dimensions_width,
        height: metafieldGlobal?.dimensions_height,
        depth: metafieldGlobal?.dimensions_depth,
        top_handle_drop_length:
          metafieldGlobal?.dimensions_top_handle_drop_length,
        adjustable_shoulder_strap_drop_length:
          metafieldGlobal?.dimensions_adjustable_shoulder_strap_drop_length,
        strap_length: metafieldGlobal?.dimensions_strap_length,
        capacity: metafieldGlobal?.dimensions_capacity,
      },
    }

    result.materials = {
      composition: metafieldGlobal?.composition,
      down_percentage: metafieldGlobal?.down_percentage,
      down_fill_power: metafieldGlobal?.fill_power,
      care_instructions: metafieldGlobal?.care_instructions,
    }

    result.seo = {
      title: metafieldGlobal?.meta_title,
      description: metafieldGlobal?.meta_desc,
      og_image: result?.medias?.[0]?.src,
    }

    result.product_ids = product?.productIds || []

    result.tags = product?.tags || []
    const tags_parsed = {}
    product.tags.forEach((tag) => {
      const splitTag = tag.split(':')
      if (splitTag.length === 2) {
        tags_parsed[splitTag?.[0]] = splitTag?.[1]
      }
    })
    result.tagsParsed = tags_parsed

    result.season = tags_parsed?.en_facet_season

    // TEST PARSING (DEBUGGING ONLY)
    // const testParsing = testParseProductFromShopify(result)
    // console.log('DEBUG testParsing Shopify product', testParsing)

    return { result, logs }
  } catch (error) {
    throw new Error(`parseProductFromShopify error: ${error}`)
  }
}

export const parseProductGiftCard = (product) => {
  const giftcard = {
    title: null,
    handle: null,
    variants: [],
    medias: [],
  }
  // TITLE
  giftcard.title = product.title
  // HANDLE
  giftcard.handle = 'gift-card'
  // VARIANTS
  product.variants.edges.forEach(({ node }) => {
    giftcard.variants.push({
      id: node.id,
      title: node.title,
      barcode: `gift-card-${node.price.amount.replace('.0', '')}`,
      price: {
        amount: node.price.amount,
        currencyCode: node.price.currencyCode,
      },
    })
  })
  // MEDIAS
  giftcard.medias.push({
    type: 'image',
    src: product.images.edges[0].node.url,
  })
  return giftcard
}

export const parseProductVariantFromShopify = (data, locale) => {
  const p = data
  const metaPim = p.metaPim.metafieldGlobal.value
  const price = p.price
  const strikeout_price = p.compareAtPrice ? p.compareAtPrice.toString() : null
  const selectedSize = p.selectedOptions[0].value
  const selectedColor = p.selectedOptions[1].value
  const model = {
    variantId: p.id,
    title: p.parentProduct.title,
    image: p.image,
    barcode: p.barcode,
    productType: p.parentProduct.productType,
    productId: p.parentProduct.id,
    productHandle: `${p.parentProduct.handle}-${selectedColor}`,
    productHandleShopify: p.parentProduct.handle,
    availableForSale: p.availableForSale,
    price,
    strikeout_price,
    variantSize: selectedSize,
    variantColor: getColorNamesByCode(metaPim, selectedColor, locale),
    variantTitle: p.title,
    sellableOnlineQty: p.sellableOnlineQuantity,
  }
  return model
}

export const testParseProductFromShopify = (product) => {
  const result = []
  if (!product.metafield) result.push('metafield not found')
  if (!product.modelGroup) result.push('modelGroup not found')
  if (!product.productCategorization)
    result.push('productCategorization not found')
  if (!product.id) result.push('id not found')
  if (!product.handleShopify) result.push('handleShopify not found')
  if (!product.handle) result.push('handle not found')
  if (!product.locale) result.push('locale not found')
  if (!product.title) result.push('title not found')
  if (!product.description) result.push('description not found')
  if (!product.internal_type) result.push('internal_type not found')
  if (!product.internal_color_code) result.push('internal_color_code not found')
  if (!product.features) result.push('features not found')
  if (!product.benefits) result.push('benefits not found')
  if (!product.price) result.push('price not found')
  if (!product.strikeout_price) result.push('strikeout_price not found')
  if (!product.availability) result.push('availability not found')
  if (!product.colors) result.push('colors not found')
  if (!product.sizes || product.sizes.length === 0)
    result.push('sizes not found')
  if (!product.medias || product.medias.length === 0)
    result.push('medias not found')
  if (!product.flaps) result.push('flaps not found')
  if (!product.normalized_color) result.push('normalized_color not found')
  if (!product.sample_color) result.push('sample_color not found')
  if (!product.icons) result.push('icons not found')
  if (!product.model_size_data) result.push('model_size_data not found')
  if (!product.fitting_guide) result.push('fitting_guide not found')
  if (!product.tags) result.push('tags not found')
  if (!product.seo.title) result.push('seo title not found')
  if (!product.seo.description) result.push('seo description not found')
  if (!product.seo.og_image) result.push('seo og_image not found')
  if (!product.product_ids) result.push('product_ids not found')
  if (!product.tagsParsed) result.push('tagsParsed not found ')
  if (!product.season) result.push('season not found')

  return result
}
