
import { mapGetters } from 'vuex'
import Button from '~/components/buttons/Button'
import DevLogs from '~/components/error/dev-logs'
export default {
  components: { Button, DevLogs },
  props: {
    error: {
      type: Object,
      default: () => ({
        message: '',
        statusCode: 500,
      }),
    },
  },
  computed: {
    ...mapGetters({
      story: 'common/getErrorStory',
    }),
  },
  methods: {
    reload() {
      window.location.reload()
    },
  },
}
