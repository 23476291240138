
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SvgFlagCa from '~/assets/icons/flag-ca.svg?inline'
import SvgFlagUs from '~/assets/icons/flag-us.svg?inline'
import SvgFlagEu from '~/assets/icons/flag-eu.svg?inline'
export default {
  components: { SvgFlagCa, SvgFlagUs, SvgFlagEu },
  data() {
    return {
      countryToCurrencyMap: {
        ca: 'CAD',
        us: 'USD',
        eu: 'EUR',
      },
    }
  },
  computed: {
    ...mapGetters({
      vxTopBar: 'notification/getTopBar',
    }),
  },
  methods: {
    ...mapMutations({
      vxRemoveTopBarCountry: 'notification/removeTopBarCountry',
    }),
    ...mapActions({
      vxSwitchCurrency: 'currency/switchCurrency',
      vxUpdateBuyerIdentity: 'cart/updateBuyerIdentity',
      vxCommonInit: 'common/init',
    }),
    async switchCurrency(country) {
      const switchCurrency = this.countryToCurrencyMap[country]
      if (!switchCurrency) return

      await this.vxUpdateBuyerIdentity(switchCurrency)
      await this.vxSwitchCurrency(switchCurrency)
      this.vxCommonInit()
      await this.vxRemoveTopBarCountry()
      this.$nuxt.refresh()
    },
  },
}
