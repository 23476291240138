import Shopify from './config'
import Product from './product'
import Collection from './collection'
import Cart from './cart'
import Customer from './customer'

export default ({ app, $axios, env, error }, inject) => {
  Shopify.initConfig({ env })
  Shopify.switchLocale(app.i18n.locale)
  const shopiApp = {
    Shopify,
    product: new Product({ Shopify, $axios, env, app, error }),
    collection: new Collection({ Shopify, $axios, env, app, error }),
    cart: new Cart({ Shopify, $axios, app, error }),
    customer: new Customer({ Shopify, $axios, app, error }),
  }

  inject('shopify', shopiApp)
}
