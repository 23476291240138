// https://shopify.dev/docs/api/storefront/2024-04/objects/product
export const productsByIds = `
query ($ids: [ID!]!, $countryCode: CountryCode!)
@inContext(country: $countryCode){
  nodes(ids: $ids) {
    ... on Product {
      id
      handle
      metafield(namespace: "pim", key: "data") {
        namespace
        key
        value
      }
      tags
      variants(first: 100) {
        edges {
          node {
            id
            sku
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
            quantityAvailable
            availableForSale
            selectedOptions {
              name
              value
            }
          }
        }
      }
      options(first: 3) {
        id
        name
        values
      }
      media(first: 100) {
        edges {
          node {
            mediaContentType
            ... on MediaImage {
              previewImage {
                altText
                height
                width
                url
              }
            }
            ... on Video {
              sources {
                format
                mimeType
                url
              }
            }
          }
        }
      }
    }
  }
}`
