
import LoleBtn from '~/components/buttons/lole-btn'
import LoleLink from '~/components/link/lole-link'
export default {
  components: { LoleBtn, LoleLink },
  props: {
    dateEnd: {
      type: String,
      required: true,
    },
    wording: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    countdown: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    timeRemaining: null,
  }),
  watch: {
    timeRemaining(value) {
      this.countdown = {
        days: Math.floor(value / (1000 * 60 * 60 * 24)),
        hours: Math.floor((value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((value % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((value % (1000 * 60)) / 1000),
      }
    },
  },
  created() {
    this.timeRemaining = new Date(this.dateEnd).getTime() - new Date().getTime()
    setInterval(() => {
      this.timeRemaining = this.timeRemaining - 1000
    }, 1000)
  },
}
