
import { mapGetters } from 'vuex'
import CountryTopBar from '~/components/notification/lole-country-top-bar'
import SubHeader from '~/components/navigation/sub-header'
import NavigationDesktop from '~/components/navigation/navigation-desktop'
import MessageHeader from '~/components/navigation/message-header'

export default {
  components: {
    CountryTopBar,
    SubHeader,
    NavigationDesktop,
    MessageHeader,
  },
  data: () => ({
    isFixed: false,
    isScrollUp: false,
  }),
  computed: {
    ...mapGetters({
      vxScroll: 'getScroll',
      vxIsOpenDesktop: 'menu/getIsOpenDesktop',
    }),
  },
  watch: {
    'vxScroll.current'() {
      this.isFixed =
        this.vxScroll.current > document.getElementById('header').offsetTop
      this.isScrollUp = this.vxScroll.current < this.vxScroll.previous

      const buybox = document.querySelector('.lole-buybox--main')
      if (buybox) {
        if (this.isFixed && this.isScrollUp && this.vxScroll.current > 100) {
          buybox.classList.add('scrollUp')
        } else {
          buybox.classList.remove('scrollUp')
        }
      }
    },
  },
}
