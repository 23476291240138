
import { mapGetters } from 'vuex'
import Swiper from '~/components/swiper'
import ProductCard from '~/components/product-card/ProductCard'
export default {
  components: { ProductCard, Swiper },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    products: [],
  }),
  computed: {
    ...mapGetters({
      vxCurrency: 'currency/getCurrency',
    }),
  },
  watch: {
    async vxCurrency() {
      await this.getCollectionProducts()
    },
  },
  async beforeMount() {
    await this.getCollectionProducts()
  },
  methods: {
    async getCollectionProducts() {
      this.products = await this.fetchCollectionFromSearchSpring(
        this.blok?.collectionHandle,
        this.vxCurrency
      )
    },
  },
}
