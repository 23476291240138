
import { mapActions, mapGetters } from 'vuex'
import LoleBackdrop from '~/components/common/lole-backdrop'
import Button from '~/components/buttons/Button'
import Price from '~/components/price/price'
import LolePicture from '~/components/picture/lole-picture'
import { isProductSellable } from '~/helpers/shopify/product'
import CartNotificationsCarousels from '~/components/cart/CartNotificationsCarousels'

export default {
  name: 'LoleCartConfirmationBundle',
  components: {
    LoleBackdrop,
    Button,
    Price,
    LolePicture,
    CartNotificationsCarousels,
  },
  data() {
    return {
      amountsToFree: {
        CAD: 99,
        EUR: 129,
        USD: 99,
      },
    }
  },
  computed: {
    ...mapGetters({
      vxAddToCartBundleNotification:
        'notification/getAddToCartBundleNotification',
      vxStory: 'common/getConfirmationStory',
      vxCart: 'cart/getCart',
      vxCurrency: 'currency/getCurrency',
    }),
    amountToFreeDelivery() {
      const subtotal = parseFloat(this.vxCart?.subtotalAmount?.amount) || 0
      const threshold = this.amountsToFree[this.vxCurrency]
      return threshold - subtotal <= 0
        ? 0
        : this.getFormat(threshold - subtotal)
    },
    deliveryProgressPercentage() {
      const subtotal = parseFloat(this.vxCart?.subtotalAmount?.amount) || 0
      const threshold = this.amountsToFree[this.vxCurrency]
      const progress = (subtotal / threshold) * 100
      return progress > 100 ? 100 : progress
    },
  },
  watch: {
    vxAddToCartBundleNotification() {
      this.getFocus()
    },
  },
  methods: {
    ...mapActions({
      vxCloseAllMobile: 'navigation/closeAllMobile',
      vxCloseAllDesktop: 'navigation/closeAllDesktop',
      vxGoToCheckout: 'product/goToCheckout',
    }),
    goToCheckout() {
      this.vxGoToCheckout(this.getGAIdSession())
    },
    isSellable() {
      return isProductSellable(this.$i18n.locale, this.$config)
    },
    getFocus() {
      if (this.vxAddToCartBundleNotification)
        setTimeout(() => {
          this.$refs?.closeButton?.focus()
        }, 500)
    },
    closeAll() {
      this.vxCloseAllMobile()
      this.vxCloseAllDesktop()
    },
    getGAIdSession() {
      const ga = window[window.GoogleAnalyticsObject]
      if (ga && ga.getAll) {
        return ga.getAll()[0].get('linkerParam')
      } else return null
    },
    getWordingPaidDelivery(amount) {
      switch (this.vxCurrency) {
        case 'CAD':
          if (this.$i18n.locale === 'en')
            return this.vxStory?.paid_delivery.replace(
              '$',
              `<span class="font-medium">$${amount}</span>`
            )
          else
            return this.vxStory?.paid_delivery.replace(
              '$',
              `<span class="font-medium">${amount}$</span>`
            )
        case 'USD':
          return this.vxStory?.paid_delivery.replace(
            '$',
            `<span class="font-medium">$${amount}</span>`
          )
        default:
          return this.vxStory?.paid_delivery.replace(
            '€',
            `<span class="font-medium">${amount}€</span>`
          )
      }
    },
    getFormat(amount) {
      const fixedPos = amount % 1 === 0 ? 0 : 2
      return parseFloat(amount).toFixed(fixedPos)
    },
  },
}
