export const state = () => ({
  windowWidth: 0,
})

export const getters = {
  getWindowWidth(state) {
    return state.windowWidth
  },
  getIsWindowSmall(state) {
    return state.windowWidth >= 640
  },
  getIsWindowMedium(state) {
    return state.windowWidth >= 768
  },
  getIsWindowLarge(state) {
    return state.windowWidth >= 1024
  },
  getIsWindowXLarge(state) {
    return state.windowWidth >= 1280
  },
}

export const mutations = {
  setWindowWidth(state) {
    state.windowWidth = window.innerWidth
  },
}
