
import { mapGetters } from 'vuex'
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      vxFlaps: 'common/getProductsFlaps',
    }),
    flap() {
      if (!this.product || this.vxFlaps?.tag_list?.length <= 0) return null

      const keys = []
      // ADD FLAPS to keys
      if (this.product?.flaps?.length) keys.push(...this.product.flaps)
      // ADD TAGS to keys
      if (this.product?.tags?.length) keys.push(...this.product.tags)
      // FILTER DISCOUNT FLAPS
      return keys.reduce((acc, key) => {
        if (this.tagList[key]) {
          acc = this.tagList[key]
        } else if (this.product.strikeout_price > 0) {
          const strikeoutPrice = this.tagList['%off']
          if (
            strikeoutPrice &&
            this.product.price !== this.product.strikeout_price
          ) {
            acc = {
              key: '%off',
              text:
                this.calculateDiscount(
                  this.product.price,
                  this.product.strikeout_price
                ).toString() + strikeoutPrice.text,
              text_colour: strikeoutPrice.text_colour,
            }
          }
        }
        return acc
      }, null)
    },
    tagList() {
      return this.vxFlaps.tag_list.reduce((acc, tag) => {
        return acc[tag.key] ? acc : Object.assign(acc, { [tag.key]: tag })
      }, {})
    },
  },
  methods: {
    calculateDiscount(salePrice, regularPrice) {
      let discount = Math.round(
        ((regularPrice - salePrice) / regularPrice) * 100
      )
      const lastDigit = discount % 10
      if (lastDigit >= 0 && lastDigit < 5) {
        discount = Math.floor(discount / 10) * 10
      } else if (lastDigit >= 5 && lastDigit <= 9) {
        discount = Math.floor(discount / 10) * 10 + 5
      }
      return discount
    },
  },
}
