
import { mapGetters, mapActions } from 'vuex'
import Price from '~/components/price/price'
import ProductFlap from '~/components/product-card/ProductFlap'
import LolePicture from '~/components/picture/lole-picture'

export default {
  components: { Price, ProductFlap, LolePicture },
  props: {
    product: {
      type: Object,
      required: true,
    },
    flapSize: {
      type: String,
      default: 'normal',
    },
    flapOrientation: {
      type: String,
      default: 'horizontal',
    },
    noCover: { type: Boolean, default: false },
    hideQuickAdd: Boolean,
    hideFlap: Boolean,
  },
  data() {
    return {
      productClicked: false,
    }
  },
  computed: {
    ...mapGetters({
      vxStoryContent: 'search/getStoryContent',
    }),
    linkProduct() {
      return this.product?.modelGroup
        ? `/product/${this.product.modelGroup}?variant=${this.product.internal_type}-${this.product.internal_color_code}`
        : `/product/${this.product.handle}`
    },
  },
  methods: {
    ...mapActions({
      vxAddProductsToCart: 'product/addProductsToCart',
    }),
    quickBuy(size) {
      if (size.availability <= 0) return
      this.vxAddProductsToCart({
        products: [
          {
            product: this.product,
            variant: { size_name: size.name, id: size.id, quantity: 1 },
          },
        ],
        location: 'product-block',
      })
      this.$googleAnalyticsEvents.GA4AddToCart(this.product)
    },
    getFrontImage() {
      const images = []
      if (!this.product.medias) return null
      this.product.medias.forEach((media) => {
        if (media.type === 'image') images.push(media.src)
      })
      return images[0]
    },
    getBackImage() {
      const images = []
      if (!this.product.medias) return null
      this.product.medias.forEach((media) => {
        if (media.type === 'image') images.push(media.src)
      })
      return images[1]
    },
    isOutOfStock(product) {
      if (parseFloat(product.price) === 0) return true
      if (!product.sizes) return false
      product.sizes.forEach((item) => {
        if (item.availability === 0) return true
      })
      return false
    },
    productClick(product) {
      try {
        document.querySelectorAll('.lole-card-container').forEach((card) => {
          card.classList.remove('active')
        })
        this.productClicked = true
        this.$gtmEvents.UAProductClick(product, {
          page: this.$route.name.includes('index')
            ? 'homepage'
            : this.$route.fullPath,
        })
        setTimeout(() => {
          this.productClicked = false
        }, 800)
      } catch (error) {
        this.$sentryException(error, {
          source: 'store',
          level: 'warning',
          extra: 'Recently viewed - failed to log gtm event',
        })
      }
    },
    detectOverlayClick(ev) {
      if (ev.target.classList.contains('qopts-overlay')) {
        this.$router.push(this.linkProduct)
      }
    },
  },
}
