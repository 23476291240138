import { render, staticRenderFns } from "./component-homepage-carousel-collections.vue?vue&type=template&id=6891c23a"
import script from "./component-homepage-carousel-collections.vue?vue&type=script&lang=js"
export * from "./component-homepage-carousel-collections.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Swiper: require('/home/vsts/work/1/s/components/swiper/index.vue').default})
