export const isKeypressEnter = (event) => {
  return event.keyCode === 13
}

export const isKeypressSpace = (event) => {
  return event.keyCode === 32
}

export const isKeypressLeftArrow = (event) => {
  return event.keyCode === 37
}

export const isKeypressRightArrow = (event) => {
  return event.keyCode === 39
}
