
import { mapGetters, mapActions, mapMutations } from 'vuex'
import LolePicture from '~/components/picture/lole-picture'
export default {
  components: { LolePicture },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      vxContentMenu: 'menu/getContentMenu',
    }),
    list1() {
      if (this.menu && this.menu.list_1 && this.menu.list_1.length > 0)
        return this.menu.list_1.filter((x) => {
          return x.showOnDesktop
        })
      else return []
    },
    list2() {
      if (this.menu && this.menu.list_2 && this.menu.list_2.length > 0)
        return this.menu.list_2.filter((x) => {
          return x.showOnDesktop
        })
      else return []
    },
    list3() {
      if (this.menu && this.menu.list_3 && this.menu.list_3.length > 0)
        return this.menu.list_3.filter((x) => {
          return x.showOnDesktop
        })
      else return []
    },
    list4() {
      if (this.menu && this.menu.list_4 && this.menu.list_4.length > 0)
        return this.menu.list_4.filter((x) => {
          return x.showOnDesktop
        })
      else return []
    },
  },
  methods: {
    ...mapActions({
      vxClose: 'menu/closeMenuDesktop',
      vxQuerySearchspring: 'search/querySearchspring',
    }),
    ...mapMutations({
      vxResetSearchResults: 'search/resetSearchResults',
      vxSetSearchFilters: 'search/setSearchFilters',
    }),
    click(url) {
      try {
        if (url.includes('?')) {
          if (
            `/${this.$i18n.locale}${('url', url.split('?')[0])}` ===
            this.$route.path
          ) {
            this.vxResetSearchResults('filtering')
            this.vxSetSearchFilters(
              url.split('?')[url.split('?').length - 1].split('&')
            )
            this.vxQuerySearchspring()
          }
        }
        this.vxClose()
      } catch (e) {}
    },
  },
}
