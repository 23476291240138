import { render, staticRenderFns } from "./CartNotification.vue?vue&type=template&id=dd9b2108&scoped=true"
import script from "./CartNotification.vue?vue&type=script&lang=js"
export * from "./CartNotification.vue?vue&type=script&lang=js"
import style0 from "./CartNotification.vue?vue&type=style&index=0&id=dd9b2108&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd9b2108",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Price: require('/home/vsts/work/1/s/components/price/price.vue').default,CartNotificationsCarousels: require('/home/vsts/work/1/s/components/cart/CartNotificationsCarousels.vue').default})
