
import { mapGetters } from 'vuex'
import Swiper from '~/components/swiper'
import ProductCard from '~/components/product-card/ProductCard'
export default {
  components: {
    Swiper,
    ProductCard,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      products: [],
    }
  },
  computed: {
    ...mapGetters({
      vxCurrency: 'currency/getCurrency',
    }),
  },
  watch: {
    async vxCurrency() {
      await this.getStaticComponentProducts()
    },
  },
  async beforeMount() {
    await this.getStaticComponentProducts()
  },
  methods: {
    async getStaticComponentProducts() {
      if (this.blok?.collection_handle)
        this.products = await this.fetchCollectionFromSearchSpring(
          this.blok?.collection_handle,
          this.vxCurrency
        )
      else if (this.blok?.products?.length > 0)
        this.products = await this.fetchProductsFromShopify(
          this.blok?.products,
          this.vxCurrency
        )
    },
  },
}
