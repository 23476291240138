/* eslint-disable no-console */
export default ({ app, env }, inject) => {
  const sentryMessage = (message, more = '', skipLogging = false) => {
    app.$sentry.withScope((scope) => {
      const { source = '', component = '', level = 'error' } = more
      scope.setTag('SOURCE', source)
      scope.setTag('COMPONENT', component)
      scope.setTag('i18n_locale', app.i18n.locale)
      if (app.navigator && app.navigator.language) {
        scope.setTag('navigator_locale', app.navigator.language.toLowerCase())
      }
      scope.setTag('vue_env', process.env.VUE_ENV)

      scope.setLevel(level)
      app.$sentry.captureMessage(message)

      if (!skipLogging && env.NODE_ENV !== 'production') {
        console.warn(
          'Error Message: [' + process.env.NODE_ENV + '] ' + message,
          more
        )
      }
    })
  }

  const sentryException = (err, more = '', skipLogging = false) => {
    app.$sentry.configureScope((scope) => {
      const { source = '', component = '', level = 'error', extra = '' } = more
      scope.setTag('SOURCE', source)
      scope.setTag('COMPONENT', component)
      scope.setTag('i18n_locale', app.i18n.locale)
      if (app.navigator && app.navigator.language) {
        scope.setTag('navigator_locale', app.navigator.language.toLowerCase())
      }
      scope.setTag('vue_env', process.env.VUE_ENV)

      scope.setLevel(level)
      scope.setExtra('extra', extra)
      app.$sentry.captureException(err)

      if (!skipLogging && process.env.NODE_ENV !== 'production') {
        console.error(
          'Exception Error: [' + process.env.NODE_ENV + '] ' + err.message,
          more
        )
      }
    })
  }

  inject('sentryMessage', sentryMessage)
  inject('sentryException', sentryException)
}
