class Location {
  constructor({ $axios, app, error }) {
    this.$axios = $axios
    this.app = app
    this.error = error
  }

  async getGeolocData(ip) {
    try {
      const apiKey = process.env.GEOIP_AZURE_API_KEY
      if (process.server) return
      const { data } = await this.$axios.get(
        `${window.location.origin}/geoipAzure/whereis/v1/json/${ip}`,
        { headers: { 'Fastah-Key': apiKey } }
      )
      return {
        ip: data?.ip,
        country: data?.locationData?.countryName,
        countryCode: data?.locationData?.countryCode,
        currencyCode: data?.l10n?.currencyCode,
        isEuropeanUnion: data?.isEuropeanUnion || false,
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  async getIp() {
    try {
      const { data } = await this.$axios.get(
        'https://api.ipify.org?format=json'
      )
      return data?.ip || null
    } catch (error) {
      throw new Error(error)
    }
  }
}

export default ({ $axios, app, error }, inject) => {
  const location = new Location({
    $axios,
    app,
    error,
  })
  inject('location', location)
}
