export const state = () => ({})

export const getters = {}

export const mutations = {}

export const actions = {
  async getCountriesProvinces() {
    try {
      const {
        data: { countries },
      } = await this.$axios.post('/api/shopify/getCountriesProvinces')
      return countries
    } catch (e) {
      throw new Error(e)
    }
  },
  async addNewAddress({ rootGetters, dispatch }, input) {
    try {
      const { accessToken } = rootGetters['customer/getCustomerAccessToken']
      await this.app.$shopify.customer.customerAddressCreate(accessToken, input)
      dispatch('customer/retrieveCustomer', {}, { root: true })
    } catch (e) {
      throw new Error(e)
    }
  },
  async updateAddress({ rootGetters, dispatch }, { idAddress, input }) {
    try {
      const { accessToken } = rootGetters['customer/getCustomerAccessToken']
      await this.app.$shopify.customer.customerAddressUpdate(
        accessToken,
        idAddress,
        input
      )
      dispatch('customer/retrieveCustomer', {}, { root: true })
    } catch (e) {
      throw new Error(e)
    }
  },
  async deleteAddress({ rootGetters, dispatch }, idAddress) {
    try {
      const { accessToken } = rootGetters['customer/getCustomerAccessToken']
      await this.app.$shopify.customer.customerAddressDelete(
        accessToken,
        idAddress
      )
      dispatch('customer/retrieveCustomer', {}, { root: true })
    } catch (e) {
      throw new Error(e)
    }
  },
}
