export const state = () => ({})

export const getters = {}

export const mutations = {}

export const actions = {
  async saveGiftCard({ rootGetters, dispatch }, cardNumber) {
    try {
      const customer = rootGetters['customer/getCustomer']
      const customerGiftCardsSaved = customer?.giftCardSaved?.value
      if (customerGiftCardsSaved) {
        const savedGiftCards = JSON.parse(customerGiftCardsSaved)
        if (savedGiftCards.includes(cardNumber))
          return { success: false, message: 'already_saved' }
        savedGiftCards.push(cardNumber)
        await dispatch('updateGiftCards', {
          customer,
          value: savedGiftCards,
        })
      } else {
        await dispatch('createGiftCards', {
          customer,
          value: [cardNumber],
        })
      }
      return { success: true, message: 'saved' }
    } catch (error) {
      throw new Error(error)
    }
  },
  async deleteGiftCard({ dispatch, rootGetters }, giftCard) {
    try {
      const customer = rootGetters['customer/getCustomer']
      const customerGiftCardsSaved = customer.giftCardSaved.value
      if (customerGiftCardsSaved) {
        const savedGiftCards = JSON.parse(customerGiftCardsSaved)
        await dispatch('updateGiftCards', {
          customer,
          value: savedGiftCards.filter((card) => card !== giftCard),
        })
      }
    } catch (error) {}
  },
  //
  async createGiftCards({ dispatch }, { customer, value }) {
    await this.$axios
      .post('/api/shopify/createCustomerMetafield', {
        locale: this.app.i18n.locale,
        customerId: customer.id,
        namespace: 'lole_shop',
        key: 'saved_gift_cards',
        type: 'json',
        value,
      })
      .then((res) => {
        dispatch('customer/retrieveCustomer', {}, { root: true })
      })
  },
  async updateGiftCards({ dispatch }, { customer, value }) {
    await this.$axios
      .post('/api/shopify/updateCustomerMetafield', {
        locale: this.app.i18n.locale,
        customerId: customer.id,
        metafieldId: customer.giftCardSaved.id,
        value,
      })
      .then((res) => {
        dispatch('customer/retrieveCustomer', {}, { root: true })
      })
  },
}
