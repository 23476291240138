
export default {
  props: {
    price: {
      type: String,
      required: true,
    },
    strikeoutPrice: {
      type: String,
      default: null,
    },
    pdp: {
      type: Boolean,
      default: false,
    },
    pairwith: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  computed: {
    priceFormated() {
      return {
        price: this.getFormat(this.price),
        strikeout_price: this.strikeoutPrice
          ? this.getFormat(this.strikeoutPrice)
          : null,
      }
    },
  },
  methods: {
    getFormat(amount) {
      const fixedPos = amount % 1 === 0 ? 0 : 2
      return parseFloat(amount).toFixed(fixedPos)
    },
  },
}
