/* eslint-disable no-unused-vars */
// auto complte https://api.searchspring.net/api/search/autocomplete.json
// doc https://searchspring.zendesk.com/hc/en-us/articles/115000446623-Basic-Search-API-Request
// product finder https://api.searchspring.net/api/search/finders.json

import config from './config'

export default class Search {
  constructor({ axios, locale }) {
    this.axios = axios
    this.locale = locale
  }

  get(params = {}) {
    const {
      q = '',
      tag = '',
      filters = {},
      page = 1,
      resultsFormat = 'native',
      beacon = false,
    } = params
    const url = `https://api.searchspring.net/api/search/search.json?q=${q}&siteId=${
      config[this.locale].siteId
    }&resultsFormat=${resultsFormat}&format=json&resultsPerPage=36&page=${page}`
    // }&resultsFormat=${resultsFormat}&format=json&resultsPerPage=36&bgfilter.handle=${categoryHandle}`

    // 'https://api-cache.searchspring.io/api/search/search.json
    // ?siteId=xzj11h
    // &userId=LOUP4OAF-TQFJ-M0CP-M5Z0-O2EPI3OTVUSL
    // &domain=https://fr.altitude-sports.com/collections/new-arrivals
    // &referer=https://fr.altitude-sports.com
    // &resultsFormat=native
    // &method=search
    // &format=json
    // &resultLayout=grid
    // &resultsPerPage=48
    // &facebook=0
    // &intellisuggest=1
    // &bgfilter.is_canadian=1
    // &bgfilter.is_canadian=0'

    // http://api.searchspring.net/api/search/search.json?resultsFormat=native&siteId=r984oi&filter.variant_size=XS&filter.ss_price.low=100&filter.ss_price.high=150&sort.ss_price=asc&q=jacket

    return this.axios.get(url)
  }
}
