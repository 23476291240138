
export default {
  props: {
    level: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'md',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
