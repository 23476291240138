
import BannerContent from '~/components/storyblok/components-homepage/component-homepage-banner/banner/banner-content'
export default {
  components: {
    BannerContent,
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
}
