import {
  cartCreate,
  cartLinesAdd,
  cartLinesUpdate,
  cartBuyerIdentityUpdate,
} from './mutations'
import { retrieveCart } from './queries'

export default class Cart {
  constructor({ $axios, Shopify, app, error }) {
    this.$axios = $axios
    this.Shopify = Shopify
    this.app = app
    this.error = error
  }

  async create(lines, currency) {
    try {
      const cartInput = {
        lines,
        buyerIdentity: {
          countryCode: this.getCountryByCurrency(currency),
        },
      }
      const body = JSON.stringify({
        query: cartCreate,
        variables: {
          input: cartInput,
          countryCode: this.getCountryByCurrency(currency),
        },
      })
      const {
        data: { data, errors },
      } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (errors) throw new Error(errors)
      else return data.cartCreate
    } catch (e) {
      throw new Error(e)
    }
  }

  async addLines(cartId, lines, currency) {
    try {
      const body = JSON.stringify({
        query: cartLinesAdd,
        variables: {
          cartId,
          lines,
          countryCode: this.getCountryByCurrency(currency),
        },
      })
      const {
        data: { data },
        errors,
      } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (errors) throw new Error(errors)
      else return data.cartLinesAdd
    } catch (e) {
      throw new Error(e)
    }
  }

  async retrieve(cartId, currency) {
    try {
      const body = JSON.stringify({
        query: retrieveCart,
        variables: {
          cartId,
          countryCode: this.getCountryByCurrency(currency),
        },
      })
      const {
        data: { data },
        errors,
      } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (errors) throw new Error(errors)
      else return data.cart
    } catch (e) {
      throw new Error(e)
    }
  }

  async updateLines(cartId, lines, currency) {
    try {
      const body = JSON.stringify({
        query: cartLinesUpdate,
        variables: {
          cartId,
          lines,
          countryCode: this.getCountryByCurrency(currency),
        },
      })
      const {
        data: { data },
        errors,
      } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      if (errors) throw new Error(errors)
      else return data.cartLinesUpdate
    } catch (e) {
      throw new Error(e)
    }
  }

  async updateBuyerIdentity(cartId, currency) {
    try {
      const countryCode = this.getCountryByCurrency(currency)
      const body = JSON.stringify({
        query: cartBuyerIdentityUpdate,
        variables: {
          buyerIdentity: {
            countryCode,
          },
          cartId,
          countryCode,
        },
      })
      const {
        data: { data },
        errors,
      } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })

      if (errors) throw new Error(errors)
      else return data.cartBuyerIdentityUpdate
    } catch (e) {
      throw new Error(e)
    }
  }

  getCountryByCurrency(currency) {
    switch (currency) {
      case 'CAD':
        return 'CA'
      case 'USD':
        return 'US'
      case 'EUR':
        return 'FR'
      default:
        return null
    }
  }
}
