
export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  computed: {
    items() {
      return this.blok?.list
    },
  },
}
