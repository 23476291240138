class GoogleApi {
  constructor({ $axios, app, error }) {
    this.$axios = $axios
    this.app = app
    this.error = error
  }

  getHeader() {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    }
  }

  async getPlaceDetails(id) {
    let locale = null
    if (this.app.i18n.locale === 'fr-ca') locale = 'fr-CA'
    else if (this.app.i18n.locale === 'fr-eu') locale = 'fr'
    else locale = 'en'
    const key = process.env.GOOGLE_API_KEY
    const { data } = await this.$axios.get(
      `${window.location.origin}/maps.googleapis/maps/api/place/details/json?key=${key}&place_id=${id}&language=${locale}`
    )
    return data
  }
}

export default ({ $axios, app, error }, inject) => {
  const googleApi = new GoogleApi({
    $axios,
    app,
    error,
  })
  inject('googleApi', googleApi)
}
