
import { mapActions, mapGetters } from 'vuex'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import Button from '~/components/buttons/Button'
export default {
  components: {
    Button,
  },
  data: () => ({
    loading: false,
    success: false,
    error: false,
    submitted: false,
    email: null,
  }),
  validations() {
    return {
      email: { required, maxLengthValue: maxLength(40), email },
    }
  },
  computed: {
    ...mapGetters({
      vxFooter: 'common/getFooter',
    }),
  },
  methods: {
    ...mapActions({
      vxSubscribe: 'klaviyo/subscribe',
    }),
    async subscribe() {
      try {
        this.$v.$touch()
        this.submitted = true
        this.loading = true
        this.error = false
        this.success = false
        if (!this.$v.$invalid) {
          await this.vxSubscribe({
            email: this.$v.email.$model,
            source: 'Footer signup form',
          })
            .then((result) => {
              this.success = true
            })
            .catch((error) => {
              this.error = true
              this.$sentryException(error, {
                source: 'Footer - Newsletter form',
                level: 'fatal',
                extra: 'Error - vxSubscribe(): ' + error,
              })
            })
        }
        this.loading = false
      } catch (e) {
        this.error = true
        this.loading = false
        this.$sentryException(e, {
          source: 'Footer - Newsletter form',
          level: 'fatal',
          extra: 'Error - failed to subscribre : ' + e,
        })
      }
    },
  },
}
