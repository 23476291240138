import config from './config'

export default class Events {
  constructor({ locale }) {
    this.locale = locale
  }

  productPage(product, customer, sku) {
    try {
      window.IntelliSuggest.init({
        siteId: config[this.locale]?.siteId,
        context: 'Product page ' + product?.title,
        seed: [sku],
      })
      if (customer) window.IntelliSuggest.setShopperId(customer?.id)
      if (sku) window.IntelliSuggest.viewItem({ sku })
    } catch (e) {
      throw new Error('productPage(): ' + e)
    }
  }

  cartPage(customer, skus, products) {
    try {
      window.IntelliSuggest.init({
        siteId: config[this.locale]?.siteId,
        context: 'Cart page',
        seed: [skus],
      })
      if (customer) window.IntelliSuggest.setShopperId(customer?.id)
      if (products)
        products.forEach((pdt) => {
          window.IntelliSuggest.haveItem(pdt)
        })
      window.IntelliSuggest.inBasket({})
    } catch (e) {
      throw new Error('cartPage(): ' + e)
    }
  }

  clickProduct(sourceLink, link, product) {
    try {
      this.#intellisuggestTrackClick(
        sourceLink,
        link,
        product?.intellisuggestData,
        product?.intellisuggestSignature,
        config[this.locale]?.siteId
      )
    } catch (e) {
      throw new Error('clickProduct(): ' + e)
    }
  }

  #intellisuggestTrackClick(referrer, link, data, signature, siteId) {
    if (document.images) {
      const apiUrl = new URL(
        `${location.protocol}//${siteId}.a.searchspring.io/api/track/track.json`
      )
      apiUrl.searchParams.append('d', data)
      apiUrl.searchParams.append('s', signature)
      apiUrl.searchParams.append('u', link)
      apiUrl.searchParams.append('r', referrer)
      const imgTag = new Image()
      imgTag.src = apiUrl.toString()
    }
    return true
  }
}
