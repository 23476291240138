import { render, staticRenderFns } from "./component-static-table-info.vue?vue&type=template&id=7c2dab95&scoped=true"
import script from "./component-static-table-info.vue?vue&type=script&lang=js"
export * from "./component-static-table-info.vue?vue&type=script&lang=js"
import style0 from "./component-static-table-info.vue?vue&type=style&index=0&id=7c2dab95&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c2dab95",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/home/vsts/work/1/s/components/product-card/ProductCard.vue').default})
