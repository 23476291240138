
import { mapGetters, mapActions } from 'vuex'
import Menu from '~/components/menu/menu'
import CartNotification from '~/components/cart/CartNotification'
import CartNotificationBundle from '~/components/cart/CartNotificationBundle'
import LoleSearchBar from '~/components/menu/lole-search-bar'
import LoleSwitchLocation from '~/components/common/lole-switch-location'
import LoleSnackbarContainer from '~/components/notification/lole-snackbar-container'

export default {
  name: 'LoleContentNotifications',
  components: {
    Menu,
    CartNotification,
    CartNotificationBundle,
    LoleSearchBar,
    LoleSwitchLocation,
    LoleSnackbarContainer,
  },
  computed: {
    ...mapGetters({
      vxScroll: 'getScroll',
      vxGetIsOpenMobile: 'menu/getIsOpenMobile',
      vxGetIsOpenDesktop: 'menu/getIsOpenDesktop',
      vxAddToCartNotification: 'notification/getAddToCartNotification',
      vxAddToCartBundleNotification:
        'notification/getAddToCartBundleNotification',
      vxIsSwitchingLanguage: 'navigation/getSwitchLocationOpen',
      vxIsAccountMenuMobile: 'navigation/getAccountMenuMobileOpen',
    }),
  },
  methods: {
    ...mapActions({
      vxCloseMenuMobile: 'menu/closeMenuMobile',
      vxCloseMenuDesktop: 'menu/closeMenuDesktop',
      vxToogleSwitchLocation: 'navigation/toggleSwitchLocation',
      vxCloseAllDesktop: 'navigation/closeAllDesktop',
    }),
    closeAll() {
      this.vxCloseMenuMobile()
      this.vxCloseMenuDesktop()
    },
  },
}
