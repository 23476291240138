
import { mapGetters } from 'vuex'
import Error404 from '~/components/error/error-404'
import Error500 from '~/components/error/error-500'
import ErrorXXX from '~/components/error/error-xxx'
// import Button from '~/components/buttons/Button'

// TODO also put the datalayer push in case of direct error
export default {
  components: {
    Error404,
    Error500,
    ErrorXXX,
  },
  props: {
    error: {
      type: Object,
      default: () => ({
        message: '',
        statusCode: null,
      }),
    },
  },
  head() {
    return {
      title: this.errorStory.title_page + ' ' + this.error.statusCode,
    }
  },
  computed: {
    ...mapGetters({
      errorStory: 'common/getErrorStory',
    }),
    errorType() {
      return 'error' + this.error.statusCode
    },
  },
  created() {
    try {
      this.$gtm.push({
        ecommerce: undefined,
        product: undefined,
        search: undefined,
      })
      this.$gtm.push({ 'page.type': `error ${this.error.statusCode}` })
    } catch (e) {
      this.$sentryException(e, {
        source: 'GTM layout error',
        level: 'warning',
        extra: 'GTM layout error failed',
      })
    }
  },
}
