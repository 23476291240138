import {
  giftCardFetch,
  productsByIds,
  productsByInternalTypes,
} from './queries'

export default class Product {
  constructor({ $axios, Shopify, app, error }) {
    this.$axios = $axios
    this.Shopify = Shopify
    this.app = app
    this.error = error
  }

  async retrieveGiftCardProduct(currency) {
    try {
      const body = JSON.stringify({
        query: giftCardFetch,
        variables: {
          countryCode: this.getCountryByCurrency(currency),
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      return data
    } catch (error) {
      throw new Error(`ERROR retrieveGiftCardProduct: ${error}`)
    }
  }

  async retrieveProducts(ids, currency) {
    try {
      if (!ids?.length > 0) throw new Error('Missing ids')
      const body = JSON.stringify({
        query: productsByIds,
        variables: {
          ids,
          countryCode: this.getCountryByCurrency(currency),
        },
      })
      const data = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      return data
    } catch (error) {
      throw new Error(`ERROR retrieveProducts: ${error}`)
    }
  }

  async getAllProductIdsFromHandle(handle) {
    try {
      const locales = ['CA', 'US', 'EU']

      const getHeader = (locale, context) => {
        return {
          'X-Shopify-Storefront-Access-Token':
            context.env[`SHOPIFY_${locale}_ACCESS_TOKEN`],
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      }

      const getUrl = (locale, context) => {
        return `https://${
          context.env[`SHOPIFY_${locale}_DOMAIN`]
        }/api/2024-04/graphql`
      }

      const body = JSON.stringify({
        query: `query ($handle: String!) {
          product(handle: $handle) {
            id
          }
        }`,
        variables: {
          handle,
        },
      })
      const response = []
      const context = this.app.context
      for (const locale of locales) {
        const { data } = await this.$axios.post(getUrl(locale, context), body, {
          headers: getHeader(locale, context),
        })

        if (data?.data?.product) {
          response.push({ locale, id: data?.data?.product?.id })
        }
      }

      Promise.resolve()
      return response
    } catch (error) {
      throw new Error(`ERROR getAllProductIdsFromHandle: ${error}`)
    }
  }

  async getProductsByInternalType(internal_types, currency) {
    try {
      let queryString = ''
      internal_types.forEach((i, index) => {
        queryString += `${index !== 0 ? ' OR ' : ''}(tags:internal_type:${i})`
      })
      if (!internal_types.length > 0) throw new Error('internal_types empty')
      const body = JSON.stringify({
        query: productsByInternalTypes,
        variables: {
          queryString,
          amountProducts: internal_types.length,
          countryCode: this.getCountryByCurrency(currency),
        },
      })
      const { data } = await this.$axios.post(this.Shopify.getUrl(), body, {
        headers: this.Shopify.getHeader(),
      })
      return data
    } catch (error) {
      throw new Error(`ERROR getProductsByInternalType: ${error}`)
    }
  }

  getCountryByCurrency(currency) {
    switch (currency) {
      case 'CAD':
        return 'CA'
      case 'USD':
        return 'US'
      case 'EUR':
        return 'FR'
      default:
        return null
    }
  }
}
