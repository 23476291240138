
import { mapGetters, mapActions } from 'vuex'
import LoleBackdrop from '~/components/common/lole-backdrop'
import SvgFlagCa from '~/assets/icons/flag-ca.svg?inline'
import SvgFlagUs from '~/assets/icons/flag-us.svg?inline'
import SvgFlagEu from '~/assets/icons/flag-eu.svg?inline'
export default {
  name: 'SwitchLocation',
  components: {
    LoleBackdrop,
    SvgFlagCa,
    SvgFlagUs,
    SvgFlagEu,
  },
  props: { isActive: Boolean },
  data: () => ({
    currSymbols: {
      CAD: '$',
      USD: '$',
      EUR: '€',
    },
    optionSelected: false,
  }),
  computed: {
    ...mapGetters({
      switchLocator: 'common/getSwitchLocator',
      vxCurrency: 'currency/getCurrency',
      vxCart: 'cart/getCart',
    }),
    currencies() {
      return ['CAD', 'USD', 'EUR']
    },
    languages() {
      return this.vxCurrency === 'USD' ? ['en'] : ['en', 'fr']
    },
  },
  methods: {
    ...mapActions({
      vxCommonInit: 'common/init',
      vxSwitchCurrency: 'currency/switchCurrency',
      vxUpdateBuyerIdentity: 'cart/updateBuyerIdentity',
      vxRetrieveCart: 'cart/retrieveCart',
    }),
    pathWithoutQuery(newLang) {
      const path = this.$route.path
      if (path.includes('search') || path.includes('product')) {
        return this.switchLocalePath(newLang)
      }
      const newPath = this.switchLocalePath(newLang)
      const newPathArray = newPath.split('?')
      const cleanPath = newPathArray[0]
      return cleanPath
    },
    changeCookieI18n(lang) {
      try {
        this.$cookies.remove(`lole_i18n`)
        this.$cookies.set(`lole_i18n`, lang)
      } catch (e) {
        this.$sentryException(e, {
          source: 'Switch lang - changeCookieI18n()',
          level: 'warning',
          extra: 'failed to change lole_i18n cookie',
        })
      }
    },
    async switchLang(lang) {
      await this.changeCookieI18n(lang)
      this.$i18n.locale = lang
      this.vxCommonInit()
      const newPath = this.pathWithoutQuery(lang)
      this.$router.push(newPath)
      this.optionSelected = true
    },
    async switchCurrency(currency) {
      await this.vxUpdateBuyerIdentity(currency)
      await this.vxSwitchCurrency(currency)
      // retrieve cart again to update correct currency in cart page
      if (this.vxCart?.id) {
        await this.vxRetrieveCart({ cartId: this.vxCart.id })
      }
      this.vxCommonInit()
      this.$nuxt.refresh()
      this.optionSelected = true
      if (currency === 'USD') {
        await this.changeCookieI18n('en')
        this.$i18n.locale = 'en'
        const newPath = this.pathWithoutQuery('en')
        this.$router.push(newPath)
        this.optionSelected = true
        this.closeModal()
      }
    },
    closeModal() {
      this.optionSelected = false
      setTimeout(() => {
        this.$emit('close')
      }, 150)
    },
  },
}
