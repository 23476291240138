
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      vxSubHeader: 'notification/getSubHeader',
      vxCustomer: 'customer/getCustomer',
      vxWording: 'navigation/getContent',
      vxLoyalty: 'loyalty/getProfile',
      vxCurrency: 'currency/getCurrency',
    }),
  },
  methods: {
    ...mapActions({
      vxOpenSwitchLocation: 'navigation/toggleSwitchLocation',
      vxCloseAll: 'navigation/closeAllDesktop',
    }),
    getLang() {
      return this?.$i18n?.locale?.toUpperCase()
    },
    getFirstName() {
      if (this.vxCustomer && this.vxCustomer.firstName) {
        const first_name = this.vxCustomer.firstName.toLowerCase()
        return `${first_name.charAt(0).toUpperCase()}${first_name.slice(1)}`
      }
    },
  },
}
