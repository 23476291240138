
import Button from '~/components/buttons/Button'
export default {
  components: { Button },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
}
